import { LogActionId, LoggerContext } from "@smartsheet/logging-state-service";
import React, {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useState,
} from "react";
import { genId } from "../../util/genId";
import {
    LoggableEvent,
    LoggedByDefault,
    LoggingDecorator,
} from "../../util/logging";
import { RadioIcon } from "./RadioIcon";
import { Label, LabelText } from "./styled/Radio";
import { RadioInputWrapper, HiddenInput } from "./styled/RadioInput";

// Currently this only supports strings.
export type ValidRadioValue = string;

export interface RadioProps {
    /** Set the field as checked */
    isChecked: boolean;
    /** Field name. All radio buttons in a group should share the same name. */
    name: string;
    /**
     * onChange event handler, passed into the props of each Radio Component instantiated within RadioGroup
     */
    onChange: (
        e: React.ChangeEvent<HTMLInputElement>,
        log: LoggableEvent<LoggedByDefault>
    ) => Promise<void> | void;
    /** Field value */
    value: ValidRadioValue;
    /**
     * Identifies the element (or elements) that describes the object.
     */
    "aria-describedby"?: string;
    /**
     * Defines a string value that labels the current element.
     */
    "aria-label"?: string;
    /**
     * Identifies the element (or elements) that labels the current element.
     */
    "aria-labelledby"?: string;
    /**
     * Defines a string value that labels the current element.
     *
     * @deprecated Please use aria-label instead.
     */
    ariaLabel?: string;
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
    /**
     * A `clientId` prop is provided for specified elements, which is a unique string that appears as a data attribute
     * `data-client-id` in the rendered code, serving as a hook for automated tests
     * we have 2 different client ids generated based on the one you pass to the Radio component:
     * - `{clientId}--hidden-radio` to check if it got changed to checked/unchecked.
     * - `{clientId}--radio-label` to click the input, because in IE11 the input has opacity: 0 and can't be interacted.
     */
    clientId?: string;
    /**
     * An optional id string to apply to the underlying input element.
     */
    id?: string;
    /** Field disabled */
    isDisabled?: boolean;
    /** Field is invalid */
    isInvalid?: boolean;
    /** Marks this as a required field */
    isRequired?: boolean;
    /** The label value for the input rendered to the dom */
    label?: ReactNode;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    onFocus?: React.FocusEventHandler<HTMLInputElement>;
    /** onInvalid event handler, passed into the props of each Radio Component instantiated within RadioGroup */
    onInvalid?: React.FormEventHandler<HTMLInputElement>;
    onMouseDown?: React.MouseEventHandler;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
    onMouseUp?: React.MouseEventHandler;
}

export const Radio: FC<RadioProps> = (props) => {
    const {
        "aria-label": ariaLabel,
        "aria-labelledby": ariaLabelledby,
        "aria-describedby": ariaDescribedBy,
        id,
        isDisabled = false,
        isRequired,
        isInvalid = false,
        isChecked = false,
        label,
        name,
        onBlur: providedOnBlur,
        onChange: providedOnChange,
        onFocus: providedOnFocus,
        onMouseDown: providedOnMouseDown,
        onMouseUp: providedOnMouseUp,
        onMouseEnter: providedOnMouseEnter,
        onMouseLeave: providedOnMouseLeave,
        onInvalid,
        value,
        clientId,
        className,
        ...rest
    } = props;

    const loggingCtx = useContext(LoggerContext);

    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isMouseDown, setIsMouseDown] = useState(false);

    const generatedId = useMemo(() => genId("radio"), []);
    const idToUse = id || generatedId;

    /**
     * TODO Remove this in a future release with this ticket:
     * https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/466
     * also put props deconstruction back and replace 'realAriaLabel' with just the aria-label
     */
    let realAriaLabel = ariaLabel;
    if (props.ariaLabel && !ariaLabel) {
        realAriaLabel = props.ariaLabel;
    }

    if (process.env.NODE_ENV !== "production") {
        if (props.ariaLabel) {
            console.warn(
                "The 'ariaLabel' prop has been deprecated in favor of the standard 'aria-label'. Please use that instead."
            );
        }
    }
    /** end of TODO */

    const onBlur: FocusEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            // onBlur is called after onMouseDown if the radio was focused, however
            // in this case on blur is called immediately after, and we need to check
            // whether the mouse is down.
            setIsActive(isMouseDown && isActive);
            setIsFocused(false);
            if (providedOnBlur) {
                providedOnBlur(event);
            }
        },
        [isActive, isMouseDown, providedOnBlur]
    );

    const onFocus: FocusEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            setIsFocused(true);
            if (providedOnFocus) {
                providedOnFocus(event);
            }
        },
        [providedOnFocus]
    );

    const onMouseLeave: MouseEventHandler = useCallback(
        (event) => {
            setIsActive(false);
            setIsHovered(false);
            if (providedOnMouseLeave) {
                providedOnMouseLeave(event);
            }
        },
        [providedOnMouseLeave]
    );

    const onMouseEnter: MouseEventHandler = useCallback(
        (event) => {
            setIsHovered(true);
            if (providedOnMouseEnter) {
                providedOnMouseEnter(event);
            }
        },
        [providedOnMouseEnter]
    );

    const onMouseUp: MouseEventHandler = useCallback(
        (event) => {
            setIsActive(false);
            setIsMouseDown(false);
            setIsHovered(false);
            if (providedOnMouseUp) {
                providedOnMouseUp(event);
            }
        },
        [providedOnMouseUp]
    );

    const onMouseDown: MouseEventHandler = useCallback(
        (event) => {
            setIsActive(true);
            setIsMouseDown(true);
            setIsHovered(false);
            if (providedOnMouseDown) {
                providedOnMouseDown(event);
            }
        },
        [providedOnMouseDown]
    );

    const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => {
            if (!isDisabled) {
                void new LoggingDecorator(loggingCtx, {
                    clientId,
                    controlType: "rdo",
                }).enabledByDefault(
                    {
                        actionId: LogActionId.CLICK,
                    },
                    providedOnChange
                )(event);
            }
        },
        [clientId, loggingCtx, providedOnChange, isDisabled]
    );

    const Root = useMemo(
        () => (label ? Label : Label.withComponent("div")),
        [label]
    );

    const eventHandlers = isDisabled
        ? {}
        : {
              onMouseDown,
              onMouseEnter,
              onMouseLeave,
              onMouseUp,
          };

    return (
        <Root
            isDisabled={isDisabled}
            isChecked={isChecked}
            data-client-id={clientId && `${clientId}--radio-label`}
            className={className}
            // Only apply this if there's actually a label.
            htmlFor={label ? idToUse : undefined}
        >
            <RadioInputWrapper>
                <HiddenInput
                    aria-label={realAriaLabel}
                    aria-labelledby={ariaLabelledby}
                    aria-describedby={ariaDescribedBy}
                    checked={isChecked}
                    aria-disabled={isDisabled}
                    id={idToUse}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    onInvalid={onInvalid}
                    onFocus={onFocus}
                    required={isRequired}
                    type="radio"
                    value={value}
                    data-client-id={clientId && `${clientId}--hidden-radio`}
                    {...rest}
                />
                <RadioIcon
                    isActive={isActive}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    isFocused={isFocused}
                    isHovered={isHovered}
                    isInvalid={isInvalid}
                    {...eventHandlers}
                />
            </RadioInputWrapper>
            {label ? <LabelText {...eventHandlers}>{label}</LabelText> : null}
        </Root>
    );
};
