import { StyledComponent } from "@emotion/styled";
import { Icon } from "@smartsheet/lodestar-icons";
import { LogActionId } from "@smartsheet/logging-state-service";
import React, {
    forwardRef,
    LabelHTMLAttributes,
    ReactNode,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { uid } from "react-uid";
import {
    useLoggingDecorator,
    LoggableEvent,
    LoggedByDefault,
} from "../../util/logging";
import { LodestarCoreTheme } from "../../util/theme";
import { useForkRef } from "../../util/useForkRef";
import { CheckboxWrapper } from "./styled/CheckboxWrapper";
import { CheckmarkIcon, IndeterminateIcon } from "./styled/CheckmarkIcon";
import { Input } from "./styled/Input";
import { Label } from "./styled/Label";
import { LabelText } from "./styled/LabelText";
import { StyledProps } from "./styled/StyledProps";
import {
    checkmarkSvg,
    indeterminateSvg,
} from "./styled/checkboxStyleConstants";

export type CheckedState = "unchecked" | "indeterminate" | "checked";

export interface CheckboxProps {
    /**
     * The current state of the checkbox. Though some props in this component are uncontrolled,
     * this one is controlled to provide a simpler API for consumers.
     * The uncontrolled props in this component are simply for user interaction.
     */
    checkedState: CheckedState;

    /** Handler to be called when the checkbox state is changed. */
    onClick: (
        e: React.MouseEvent<HTMLLabelElement>,
        log: LoggableEvent<LoggedByDefault>
    ) => Promise<void> | void;

    /**
     * Identifies the element (or elements) that describes the object.
     */
    "aria-describedby"?: string;

    /**
     * Applies aria-hidden to the hidden input inside the Checkbox.
     */
    "aria-hidden"?: boolean;

    /**
     * Defines a string value that labels the current element.
     */
    "aria-label"?: string;

    /**
     * Identifies the element (or elements) that labels the current element.
     */
    "aria-labelledby"?: string;

    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;

    /**
     * A `clientId` prop is provided for specified elements, which is a unique string that appears
     * as a data attribute `data-client-id` in the rendered code, serving as a hook for automated tests.
     * we have added 2 ids:
     *
     * - `clientId` that targets the Label component to interact with the component.
     * - `{clientId}--input` to check if the checkbox has been checked/unchecked.
     */
    clientId?: string;

    /**
     * Provide a custom id attribute to the Checkbox's underlying `input` element.
     * If not provided, one will be generated.
     */
    inputId?: string;

    /** Whether the checkbox is disabled or not. This will prevent any interaction with the user */
    isDisabled?: boolean;

    /** Marks the field as invalid. Changes style of unchecked component. */
    isInvalid?: boolean;

    /** Marks the field as required & changes the label style. */
    isRequired?: boolean;

    /** Label to be set for accessibility */
    label?: ReactNode;

    /** Descriptive name for value property to be submitted in a form */
    name?: string;

    omitHiddenInput?: boolean;

    /** Handler to be called when checkbox is unfocused */
    onBlur?: React.FocusEventHandler<HTMLInputElement>;

    /** Handler to be called when checkbox is focused. */
    onFocus?: React.FocusEventHandler<HTMLInputElement>;

    /**
     * The size of the Checkbox. This affects the dimensions of the checkbox itself but also the line-height of the optional label.
     *
     * Defaults to 'large'.
     */
    size?: "large" | "medium";

    /**
     * An optional tabIndex to specify. Inputs are included in the taborder by default, but
     * you can set this to -1 to remove it if you want. Please also add role="presentation"
     * and aria-hidden as well if you remove the Checkbox from the tab order.
     */
    tabIndex?: number;

    /**
     * If true, the Checkbox will use the aria-disabled attribute instead of the disabled
     * attribute for better a11y.
     */
    useAriaDisabled?: boolean;

    /** The value to be submitted in a form. */
    value?: string;
}

const noopChangeHandler = () => {
    // This is provided to the onChange handler of the input. It's only
    // here because React throws out a warning if you don't include it AND the value prop.
    // However, we do something special with the onClick event in the label
    // so we're all good.
    // Quiet please, React. ty.
};
/**
 * Map the users inputed size to the proper sizing in Icon.tsx.
 *
 * Default to small if there is no size or incorrect size.
 */
const mapCheckboxSize = (size: CheckboxProps["size"]) => {
    if (size === "medium") {
        return "xSmall";
    }
    return "small";
};

/**
 * Checkbox: select single values for form submission.
 */
export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
    (props, parentRef) => {
        const localRef = useRef<HTMLInputElement>(null);
        const ref = useForkRef(parentRef, localRef);

        const [isFocused, setIsFocused] = useState(false);
        const [isHovered, setIsHovered] = useState(false);

        const withLogging = useLoggingDecorator({
            clientId: props.clientId,
            // TODO pull from logging-state-service
            controlType: "cbx",
        });

        const setHoveredTrue = useCallback(() => setIsHovered(true), []);
        const setHoveredFalse = useCallback(() => setIsHovered(false), []);

        const {
            "aria-hidden": ariaHidden,
            "aria-label": ariaLabel,
            "aria-labelledby": ariaLabelledby,
            "aria-describedby": ariaDescribedBy,
            onBlur,
            onFocus,
            onClick,
            checkedState,
            className,
            inputId,
            isDisabled = false,
            isInvalid = false,
            isRequired = false,
            omitHiddenInput = false,
            label,
            size = "large",
            tabIndex,
            name,
            clientId,
            value,
            useAriaDisabled,
        } = props;

        const disabledStateProps = useAriaDisabled
            ? { "aria-disabled": isDisabled }
            : { disabled: isDisabled };

        if (process.env.NODE_ENV !== "production") {
            if (size !== "large" && label !== undefined) {
                console.warn(
                    "Medium Checkboxes cannot be used with the label prop. Add an aria-label or aria-labelledby to label it."
                );
            }
            if (!useAriaDisabled && isDisabled) {
                console.warn(
                    "Checkbox: the disabled attribute is bad for accessibility. Please include the useAriaDisabled prop to use the aria-disabled attribute instead of the disabled attribute."
                );
            }
        }

        const handleBlur: React.FocusEventHandler<HTMLInputElement> = (
            event
        ) => {
            setIsFocused(false);
            if (onBlur) {
                onBlur(event);
            }
        };

        const handleFocus: React.FocusEventHandler<HTMLInputElement> = (
            event
        ) => {
            setIsFocused(true);
            if (onFocus) {
                onFocus(event);
            }
        };

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        const handleClick: React.MouseEventHandler<HTMLLabelElement> = (
            event
        ) => {
            if (isDisabled) {
                return;
            }

            // The default action of a label around an input is to re-trigger the click event on the
            // input element. We need to ignore the first event on the label here or we'll call this
            // handler twice since the event will be re-fired on the input as well.
            // We could preventDefault in the first event, but for screen-reader support we must
            // have the event on the input element be handled, or the SR won't say "Space, checked".
            // It would just say "Space" and not provide the new state.
            if (label && event.target !== localRef.current) {
                event.stopPropagation();
                return;
            }

            return withLogging.enabledByDefault(
                { actionId: LogActionId.CLICK },
                onClick
            )(event as React.MouseEvent<HTMLLabelElement>);
        };

        const themeMode = useContext(LodestarCoreTheme);
        const styledProps: StyledProps = {
            checkedState,
            isDisabled,
            isFocused,
            isHovered,
            isInvalid,
            themeMode,
            size,
        };

        const id = useMemo(() => inputId || uid({ id: "Checkbox" }), [inputId]);

        // If there's no text label prop provided, this component will fail
        // some a11y scanners due to there being an empty <label>. This happens
        // even when an aria-label is provided anyways since that is stuck on
        // the inner input and the label is still empty.
        // To prevent a breaking change, we've hidden this in the onClick
        // signature but we'll eventually need to fix this with a major release.
        // See https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/387
        const Root = useMemo(
            () => (label ? Label : Label.withComponent("div")),
            [label]
        ) as StyledComponent<
            LabelHTMLAttributes<HTMLLabelElement>,
            // eslint-disable-next-line @typescript-eslint/ban-types
            object,
            // eslint-disable-next-line @typescript-eslint/ban-types
            object
        >;

        return (
            <Root
                htmlFor={label ? id : undefined}
                data-client-id={clientId}
                className={className}
                onClick={handleClick}
            >
                <Input
                    aria-hidden={ariaHidden}
                    aria-label={ariaLabel}
                    aria-labelledby={ariaLabelledby}
                    aria-describedby={ariaDescribedBy}
                    checked={checkedState === "checked"}
                    {...disabledStateProps}
                    id={id}
                    name={name}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onChange={noopChangeHandler}
                    ref={ref}
                    required={isRequired}
                    tabIndex={tabIndex}
                    hidden={omitHiddenInput}
                    type="checkbox"
                    {...(value !== undefined && { value })}
                    data-client-id={clientId && `${clientId}--input`}
                />
                <CheckboxWrapper
                    {...styledProps}
                    onMouseOver={setHoveredTrue}
                    onMouseOut={setHoveredFalse}
                >
                    <CheckmarkIcon {...styledProps}>
                        <Icon
                            color="inherit"
                            dangerouslySetSvg={checkmarkSvg}
                            size={mapCheckboxSize(props.size)}
                        />
                    </CheckmarkIcon>
                    <IndeterminateIcon {...styledProps}>
                        <Icon
                            color="inherit"
                            dangerouslySetSvg={indeterminateSvg}
                            size={mapCheckboxSize(props.size)}
                        />
                    </IndeterminateIcon>
                </CheckboxWrapper>
                {label && size === "large" && (
                    <LabelText isDisabled={isDisabled} isRequired={isRequired}>
                        {label}
                    </LabelText>
                )}
            </Root>
        );
    }
);

Checkbox.displayName = "Checkbox";
