/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgCheck: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M18.216 5.24l.027.017c.753.5.981 1.51.517 2.288l-6.516 10.923a1.084 1.084 0 01-1.736.175l-5.06-5.565a1.725 1.725 0 01.05-2.372 1.548 1.548 0 012.083-.11l.143.132 3.244 3.568 5.094-8.538a1.545 1.545 0 011.988-.61l.166.092z"
            />
        </g>
    </svg>
);
