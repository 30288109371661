/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { forwardRef, MouseEventHandler, ReactNode, useContext } from "react";
import * as colors from "../../../constants/colors";
import { LodestarCoreTheme, themed } from "../../../util/theme";

export interface StyledOptionProps {
    children: ReactNode;
    height: number;
    id: string;
    isFocused: boolean;
    isSelected: boolean;
    key: string;
    onClick: MouseEventHandler<HTMLDivElement>;
    onMouseMove: MouseEventHandler<HTMLDivElement>;
    onMouseOver: MouseEventHandler<HTMLDivElement>;
    tabIndex: number;
    className?: string;
    isDisabled?: boolean;
}

const optionTheme = themed({
    backgroundColor: {
        selected: {
            light: colors.neutralLight10,
            oldestar: "#e5f1fd",
        },
        hover: {
            light: colors.neutralLight10,
            oldestar: "#e5f1fd",
        },
        active: {
            light: colors.neutral,
            oldestar: "#e5f1fd",
        },
    },
});

export const StyledOption = forwardRef<HTMLDivElement, StyledOptionProps>(
    (
        {
            children,
            className,
            height,
            isFocused,
            isSelected,

            // TODO When we have a style for disabled options, use this.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
            isDisabled = false,
            ...rest
        },
        ref
    ) => {
        const theme = useContext(LodestarCoreTheme);

        const additionalStyles = css`
            box-sizing: border-box;
            border-radius: 4px;
            color: ${colors.neutralDark30};
            cursor: pointer;
            display: block;
            font-size: 13px;
            height: auto;
            line-height: 18px;
            margin: 0 4px;
            min-height: ${height}px;
            overflow: hidden;
            padding: 11px 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: 500;
            > * {
                white-space: normal;
            }

            &:hover {
                color: ${colors.neutralDark40};
                background-color: ${optionTheme.backgroundColor.hover(theme)};
            }

            ${isFocused &&
            `background-color: ${optionTheme.backgroundColor.selected(theme)};`}

            &:active {
                background-color: ${optionTheme.backgroundColor.active(theme)};
            }
        `;

        return (
            <div
                css={additionalStyles}
                className={className}
                aria-selected={isSelected ? true : undefined}
                role={"option"}
                ref={ref}
                {...rest}
            >
                {children}
            </div>
        );
    }
);
StyledOption.displayName = "StyledOption";
