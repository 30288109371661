/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { components } from "react-select";
import { neutralDark40, toRgba } from "../../../constants/colors";

const disabledColor = toRgba(neutralDark40, 0.3);

export const SingleValue: typeof components.SingleValue = ({
    children,
    ...rest
}) => {
    return (
        <components.SingleValue
            {...rest}
            css={css`
                color: ${rest.isDisabled ? disabledColor : neutralDark40};
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                margin: 0;
                padding: 0 8px;
            `}
        >
            {children}
        </components.SingleValue>
    );
};
