import { Ref, useCallback, useMemo, useRef } from "react";
import { Omit } from "utility-types";
import { MakeOptional } from "../../util/MakeOptional";
import { genId } from "../../util/genId";
import { useForkRef } from "../../util/useForkRef";
import { FlyoutProps } from "./Flyout";

type HookProvidedProps = keyof Pick<FlyoutProps, "targetElement">;

export type UseFlyoutOptions<TargetElement> = Omit<
    MakeOptional<FlyoutProps, "id">,
    HookProvidedProps
> & {
    /**
     * An optional ref to get access to the underlying target DOM element.
     */
    targetRef?: Ref<TargetElement>;
};

export interface UseFlyoutResult<TargetElementType> {
    flyoutProps: FlyoutProps;
    targetProps: {
        ref: Ref<TargetElementType>;

        /**
         * The id of the Flyout element. This attribute links them semantically for assistive tech.
         *
         * Only provided when the Flyout is open, otherwise undefined.
         */
        "aria-owns"?: string;
    };
}

/**
 * Hook that connects the Flyout and its target element
 * to support good a11y and make sure the refs are passed around properly!
 */
export function useFlyout<
    TargetElementType extends HTMLElement = HTMLButtonElement
>({
    id: providedFlyoutId,
    targetRef: providedTargetRef,
    onCloseRequested: providedOnCloseRequested,
    ...flyoutProps
}: UseFlyoutOptions<TargetElementType>): UseFlyoutResult<TargetElementType> {
    // Use the provided ref for the target element if it's there, otherwise make our own.
    const localRef = useRef<TargetElementType | null>(null);
    const targetRef = useForkRef<TargetElementType>(
        providedTargetRef || null,
        localRef
    );

    // Sync the ids between the target and Flyout.
    const generatedId = useMemo(() => genId("flyout-target"), []);
    const flyoutId = providedFlyoutId || generatedId;

    // Add a default onCloseHandler that re-focuses the target element.
    const onCloseRequested = useCallback(() => {
        providedOnCloseRequested();

        if (localRef.current) {
            localRef.current.focus();
        }
    }, [providedOnCloseRequested]);

    // These things should be spread onto their corresponding React components.
    // They are all the things a component needs to be happy and Flyout-ey.
    return {
        targetProps: {
            "aria-owns": flyoutProps.isOpen ? flyoutId : undefined,
            ref: targetRef,
        },
        flyoutProps: {
            ...flyoutProps,
            id: flyoutId,
            targetElement: localRef,
            onCloseRequested,
        },
    };
}
