export const WIDTH_ENUM: WidthEnumType = {
    values: ["xSmall", "small", "medium", "large", "xLarge"],
    widths: {
        xSmall: 352,
        small: 480,
        medium: 664,
        large: 768,
        xLarge: 872,
    },
    defaultValue: "medium",
};

export type WidthNames = "xSmall" | "small" | "medium" | "large" | "xLarge";
export interface WidthEnumType {
    defaultValue: string;
    values: WidthNames[];
    widths: { [index in WidthNames]: number };
}

export const gutter = 60;
