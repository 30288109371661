import * as colors from "../../../constants/colors";
import { themed } from "../../../util/theme";
import { StyledProps } from "./StyledProps";

export const getHeight = ({ size }: StyledProps) =>
    size === "medium" ? 16 : 20;
export const getWidth = ({ size }: StyledProps) =>
    size === "medium" ? 16 : 20;
export const getBorderWidth = () => 2;
export const transition = "0.2s";

export const checkmarkSvg = `
<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <path d="M 13.7 4.41 C 14.14 3.69 15.08 3.48 15.79 3.94 C 16.45 4.38 16.65 5.26 16.24 5.94 L 10.36 15.73 C 9.95 16.41 9 16.5 8.46 15.92 L 3.94 10.98 C 3.4 10.39 3.42 9.48 3.98 8.92 C 4.58 8.32 5.57 8.34 6.14 8.96 L 9.05 12.14 L 13.7 4.41 Z" />
</svg>
`;
export const indeterminateSvg = `
<svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
  <rect x="4" y="8.5" width="12" height="3" rx="1.5" />
</svg>
`;

export const checkboxColors = themed({
    unchecked: {
        rest: {
            background: colors.neutralLight40,
            border: {
                light: colors.neutralAccessible,
                oldestar: "#cccccc",
            },
            checkmark: "transparent",
            dash: "transparent",
        },
        hovered: {
            background: {
                light: colors.neutralLight10,
                oldestar: "#f3f3f3",
            },
            border: {
                light: colors.neutralAccessible,
                oldestar: "#c3c3c3",
            },
            checkmark: {
                light: colors.toRgba(colors.neutralLight40, 1.0),
                oldestar: "transparent",
            },
            dash: "transparent",
        },
        disabled: {
            background: colors.neutralLight40,
            border: {
                light: colors.toRgba(colors.neutralAccessible, 0.4),
                oldestar: colors.toRgba(colors.neutralAccessible, 0.3),
            },
            checkmark: {
                light: "transparent",
                oldestar: "transparent",
            },
            dash: {
                light: "transparent",
                oldestar: "transparent",
            },
        },
        invalid: {
            background: colors.neutralLight40,
            border: {
                light: colors.buttonDestructive,
                oldestar: colors.buttonDestructive,
            },
            checkmark: {
                light: "transparent",
                oldestar: "transparent",
            },
            dash: {
                light: "transparent",
                oldestar: "transparent",
            },
        },
    },
    checked: {
        rest: {
            background: {
                light: colors.buttonPrimary,
                oldestar: "#0073ec",
            },
            border: "transparent",
            checkmark: colors.neutralLight40,
            dash: "transparent",
        },
        hovered: {
            background: {
                light: colors.buttonPrimary,
                oldestar: "#005cbc",
            },
            border: "transparent",
            checkmark: {
                light: colors.toRgba(colors.neutralLight40, 0.6),
                oldestar: "#ffffff",
            },
            dash: "transparent",
        },
        disabled: {
            background: {
                light: colors.toRgba(colors.buttonPrimary, 0.4),
                oldestar: colors.toRgba("#0073ec", 0.4),
            },
            border: "transparent",
            checkmark: colors.neutralLight40,
            dash: "transparent",
        },
        invalid: {
            background: {
                light: colors.buttonDestructive,
                oldestar: colors.buttonDestructive,
            },
            border: "transparent",
            checkmark: colors.neutralLight40,
            dash: "transparent",
        },
    },
    indeterminate: {
        rest: {
            background: {
                light: colors.buttonPrimary,
                oldestar: "#0073ec",
            },
            border: "transparent",
            checkmark: "transparent",
            dash: colors.neutralLight40,
        },
        hovered: {
            background: {
                light: colors.buttonPrimary,
                oldestar: "#005cbc",
            },
            border: "transparent",
            checkmark: "transparent",
            dash: {
                light: colors.toRgba(colors.neutralLight40, 0.6),
                oldestar: "#ffffff",
            },
        },
        disabled: {
            background: {
                light: colors.toRgba(colors.buttonPrimary, 0.4),
                oldestar: colors.toRgba("#0073ec", 0.4),
            },
            border: "transparent",
            checkmark: "transparent",
            dash: colors.neutralLight40,
        },
        invalid: {
            background: {
                light: colors.buttonDestructive,
                oldestar: colors.buttonDestructive,
            },
            border: "transparent",
            checkmark: "transparent",
            dash: colors.neutralLight40,
        },
    },
});

export const getColors = ({
    checkedState,
    isDisabled,
    isHovered,
    isInvalid,
}: StyledProps) => {
    const stateColorOptions =
        checkedState && checkboxColors[checkedState]
            ? checkboxColors[checkedState]
            : checkboxColors["unchecked"];

    if (isDisabled) {
        return stateColorOptions.disabled;
    }
    if (isInvalid) {
        return stateColorOptions.invalid;
    }
    if (isHovered) {
        return stateColorOptions.hovered;
    }

    return stateColorOptions.rest;
};
