import styled from "@emotion/styled";
import { neutral } from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import { getFocusRingStyles } from "../../../util/focusRing";
import { ThemeMode } from "../../../util/theme";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";
import { convertToIcon } from "../../icon";

export const CloseRestSvg = convertToIcon(`
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M17.091 8.606l-3.395 3.393 3.395 3.395-1.697 1.697L12 13.696l-3.393 3.395-1.697-1.697L10.302 12 6.91 8.606l1.697-1.697 3.393 3.393 3.395-3.393 1.697 1.697z"/>
    </svg>
`);

export const CloseHoverSvg = convertToIcon(`
    <svg viewBox="0 0 24 24" width="24px" height="24px" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M 17.09 8.61 L 13.69 12 L 17.09 15.39 L 15.39 17.09 L 12 13.7 L 8.61 17.09 L 6.91 15.39 L 10.3 12 L 6.91 8.61 L 8.61 6.91 L 12 10.3 L 15.39 6.91 Z M 2 6 L 2 18 C 2 20.21 3.79 22 6 22 L 18 22 C 20.21 22 22 20.21 22 18 L 22 6 C 22 3.79 20.21 2 18 2 L 6 2 C 3.79 2 2 3.79 2 6 Z" />
    </svg>
`);

function createFocusRing({ themeMode }: CloseButtonProps) {
    return getFocusRingStyles({
        theme: themeMode,
        includeFocusPseudoClass: true,
        offset: 5,
        radius: 4,
    });
}

interface CloseButtonProps {
    isDisabled: boolean;
    themeMode: ThemeMode;
}

export const CloseButton =
    withFocusToggleClassName(styled.button<CloseButtonProps>`
        margin: 0;
        padding: 0;
        background-color: transparent;
        border: none;
        height: 28px;
        padding-right: ${sizes.xxSmall}px;
        color: inherit;

        // This doesn't do anything in Chrome because it's dealt with in the user-agent-stylesheet
        // But in every other browser it's necessary to vertically center the button.
        display: inline-flex;
        align-items: center;

        ${createFocusRing}

        &:hover .token--icon--hover {
            display: inline-block;
        }

        ${({ isDisabled }) =>
            isDisabled
                ? ``
                : `&:hover .token--icon-rest {
                  display: none;
              }`}

        ${({ isDisabled }) =>
            isDisabled ? `color: ${neutral};` : "cursor: pointer;"}
    `);
CloseButton.displayName = "CloseButton";
