import { ReactNode, CSSProperties, FC, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import invariant from "tiny-invariant";

export interface PortalProps {
    /*
     * Children to render in the React Portal.
     */
    children: ReactNode;

    /**
     * For advanced use-cases only: The parent element to portal into.
     * If none is provided, Portal will fall back to its standard behavior of placing the portal
     * in a stacking context it creates near the document's body element.
     */
    appendTo?: HTMLElement;

    /*
     * The z-index of the DOM container element.
     * This will be applied to your custom element if you provide the `appendTo` prop.
     */
    zIndex?: CSSProperties["zIndex"];
}

const createContainer = (): HTMLElement => {
    const container = document.createElement("div");
    container.setAttribute("class", "lodestar-portal");
    return container;
};

const getBody = (): HTMLElement => {
    invariant(document && document.body, "cannot find document.body");
    return document.body;
};

const getPortalParent = (): HTMLElement => {
    const parentElement = document.querySelector<HTMLElement>(
        "body > .lodestar-portal-container"
    );
    if (!parentElement) {
        const parent = document.createElement("div");
        parent.setAttribute("class", "lodestar-portal-container");
        parent.setAttribute("style", `display: flex;`);
        getBody().appendChild(parent);
        return parent;
    }
    return parentElement;
};

export const Portal: FC<PortalProps> = ({
    children,
    appendTo: customContainerElement,
    zIndex,
}) => {
    const [container, setContainer] = useState<HTMLElement | null>(null);

    // Choose a container.
    useEffect(() => {
        const newContainer = customContainerElement || createContainer();

        setContainer(newContainer);

        // If we are creating our own portal container element, manage it.
        // Otherwise assume nothing about the provided container element.
        if (!customContainerElement) {
            getPortalParent().appendChild(newContainer);

            return () => {
                // Don't forget to clean up! But there might not be anything
                // to clean up if there was a custom container provided.
                getPortalParent().removeChild(newContainer);
                // clean up parent element if there are no more portals.
                const portals = !!document.querySelector(
                    "body > .lodestar-portal-container > .lodestar-portal"
                );
                if (!portals) {
                    getBody().removeChild(getPortalParent());
                }
            };
        }
    }, [customContainerElement]);

    // Sync the z-index to the chosen container.
    useEffect(() => {
        if (container) {
            // If we have a custom element, only set the z-index if it's specified, we don't
            // even want to set a z-index of 0 to the custom element if it's not asked for.
            if (
                (customContainerElement && zIndex !== undefined) ||
                !customContainerElement
            ) {
                container.style.zIndex = `${zIndex || 0}`;
            }
        }
    }, [container, zIndex, customContainerElement]);

    return container ? ReactDOM.createPortal(children, container) : null;
};
