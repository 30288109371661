import styled from "@emotion/styled";
import * as colors from "../../constants/colors";
import { ThemeMode } from "../../util/theme";

// These are used to derive the fact that a 4 line textarea with
// box-sizing: border-box is exactly 94px high, so we can avoid
// magic numbers.
export const BORDER_WIDTH = 2;
export const BORDER_WIDTH_OLDESTAR = 1;
export const VERTICAL_PADDING = 11;
export const LINE_HEIGHT_EM = 1.38;
export const FONT_SIZE = 13;
export const SIZING_BUFFER = 1;

export const StyledTextarea = styled.textarea<{
    disableManualResize?: boolean;
    themeMode?: ThemeMode;
    validationState?: string;
}>`
    border: ${(props) => (props.themeMode === "oldestar" ? 1 : 2)}px solid
        ${(props) =>
            props.validationState === "error"
                ? colors.buttonDestructive
                : props.themeMode === "oldestar"
                ? "#cccccc"
                : colors.neutralLight10};
    border-radius: ${(props) => (props.themeMode === "oldestar" ? 2 : 4)}px;
    padding: ${VERTICAL_PADDING}px 12px ${VERTICAL_PADDING - SIZING_BUFFER}px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 100ms ease-in-out;
    resize: ${(props) => (props.disableManualResize ? "none" : "vertical")};
    color: ${colors.neutralDark40};

    /* Font */
    font-size: ${FONT_SIZE}px;
    font-weight: ${(props) => (props.themeMode === "oldestar" ? 400 : 500)};
    line-height: ${LINE_HEIGHT_EM};
    font-family: inherit;

    &:hover {
        border-color: ${(props) =>
            props.validationState === "error"
                ? colors.buttonDestructive
                : props.themeMode === "oldestar"
                ? "#a0a0a0"
                : colors.neutral};
    }

    &::placeholder {
        color: ${colors.neutralDark20};
    }

    &:hover::placeholder {
        color: ${colors.neutralDark40};
    }
    &[aria-disabled="true"] {
        background-color: ${colors.neutralLight30};
        border-color: ${colors.neutralLight30};
        color: ${colors.neutralDark10};
    }
    &:focus {
        border-color: ${(props) =>
            props.validationState === "error"
                ? colors.buttonDestructive
                : props.themeMode === "oldestar"
                ? "#005ee0"
                : colors.focus};
    }
`;
