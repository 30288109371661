/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { useFocusModeListener } from "../../util/useFocusModeListener";
import { BaseButton } from "./BaseButton";
import { ButtonAssignProps } from "./ButtonTypes";
import { ButtonAppearances } from "./types";

export interface ButtonProps extends ButtonAssignProps {
    /**
     * One of a standard set of Lodestar Button appearances.
     */
    appearance?: ButtonAppearances;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ type = "button", appearance = "primary", ...props }, ref) => {
        if (process.env.NODE_ENV !== "production") {
            if (
                !props.children &&
                (props.iconBefore || props.iconAfter) &&
                !props["aria-label"] &&
                !props["aria-labelledby"]
            ) {
                console.warn(
                    "No aria-label or aria-labelledby provided to ICON-ONLY BUTTON component!"
                );
            }
        }

        const focusMode = useFocusModeListener(appearance === "borderless");
        return (
            <BaseButton
                {...props}
                appearance={appearance}
                type={type}
                innerRef={ref}
                focusMode={focusMode}
                renderAs={"button"}
            />
        );
    }
);
