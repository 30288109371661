import styled from "@emotion/styled";

export const TokenContainer = styled.span<{
    backgroundColor: string;
    textColor: string;
}>`
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ textColor }) => textColor};
    border-radius: 2px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-left: 4px;

    // Ellide if the token is wider than its container.
    overflow: hidden;
`;
