// Needed to provide a non-scrolling layout for positioning the keyline
import styled from "@emotion/styled";
import { sizes } from "../../../constants/spacing";
import { flexMaxHeightIEFix } from "../../../util/flex-max-height-ie-fix";
import { ScrollAreaGutter } from "../types";

export interface StyledScrollAreaScrollProps {
    /**
     * gutter is the amount of padding that should be applied to the StyledScrollAreaScroll component.
     */
    gutter: ScrollAreaGutter;
}

export const StyledScrollArea = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0; // allow it to be less than the height of its children
    ${flexMaxHeightIEFix};
    position: relative; // for keyline positioning
`;

// Parent of body content. Provides scrolling context
export const StyledScrollAreaScroll = styled.div<StyledScrollAreaScrollProps>`
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    ${flexMaxHeightIEFix};
    padding: ${(props) => {
        switch (props.gutter) {
            case "none":
                return "0px";
            case "legacy":
                return `0px ${sizes.large}px 0px ${sizes.xLarge}px`;
            default:
                return `0px ${sizes[props.gutter]}px 0px ${
                    sizes[props.gutter]
                }px;`;
        }
    }};
    // Keep scrollbar 8px off the edge for a legacy gutter to preserve back-compat.
    // Otherwise we want consumers to be able to specify their own margins.
    margin-right: ${(props) =>
        props.gutter === "legacy"
            ? sizes.xSmall
            : 0}px; // keep scrollbar 8px off the edge
    overflow-y: auto;
    overflow-x: hidden;

    @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
    }
    // This odd linethat doesn't seem to do anything is needed for a bugfix
    // where an ancestor picked up the height of the hidden scroll content
    // https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/649
    transform: scale(1);
`;
