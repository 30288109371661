import { useState, useEffect, useContext } from "react";
import { StackOrderContext } from "./StackOrderContext";
import { StackOrderManagerSubscription } from "./StackOrderManager";

export interface UseStackOrderOptions {
    /**
     * Indicates if the subscribing component is displayed, when true the component is
     * considered to be part of the stack.
     */
    isOpen: boolean;

    /**
     * Function to be triggered when this component should be closed, generally expected to
     * result in isOpen being set to false
     *
     * This fn will be called if:
     *   1. the component is open AND the active item in the stack
     *   2. the component is not part of a stack i.e. there is no StackOrderContextProvider above it.
     */
    onCloseRequested: () => void;

    /**
     * Allows components to ignore escape key presses when considering if they should be closed.
     * If set to false, onCloseRequested will not be called when Escape is pressed, even if that
     * component is the active item.
     */
    shouldCloseOnEscape?: boolean;
}

export type UseStackOrderResult = {
    /**
     * true if this item is the top-most within the active stack.
     * If there is no active stack, this will be false.
     */
    isActiveItem: boolean;

    /**
     * true if this item is within a stack.
     */
    isWithinStack: boolean;
};

export const useStackOrder = ({
    isOpen,
    onCloseRequested,
    shouldCloseOnEscape = true,
}: UseStackOrderOptions): UseStackOrderResult => {
    const [stackOrderSub, setStackOrderSub] =
        useState<StackOrderManagerSubscription | null>(null);
    const stackOrderContextValue = useContext(StackOrderContext);

    useEffect(() => {
        if (isOpen && stackOrderContextValue) {
            const stackOrderSub =
                stackOrderContextValue.stackOrderManager.addToStack();
            setStackOrderSub(stackOrderSub);

            return () => {
                stackOrderSub?.remove();
            };
        }
    }, [stackOrderContextValue, isOpen]);

    useEffect(() => {
        if (isOpen) {
            const handleKeyDown = (event: KeyboardEvent) => {
                // bail if we are in a stack but aren't the active item.
                if (stackOrderSub && !stackOrderSub.isActiveItem()) {
                    return;
                }

                if (event.key === "Esc" || event.key === "Escape") {
                    if (shouldCloseOnEscape) {
                        onCloseRequested();
                    }
                }
            };

            document.addEventListener("keydown", handleKeyDown);

            return () => {
                document.removeEventListener("keydown", handleKeyDown);
            };
        }
    }, [
        isOpen,
        stackOrderContextValue,
        onCloseRequested,
        shouldCloseOnEscape,
        stackOrderSub,
    ]);

    return {
        isActiveItem: stackOrderSub?.isActiveItem() || false,
        isWithinStack: !!stackOrderContextValue && !!stackOrderSub,
    };
};
