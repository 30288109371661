/**
 * This file is generated from scripts/genIconComponents.js. Any
 * changes should be made in that file.
 */
import React, { forwardRef, Ref } from "react";
import { getSvgResourceForSize, getDefaultSize } from "../../utils";
import { Icon, LodestarIconProps } from "../../icon";
import { SvgDelete as DeleteMedium } from "./medium/Delete";
const sizeMap = {
    medium: DeleteMedium,
};
const defaultSize = getDefaultSize(sizeMap);

export const DeleteIcon = forwardRef(
    (
        { size = defaultSize, ...rest }: LodestarIconProps,
        ref: Ref<HTMLElement>
    ) => (
        <Icon
            ref={ref}
            svgComponent={getSvgResourceForSize(sizeMap, size)}
            size={size}
            {...rest}
        />
    )
);
DeleteIcon.displayName = "DeleteIcon";
