import styled from "@emotion/styled";
import {
    neutralDark40,
    neutralLight40,
    toRgba,
} from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import { getFocusRingStyles } from "../../../util/focusRing";
import { FocusMode } from "../../../util/useFocusMode";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";

const focusRing = getFocusRingStyles({
    colorOverride: "currentColor",
    offset: 2,
    radius: 6,
    theme: "light",
    includeFocusPseudoClass: true,
});

const hoverAndFocusBackground = toRgba(neutralLight40, 0.2);
const pressedBackground = toRgba(neutralDark40, 0.12);

const maybeApplyFocusBackground = ({ focusMode }: BannerActionStyledProps) => {
    return focusMode === "keyboard"
        ? `background-color: ${hoverAndFocusBackground};`
        : "";
};

export interface BannerActionStyledProps {
    focusMode: FocusMode;
}

export const BannerActionStyled = withFocusToggleClassName(styled(
    "button"
)<BannerActionStyledProps>`
    display: inline-flex;
    align-items: center;
    box-sizing: border-box;
    height: 32px;
    min-width: 32px;
    padding: 7px ${sizes.xxSmall}px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    color: currentColor;

    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    white-space: nowrap;

    ${focusRing}

    background-color: transparent;

    &:hover {
        background-color: ${hoverAndFocusBackground};
    }

    &:active {
        background-color: ${pressedBackground};
    }

    &:focus {
        ${maybeApplyFocusBackground}
    }
`);
BannerActionStyled.displayName = "BannerActionContainer";
