import React, { createContext, FC, useContext, useMemo } from "react";
import { layers } from "../../constants/layers";

// This is to support the weird case where our select menu doesn't
// portal to the same container as the rest, causing z-index ordering not to work.
const __unstable_select_v1_menu = "__unstable-select-v1-menu";

/**
 * The goal here is to allow our components to make a best-guess at their current layer and
 * try to render within it.
 *
 * This avoids people having to specify the modal layer every time they are rendering a
 * Flyout within a Modal.
 */
export interface LayerContextType {
    layer: keyof typeof layers | typeof __unstable_select_v1_menu;
}

const LayerContext = createContext<LayerContextType>({
    layer: "surface",
});

export interface LayerContextProviderProps {
    /**
     * Specifies a layer to use by default. If a LayerContext exists above this one in the React
     * component hierarchy, that layer will be used if it's larger than this default layer.
     */
    defaultLayer: LayerContextType["layer"];
}

/**
 * If this returns a layer, apply the same layer to your component, it should be
 * rendered within that layer.
 *
 * @returns the current LayerContext value, or null if there is no context available.
 */
export const useLayerContext = () => useContext(LayerContext);

export const LayerContextProvider: FC<LayerContextProviderProps> = ({
    children,
    defaultLayer,
}) => {
    // Look upwards to see if we're in a higher layer. If so, use that one.
    // Ex: A Flyout inside a Modal. The `defaultLayer` for a flyout is 490, but we should use 600
    // since the Flyout should sit on top of the modal layer.
    const { layer: inheritedLayer } = useLayerContext();
    const memoizedValue = useMemo<LayerContextType>(() => {
        // For now, if we're anywhere inside the menu, use that layer.
        if (
            inheritedLayer === __unstable_select_v1_menu ||
            defaultLayer === __unstable_select_v1_menu
        ) {
            return { layer: __unstable_select_v1_menu };
        } else {
            return {
                layer:
                    layers[inheritedLayer] > layers[defaultLayer]
                        ? inheritedLayer
                        : defaultLayer,
            };
        }
    }, [defaultLayer, inheritedLayer]);

    return (
        <LayerContext.Provider value={memoizedValue}>
            {children}
        </LayerContext.Provider>
    );
};

/**
 *
 * @returns the z-index of the layer this hook is run within, or undefined if there's no layer.
 *          undefined is nice because generally it is the same as not passing the property/prop.
 */
export const useLayerZIndex = (): number | undefined => {
    const { layer } = useContext(LayerContext);

    switch (layer) {
        case "surface":
            // This is the default case.
            // Don't apply 0 everywhere, please. That's confusing since it's not the CSS
            // default of 'auto'. 0 creates a new stacking context, which is not always what we want
            // to do.
            return undefined;
        case "__unstable-select-v1-menu":
            // For the select v1 menu, make sure anything in it will have a higher z-index.
            // This covers the case where there is a portal already in the DOM, then the
            // select menu renders, and then the select menu creates another portalled floating
            // element (like a Tooltip).
            return layers.context + 1;
        default:
            return layers[layer];
    }
};
