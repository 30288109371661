/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgHelp: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M11.997 2c5.518-.003 9.993 4.47 10 9.995C22.004 17.515 17.525 21.997 12 22c-5.522.002-9.994-4.47-10-10-.006-5.515 4.475-9.997 9.997-10zm.006 1.5c-4.688-.007-8.497 3.796-8.505 8.491-.009 4.693 3.798 8.504 8.499 8.51 4.688.004 8.491-3.795 8.503-8.495.01-4.686-3.797-8.498-8.497-8.505zm-.031 12.175a.825.825 0 110 1.65.838.838 0 01-.823-.833.824.824 0 01.823-.817zm.115-8.376a3.21 3.21 0 012.228.916c.479.464.78 1.022.86 1.69.054.452.02.89-.176 1.309a2.75 2.75 0 01-.649.857 5.039 5.039 0 01-.708.515c-.186.117-.37.238-.53.392a1.01 1.01 0 00-.328.778c.001.127.005.257-.02.38-.063.324-.307.532-.664.588a.771.771 0 01-.833-.469.787.787 0 01-.05-.209c-.066-.808.16-1.511.747-2.085.215-.21.454-.386.704-.55.17-.109.338-.218.5-.337.154-.113.286-.25.369-.427.108-.233.115-.478.05-.719a1.457 1.457 0 00-.948-1.023c-.548-.202-1.084-.163-1.583.153-.37.235-.605.57-.67 1.01-.013.09-.008.183-.023.273a.77.77 0 01-.795.64c-.37-.011-.68-.253-.75-.605a1.324 1.324 0 01-.008-.356c.03-.398.14-.772.34-1.118.471-.809 1.167-1.311 2.08-1.513.263-.058.528-.088.857-.09z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
