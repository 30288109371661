import React from "react";
import { FocusToggleStyles } from "./FocusToggleStyles";
import { MotionResetStyles } from "./MotionResetStyles";
import { ScrollbarStyles } from "./ScrollbarStyles";

export const LodestarGlobalStyles = () => {
    return (
        <>
            <FocusToggleStyles />
            <MotionResetStyles />
            <ScrollbarStyles />
        </>
    );
};
