import { neutralDark30, neutralDark40, neutralLight40 } from "./colors";

export const avatar = {
    fontWeight: 700,
    color: neutralLight40,
};

export const tag = {
    fontWeight: 500,
    lineHeight: 18,
    size: 14,
};

// Field labels, section headers
export const title = {
    lineHeight: 18,
    size: 13,
    color: neutralDark30,
};

export const body = {
    fontWeight: 400,
    lineHeight: 18,
    size: 15,
    color: neutralDark40,
};
