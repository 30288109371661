/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Interpolation } from "@emotion/serialize";
import styled, { StyledComponent } from "@emotion/styled";
import {
    forwardRef,
    useContext,
    ReactNode,
    Ref,
    AllHTMLAttributes,
    DetailedHTMLProps,
    HTMLAttributes,
    useMemo,
} from "react";
import { LodestarCoreTheme } from "../../util/theme";
import { typeRampDefinitions } from "./constants";

export interface TypeRampProps<
    // We need to leave this generic in here to avoid breaking changes, but it's not actually used.
    // It wasn't truly constraining anything, and the union types it created was causing a lot of
    // slowdown in the typecript build.
    // Remove this in a major release.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    EType extends keyof JSX.IntrinsicElements = "span"
> extends AllHTMLAttributes<HTMLElement> {
    /**
     * The list of children to render inline.
     */
    children: ReactNode;

    /**
     * String representation of the desired component.
     */
    variant: keyof typeof typeRampDefinitions;

    /**
     * A `clientId` which is a unique string that appears as a data attribute `data-client-id`
     * in the rendered code, serving as a hook for automated tests. Will be applied to the root
     * element of this component.
     */
    clientId?: string;

    /**
     * What element to render the wrapper as.
     * @default span
     */
    renderAs?: keyof JSX.IntrinsicElements;
}

const ComponentBase = styled("div")``;

export const TypeRamp = forwardRef(
    (
        { renderAs, children, clientId, variant, ...rest }: TypeRampProps,
        ref: Ref<HTMLElement>
    ): JSX.Element => {
        const themeMode = useContext(LodestarCoreTheme);
        const { component, theme, ...restStyles } =
            typeRampDefinitions[variant];
        const styleObject: Interpolation<undefined> = {
            ...restStyles,
            ...theme[themeMode],
        };

        const element = renderAs ? renderAs : component;

        // Memoizing this to ensure we won't create a new component each time.
        // withComponent returns a new component.
        // do we need the theme in here too??
        const ComponentRoot = useMemo(() => {
            return ComponentBase.withComponent(element);
        }, [element]) as StyledComponent<
            DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
            // This type doesn't matter here.
            // eslint-disable-next-line @typescript-eslint/ban-types
            object,
            Record<string, unknown>
        >;

        return (
            <ComponentRoot
                ref={ref}
                data-client-id={clientId}
                css={styleObject}
                {...rest}
            >
                {children}
            </ComponentRoot>
        );
    }
);

TypeRamp.displayName = "TypeRamp";
