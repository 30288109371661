import { MutableRefObject, Ref, RefCallback, useMemo } from "react";

function setRef<T>(ref: Ref<T>, value: T) {
    if (typeof ref === "function") {
        ref(value);
    } else if (ref) {
        (ref as MutableRefObject<T>).current = value;
    }
}

/**
 * Combines 1+ refs (functional or refobject) into a single functional ref.
 * Make sure you memoize the refs if they are callback functions or you'll get extra renders!
 */
export function useForkRef<T>(...refs: Ref<T>[]): RefCallback<T> | null {
    return useMemo(() => {
        if (refs.every((ref) => !ref)) {
            return null;
        }
        return (refValue) => {
            refs.forEach((r) => setRef(r, refValue));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, refs);
}
