/* eslint-disable @typescript-eslint/no-use-before-define */

import { useCallback, useLayoutEffect, useState } from "react";

/**
 * This set of utilities is used to provide a switch for focus styles.
 * Focus styles like outlines should only show up when the user is interacting with
 * the app using the keyboard. Once the mouse is used again, focus outlines should be
 * disabled.
 */

export const SHOW_FOCUS_MARKER = "lodestar--show-focus";

/**
 * The mouse event names to listen for to turn focus styles off.
 */
const mouseEvents = ["mousemove", "mousedown", "wheel"] as const;

/**
 * The keyboard event names to listen for to turn focus styles on.
 */
const keyEvents = ["keydown"] as const;

/**
 * The key event codes to listen for to turn focus styles on.
 */
const keyCodes = [
    "ArrowUp",
    "ArrowRight",
    "ArrowDown",
    "ArrowLeft",
    "Tab",
    "PageUp",
    "PageDown",
];

export interface Unsubscribe {
    (): void;
}

export type FocusMode = "keyboard" | "mouse";

interface UseFocusModeOptions {
    initialState?: FocusMode;
    watchElement?: HTMLElement;
}

/**
 * Hook that watches the document for key and mouse events to toggle between keyboard
 * and mouse modes.
 *
 * This hook assumes the initial mode to be mouse.
 */
export const useFocusMode = ({
    initialState = "mouse",
    watchElement = document.body,
}: UseFocusModeOptions = {}): FocusMode => {
    // Don't show focus initially - assume the initial interaction is through the mouse.
    const [focusMode, setFocusMode] = useState<"keyboard" | "mouse">(
        initialState
    );

    const setKeyboard = useCallback((event: KeyboardEvent) => {
        if (keyCodes.indexOf(event.key) === -1) {
            // Only pay attention to certain keys.
            return;
        }
        setFocusMode("keyboard");
    }, []);
    const setMouse = useCallback(() => setFocusMode("mouse"), []);

    const watchForKeyboardOn = useCallback(() => {
        keyEvents.forEach((eventName) => {
            watchElement.addEventListener(eventName, setKeyboard);
        });
    }, [setKeyboard, watchElement]);

    const watchForKeyboardOff = useCallback(() => {
        keyEvents.forEach((eventName) => {
            watchElement.removeEventListener(eventName, setKeyboard);
        });
    }, [setKeyboard, watchElement]);

    const watchForMouseOn = useCallback(() => {
        mouseEvents.forEach((eventName) => {
            watchElement.addEventListener(eventName, setMouse);
        });
    }, [setMouse, watchElement]);

    const watchForMouseOff = useCallback(() => {
        mouseEvents.forEach((eventName) => {
            watchElement.removeEventListener(eventName, setMouse);
        });
    }, [setMouse, watchElement]);

    useLayoutEffect(() => {
        switch (focusMode) {
            case "mouse":
                watchForKeyboardOn();
                break;
            case "keyboard":
                watchForMouseOn();
                break;
        }

        return () => {
            watchForKeyboardOff();
            watchForMouseOff();
        };
    }, [
        focusMode,
        watchForKeyboardOff,
        watchForKeyboardOn,
        watchForMouseOff,
        watchForMouseOn,
    ]);

    return focusMode;
};
