/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { useContext } from "react";
import { components } from "react-select";
import * as colors from "../../../constants/colors";
import { StaticPropContext } from "./StaticPropContext";

export const Menu: typeof components.Menu = ({ children, ...props }) => {
    const { clientId } = useContext(StaticPropContext);

    return (
        <components.Menu
            {...props}
            innerProps={{
                ...props.innerProps,
                // Yarg! React-select spreads this object onto the Menu but doesn't allow an arbitrary shape.
                // Let's just push through this since it's a safe thing to do in this case.
                // The Menu component in react-select doesn't do anything weird with this object.
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                "data-client-id": clientId && `${clientId}--menu`,
            }}
            css={css`
                margin: 4px 0 4px 0;
                border: 1px solid ${colors.shadowBorder};
                box-shadow: 0 4px 4px -1px ${colors.shadowMenu};
            `}
        >
            {children}
        </components.Menu>
    );
};
