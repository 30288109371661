import React from "react";
import { noop } from "../../constants/noop";
import { Div } from "./styled";

interface Props {
    /** Whether mouse events can pierce the blanket. If true, onClick will not be fired */
    canClickThrough?: boolean;
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
    /**
     A `clientId` prop is provided for specified elements, which is a unique string that
     appears as a data attribute `data-client-id` in the rendered code, serving as a hook
     for automated tests
     */
    clientId?: string;
    /** Whether the blanket has a tinted background color. */
    isTinted?: boolean;
    /** Handler function to be called when the blanket is clicked */
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    /** Handler function to be called when the blanket is clicked */
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * The main purpose of the blanket component is to provide the overlay layer for components such as a modal dialog or a tooltip.
 */
export const Blanket: React.FC<Props> = ({
    canClickThrough = false,
    className,
    isTinted = false,
    onClick = noop,
    onDoubleClick = noop,
    clientId,
}) => {
    const clickHandler = canClickThrough ? undefined : onClick;
    const dblClickHandler = canClickThrough ? undefined : onDoubleClick;
    return (
        <Div
            canClickThrough={canClickThrough}
            className={className}
            isTinted={isTinted}
            onClick={clickHandler}
            data-client-id={clientId}
            onDoubleClick={dblClickHandler}
        />
    );
};
