import { LogActionId } from "@smartsheet/logging-state-service";
import React, { PropsWithChildren } from "react";
import {
    LoggableEvent,
    LoggedByDefault,
    useLoggingDecorator,
    useLoggingEnabledByDefault,
} from "../../util/logging";
import { SelectBase, SelectBaseProps } from "./SelectBase";
import { SingleValue } from "./components/SingleValue";
import { ValueContainer } from "./components/ValueContainer";
import {
    DEFAULT_ITEM_HEIGHT,
    MAX_OPTIONS_UNTIL_VIRTUALIZATION,
} from "./selectConstants";
import {
    OptionType,
    ActionMeta,
    CommonSelectProps,
    OptionsType,
} from "./types";

export type SelectValueType<SelectOption extends OptionType> =
    | SelectOption
    | undefined;

export interface SelectProps<SelectOption extends OptionType>
    extends CommonSelectProps<SelectOption> {
    /** Callback invoked whenever a change of selection is made. Use this to change the data backing the `value` prop */
    onChange: (
        value: SelectValueType<SelectOption>,
        action: ActionMeta<SelectOption>,
        log: LoggableEvent<LoggedByDefault>
    ) => void;
    // Options was pulled out of CommonSelectProps for docs purposes
    /** Array of options that populate the select menu */
    options: OptionsType<SelectOption>;
    /** The currently selected option */
    value: SelectValueType<SelectOption>;
}

/**
 * Wraps the SelectBase component with correct props for a "Single" select (versus the blend of
 * Single- and Multi- select props that SelectBase takes).
 */
export const Select = <SelectOption extends OptionType>({
    validationState = "default",
    maxOptionsUntilVirtualization = MAX_OPTIONS_UNTIL_VIRTUALIZATION,
    optionHeight = DEFAULT_ITEM_HEIGHT,
    closeMenuOnScroll = false,
    createOptionPosition = "last",
    isClearable = true,
    isSearchable = true,
    menuPortalTarget = document.body,
    menuPlacement = "auto",
    onChange,
    components,
    ...restProps
}: PropsWithChildren<SelectProps<SelectOption>>) => {
    const withLogging = useLoggingDecorator({
        clientId: restProps.clientId,
        controlType: "sel",
    });
    const onChangeWithLogging = useLoggingEnabledByDefault(
        withLogging,
        {
            actionId: LogActionId.CLICK,
        },
        onChange
    );
    const newProps: SelectBaseProps<SelectOption> = {
        // Putting these above restProps as they were only created to provide defaults--so it
        // doesn't matter if these are before or after the restProps spread.
        validationState,
        maxOptionsUntilVirtualization,
        closeMenuOnScroll,
        createOptionPosition,
        isClearable,
        isSearchable,
        optionHeight,
        menuPortalTarget,
        menuPlacement,
        components: {
            SingleValue,
            ValueContainer,
            ...components,
        },
        ...restProps,

        // These *must* be after the restProps spread, since we don't want to let the consumer override them
        onChange: (value, action) => {
            void onChangeWithLogging(
                (Array.isArray(value)
                    ? value[0]
                    : value) as SelectValueType<SelectOption>,
                action
            );
        },
    };

    return <SelectBase<SelectOption> {...newProps} />;
};
