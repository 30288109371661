import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { sizes } from "../../../constants/spacing";

export type BannerActionsProps = HTMLAttributes<HTMLDivElement>;

export const BannerActions = styled("div")`
    display: inline-flex;
    align-items: center;
    margin: ${sizes.xSmall}px ${sizes.xSmall}px 0 0;
`;
