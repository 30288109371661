/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgInfo: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                stroke="currentColor"
                strokeWidth={0.583}
                d="M7 1.301c3.222 0 5.72 2.477 5.72 5.699S10.221 12.718 7 12.718c-3.222 0-5.694-2.496-5.694-5.718S3.778 1.301 7 1.301zm0 .875C4.262 2.176 2.181 4.262 2.181 7S4.261 11.843 7 11.843c2.738 0 4.844-2.105 4.844-4.843S9.738 2.176 7 2.176zm-.467 4.24h.428a.51.51 0 01.289.076.393.393 0 01.187.338v2.681c0 .19-.139.35-.338.394-.267.06-.454-.126-.464-.38V7.281c0-.05 0-.05-.055-.05-.056 0-.112 0-.168-.01a.415.415 0 01-.356-.429c.01-.18.176-.347.378-.372a.827.827 0 01.1-.005zM7 4.5a.501.501 0 11-.002.998A.501.501 0 017 4.5z"
            />
            <path d="M0 0h14v14H0z" />
        </g>
    </svg>
);
