export const sizes = {
    xxSmall: 4,
    xSmall: 8,
    small: 12,
    medium: 16,
    large: 24,
    xLarge: 32,
    xxLarge: 48,
} as const;

export type sizeNames = keyof typeof sizes;

export const modalSizes = {
    small: 320,
    medium: 480,
    large: 640,
    xLarge: 720,
} as const;
