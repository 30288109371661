/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useThrottledCallback } from "@react-hookz/web/cjs/useThrottledCallback/useThrottledCallback";
import rafSchedule from "raf-schd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ScrollLock from "react-scrolllock";
import { scrollbarClassNames } from "../../../constants/scrollbars";
import { getClientId } from "../../../util/getClientId";
import { StyledScrollArea, StyledScrollAreaScroll } from "../styled/Body";
import { Keyline } from "../styled/Keyline";
import { ScrollAreaGutter } from "../types";
import { getScrollInfo } from "../utils/scrollUtils";

export interface ScrollAreaProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * A `clientId` prop is provided for specified elements, which is a unique string
     * that appears as a data attribute `data-client-id` in the rendered code, serving as
     * a hook for automated tests
     */
    clientId?: string;
    /**
     * gutter is the amount of padding/margin that should be applied to the StyledScrollAreaScroll/Keyline component.
     * Default is Gutter.Legacy
     */
    gutter?: ScrollAreaGutter;

    /**
     * set to true to lock the page from scrolling.  Useful for modal contexts.
     */
    lockPageScroll?: boolean;
    /**
     * Apply a specific scrollbar style.
     */
    scrollbarStyle?: keyof typeof scrollbarClassNames;
}
/**
 * ScrollArea is a component to handle a vertically scrolling content area. It is responsible for:
 * - Rendering the content area
 * - Providing a scrolling context for the content area
 * - Optionally locking browser scroll (useful for when being used inside a modal, so the page doesn't scroll behind it)
 * - Rendering an affordance that there's more content below the fold
 */
export const ScrollArea: React.FC<ScrollAreaProps> = ({
    children,
    clientId,
    gutter = "legacy",
    lockPageScroll = false,
    scrollbarStyle,
    className,
    ...rest
}) => {
    const [showBottomKeyline, setShowBottomKeyline] = useState(false);
    const [localScroll, setLocalScroll] = useState(lockPageScroll);
    const scrollContainerRef = useRef<HTMLDivElement | null>(null);

    const determineKeylines = useMemo(
        () =>
            rafSchedule(() => {
                if (!scrollContainerRef.current) {
                    return;
                }

                const { top, viewportHeight, contentHeight } = getScrollInfo(
                    scrollContainerRef.current
                );

                const scrollableDistance = contentHeight - viewportHeight;
                const showBottomKeyline = top < scrollableDistance;

                setShowBottomKeyline(showBottomKeyline);
            }),
        []
    );

    // Attach handlers to the scroll container's current element to show/hide keylines.
    useEffect(() => {
        if (scrollContainerRef.current) {
            window.addEventListener("resize", determineKeylines);
            scrollContainerRef.current.addEventListener(
                "scroll",
                determineKeylines
            );
            determineKeylines();
        }

        return () => {
            if (typeof determineKeylines.cancel === "function") {
                determineKeylines.cancel();
            }
            if (scrollContainerRef.current) {
                window.removeEventListener("resize", determineKeylines);
                // eslint-disable-next-line react-hooks/exhaustive-deps
                scrollContainerRef.current.removeEventListener(
                    "scroll",
                    determineKeylines
                );
            }
        };
    }, [determineKeylines]);

    // also make sure we consider keylines on any render/re-render
    determineKeylines();

    const keylineIdSuffix = "lodestar-keyline";
    const keylineId = getClientId(clientId, keylineIdSuffix);
    const scrollClassName =
        scrollbarStyle && scrollbarClassNames[scrollbarStyle];
    const timerId = useRef<NodeJS.Timeout | null>(null);

    const throttledHandleResize = useThrottledCallback(
        () => {
            setLocalScroll(false);
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
            timerId.current = setTimeout(() => {
                setLocalScroll(true);
            });
        },
        [],
        30
    );
    useEffect(() => {
        window.addEventListener("resize", throttledHandleResize);
        return () => {
            window.removeEventListener("resize", throttledHandleResize);
            if (timerId.current) {
                clearTimeout(timerId.current);
            }
        };
    }, [throttledHandleResize]);

    /*  */
    return (
        <StyledScrollArea className={className} {...rest}>
            <StyledScrollAreaScroll
                data-client-id={clientId}
                gutter={gutter}
                ref={scrollContainerRef}
                className={scrollClassName}
            >
                {children}
                {lockPageScroll && <ScrollLock isActive={localScroll} />}
            </StyledScrollAreaScroll>
            <Keyline
                data-client-id={keylineId}
                isVisible={showBottomKeyline}
                gutter={gutter}
            />
        </StyledScrollArea>
    );
};
