/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, InterpolationWithTheme } from "@emotion/core";
import { FC } from "react";
import * as colors from "../../../constants/colors";
import { Spinner } from "../../spinner";
import { ButtonAppearances } from "../types";

type Props = {
    isDisabled: boolean;
    styles: InterpolationWithTheme<unknown>;
    appearance?: ButtonAppearances;
};

export const LoadingSpinner: FC<Props> = ({ styles, appearance }) => (
    <div css={styles}>
        <Spinner
            size="medium"
            color={
                appearance === "secondary" || appearance === "borderless"
                    ? colors.neutralDark10
                    : colors.neutralLight40
            }
        />
    </div>
);
