import React, { FC, useState, useMemo, useContext, ReactNode } from "react";

export interface LayoutContextShape {
    controlHeight: number;
    setControlHeight(newHeight: number): void;
}

const layoutContextDefaultControlHeight = 40;

/**
 * This Context's purpose is to sync layout across the various components in the Select/MultiSelect
 * components. Since we are using react-select, we sometimes need to go around the react-select
 * API. Things included in this context are shared parameters that should cause a re-render of the
 * component when they change.
 *
 * Note: we DON'T expose this directly on purpose to ensure the consumer always uses the
 * `LayoutContextProvider` which wraps up some functionality that's required to make this
 * all work properly.
 */
const LayoutContext = React.createContext<LayoutContextShape>({
    controlHeight: layoutContextDefaultControlHeight,
    setControlHeight: () => undefined,
});

export const useLayoutContext = () => {
    return useContext(LayoutContext);
};

export const LayoutContextProvider: FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [controlHeight, setControlHeight] = useState(
        layoutContextDefaultControlHeight
    );
    const layoutCtxValue = useMemo<LayoutContextShape>(
        () => ({
            controlHeight,
            setControlHeight,
        }),
        [controlHeight]
    );

    return (
        <LayoutContext.Provider value={layoutCtxValue}>
            {children}
        </LayoutContext.Provider>
    );
};
