/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { AnchorHTMLAttributes } from "react";
import { Assign } from "utility-types";
import { useFocusModeListener } from "../../util/useFocusModeListener";
import { BaseButton, BaseButtonProps } from "./BaseButton";
import { LinkAppearances } from "./types";

export interface LinkProps
    // Override all Anchor attributes with whatever is in BaseButtonProps.
    extends Assign<
        AnchorHTMLAttributes<HTMLAnchorElement>,
        // Pull out the props we don't actually want to expose to our consumers.
        Omit<
            BaseButtonProps<HTMLAnchorElement>,
            "focusMode" | "innerRef" | "renderAs" | "appearance"
        >
    > {
    /**
     * One of a standard set of Lodestar Link appearances.
     */
    appearance?: LinkAppearances;
}

export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
    ({ appearance = "link", ...props }, ref) => {
        const focusMode = useFocusModeListener(
            appearance === "button-borderless"
        );
        return (
            <BaseButton
                {...props}
                appearance={appearance}
                innerRef={ref}
                focusMode={focusMode}
                renderAs={"a"}
            />
        );
    }
);
