import { useEffect, useState } from "react";
import { FocusMode, SHOW_FOCUS_MARKER } from "./useFocusMode";

const getFocusMode = () =>
    document.body.classList.contains(SHOW_FOCUS_MARKER) ? "keyboard" : "mouse";

/**
 * This hook listens to the marker class applied to the document body
 * and causes a re-render when it changes.
 *
 * We use this for performance so we don't have to use `useFocusMode` more
 * than we need.
 *
 * @param enabled if true, this listener is enabled. If false, all functions will be paused.
 *               This can be helpful to only turn it on in some cases, since it is extra overhead.
 */
export const useFocusModeListener = (enabled = true): FocusMode => {
    const [mouseOrKeyboard, setMouseOrKeyboard] = useState<FocusMode>(
        getFocusMode()
    );

    useEffect(() => {
        if (enabled) {
            // Do a quick update in case it's change while we were disabled.
            setMouseOrKeyboard(getFocusMode());

            const obs = new MutationObserver(() =>
                setMouseOrKeyboard(getFocusMode())
            );
            obs.observe(document.body, {
                attributes: true,
                attributeFilter: ["class"],
            });
            return () => {
                obs.disconnect();
            };
        }
    }, [enabled]);

    return mouseOrKeyboard;
};
