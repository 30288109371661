/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgCheckFilled: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm4.08 6.06a1.016 1.016 0 00-1.395.347l-3.342 5.558-2.112-2.305a1.02 1.02 0 00-1.476-.032 1.116 1.116 0 00-.033 1.544l3.061 3.341a1 1 0 001.595-.16l4.076-6.782a1.103 1.103 0 00-.339-1.49l-.034-.021z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
