/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { CloseIcon } from "@smartsheet/lodestar-icons";
import { FC } from "react";
import { ButtonProps } from "../../button";
import { StyledXButton } from "../styled/XButton";

export interface XButtonProps extends ButtonProps {
    /**
     * the aria-label attribute associated with the button.
     */
    "aria-label": string;
    /**
     * Function that will be called to initiate the exit transition.
     * If not provided, no `x` button will be rendered.
     */
    onClick: () => void;
    /**
     * the aria-label attribute associated with the button.
     *
     * @deprecated Please use aria-label instead. The image is aria-hidden.
     */
    ariaLabel?: string;
    /**
     * Pass through props for the button
     */
    xButtonProps?: Partial<XButtonProps>;
}

export const XButton: FC<XButtonProps> = (props) => {
    const { "aria-label": ariaLabel, onClick, xButtonProps } = props;

    /**
     * This will be removed in a future release with this ticket:
     * https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/466
     */

    let realAriaLabel = ariaLabel;
    if (props.ariaLabel && !ariaLabel) {
        realAriaLabel = props.ariaLabel;
    }

    if (process.env.NODE_ENV !== "production") {
        if (props.ariaLabel) {
            console.warn(
                "The 'ariaLabel' prop has been deprecated in favor of the standard 'aria-label'. Please use that instead."
            );
        }
    }

    return (
        <StyledXButton
            {...xButtonProps}
            appearance="borderless"
            type="button"
            onClick={onClick}
            aria-label={realAriaLabel}
        >
            <CloseIcon />
        </StyledXButton>
    );
};
