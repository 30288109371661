import styled from "@emotion/styled";
import { ToggleSize } from "./toggleStyleConstants";

const margin = {
    large: 3,
    medium: -1,
};

export const LabelText = styled.span<{
    isDisabled: boolean;
    size: ToggleSize;
}>`
    // Ensure proper alignment, especially when labelPlacement="start"
    flex-grow: 1;
    // Add small margin to center-align first line of text with toggle.
    margin-top: ${({ size }) => margin[size]}px;

    ${({ isDisabled }) => {
        if (isDisabled) {
            return "opacity: 0.4;";
        }
    }}
`;
