/**
 * Use one of these className constants on a scrollable element
 * to apply a modified scrollbar style to it.
 */
export const scrollbarClassNames: {
    // Normally we would let the type flow here, but we don't want to expose the
    // actual strings here, just expose that they are a string.
    slim: string;
} = {
    slim: "lodestar--global-scrollbars--slim",
};
