import styled from "@emotion/styled";
import { gridSize } from "../../constants/grid";
import { tag as typography } from "../../constants/typography";

interface TagStyledProps {
    backgroundColor: string;
    textColor: string;
}

export const background = (p: TagStyledProps) => p.backgroundColor;
export const color = (p: TagStyledProps) => p.textColor;

export const TagStyled = styled.span<TagStyledProps>`
    display: inline-flex;
    background: ${background};
    color: ${color};
    font-size: ${typography.size}px;
    line-height: ${gridSize * 6}px;
    font-weight: ${typography.fontWeight};
    padding: 0px ${gridSize * 2}px;
    border-radius: 2px;
    white-space: nowrap;
`;
