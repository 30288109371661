/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgFeedback: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M6.979 16.362l.015.052c.378.954.752 1.91 1.134 2.862.244.608.935.865 1.498.565.452-.24.727-.819.495-1.402-.268-.67-.53-1.344-.792-2.016-.018-.046-.04-.062-.09-.062-.731.002-1.461.001-2.191.001h-.07zm13.485-5.39V5.376c0-.097-.007-.196-.024-.291-.11-.624-.652-1.01-1.185-1.044a1.281 1.281 0 00-.826.242c-1.185.812-2.37 1.624-3.557 2.434-.042.029-.055.059-.055.107v8.301c0 .05.015.079.056.107.742.506 1.482 1.013 2.223 1.52.468.32.933.646 1.406.958.324.213.681.257 1.052.14.565-.177.91-.656.91-1.255v-5.621zm-7.179 3.857V7.195c0-.079 0-.08-.076-.08l-6.306.001a3.337 3.337 0 00-3.365 3.297c-.004.359-.007.718 0 1.077a3.8 3.8 0 00.05.564 3.276 3.276 0 001.446 2.217c.573.386 1.211.558 1.9.556 1.01-.002 2.019 0 3.028 0l3.242.002h.081zm-2.33 1.534l.067.174c.19.49.399.973.57 1.47.287.838.171 1.636-.327 2.368-.365.537-.869.89-1.499 1.045-1.296.321-2.578-.35-3.074-1.598l-1.455-3.68a.156.156 0 00-.1-.1 4.775 4.775 0 01-1.861-1.27 4.83 4.83 0 01-1.193-2.395A4.95 4.95 0 012 11.42c.003-.41-.003-.822.012-1.232a4.547 4.547 0 01.406-1.703 4.825 4.825 0 011.918-2.19 4.576 4.576 0 011.838-.661c.252-.033.508-.05.762-.05 2.263-.005 4.526-.003 6.79-.002a.25.25 0 00.154-.047l3.69-2.527a2.798 2.798 0 011.697-.507 2.824 2.824 0 012.73 2.856c.004 3.744.003 7.488.003 11.232 0 .667-.194 1.27-.614 1.79a2.779 2.779 0 01-1.704 1.02c-.77.141-1.477-.027-2.121-.468l-3.69-2.527a.225.225 0 00-.133-.041h-2.784z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
