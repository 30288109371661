/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { CloseIcon } from "@smartsheet/lodestar-icons";
import { components } from "react-select";
import {
    neutral,
    neutralDark20,
    neutralDark40,
} from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";

export const ClearIndicator: typeof components.ClearIndicator = (props) => (
    <components.ClearIndicator
        {...props}
        css={css`
            ${props.selectProps.isDisabled
                ? `
                    color: ${neutral};
                    &:hover {
                        color: ${neutral};
                    }
                `
                : `
                    color: ${neutralDark20};
                    &:hover {
                        color: ${neutralDark40};
                    }
                `}
            padding: 0 ${sizes.xSmall}px 0 0;
        `}
    >
        <CloseIcon size="xSmall" aria-label="clear" />
    </components.ClearIndicator>
);
