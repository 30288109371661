/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, ClassNames } from "@emotion/core";
import { LoggerContext, LogActionId } from "@smartsheet/logging-state-service";
import React, { HTMLAttributes, Ref } from "react";
import { Assign } from "utility-types";
import { FOCUS_USING_BEFORE_CLASS_NAME } from "../../util/focusRing";
import {
    LoggableEvent,
    LoggedByDefault,
    LoggingDecorator,
    NotLoggedByDefault,
} from "../../util/logging";
import { LodestarCoreTheme } from "../../util/theme";
import { FocusMode } from "../../util/useFocusMode";
import { LinkOrButton } from "./LinkOrButton";
import { getButtonStyles, getSpinnerStyles } from "./getStyles";
import { Content } from "./subComponents/Content";
import { IconWrapper } from "./subComponents/IconWrapper";
import { LoadingSpinner } from "./subComponents/LoadingSpinner";
import {
    ButtonAppearances,
    LinkAppearances,
    ButtonIconRenderProps,
    ButtonSizes,
    ButtonState,
    StyleProps,
} from "./types";

export type BaseButtonProps<
    RootElementType extends HTMLButtonElement | HTMLAnchorElement
> = Assign<
    HTMLAttributes<RootElementType>,
    {
        focusMode: FocusMode;
        innerRef: Ref<RootElementType>;

        /** The base styling to apply to the button */
        appearance?: ButtonAppearances | LinkAppearances;
        /** Children react nodes will be inserted as siblings to any generated icon nodes */
        children?: React.ReactNode;
        /**
         * A `clientId` prop is provided for specified elements, which is a unique string that appears as a data attribute
         * `data-client-id` in the rendered code, serving as a hook for automated tests
         */
        clientId?: string;
        /**
         * Places an icon within the button, after the button's text. If this is a function,
         * some of the Button's internal state will be provided for the icon to
         * style itself accordingly.
         */
        iconAfter?: React.ReactChild | ButtonIconRenderProps;
        /**
         * Places an icon within the button, before the button's text. If this is a function,
         * some of the Button's internal state will be provided for the icon to
         * style itself accordingly.
         */
        iconBefore?: React.ReactChild | ButtonIconRenderProps;
        /** Set if the button is disabled */
        isDisabled?: boolean;
        /**
         * Set if the button is loading. When isLoading is true, text is hidden, and
         * a spinner is shown in its place. The button maintains the width that it
         * would have if the text were visible.
         */
        isLoading?: boolean;
        /** Handler to be called on click */
        onClick?: (
            e: React.MouseEvent<RootElementType>,
            log: LoggableEvent<LoggedByDefault>
        ) => Promise<void> | void;
        /**
         * Handler to be called on a double-click.
         */
        onDoubleClick?: (
            e: React.MouseEvent<RootElementType>,
            log: LoggableEvent<LoggedByDefault>
        ) => Promise<void> | void;
        onMouseDown?: (
            e: React.MouseEvent<RootElementType>,
            log: LoggableEvent<NotLoggedByDefault>
        ) => Promise<void> | void;
        onMouseUp?: (
            e: React.MouseEvent<RootElementType>,
            log: LoggableEvent<NotLoggedByDefault>
        ) => Promise<void> | void;

        renderAs?: "button" | "a";

        /** Option to fit button width to its parent width */
        shouldFitContainer?: boolean;

        /**
         * Sets the size of the Button to one of the standard Lodestar sizes.
         */
        size?: ButtonSizes;

        /**
         * This is the base type for the Anchor and Button element attr 'type'.
         */
        type?: string;
    }
>;

export class BaseButton<
    RootElementType extends HTMLAnchorElement | HTMLButtonElement
> extends React.Component<BaseButtonProps<RootElementType>, ButtonState> {
    static contextType = LoggerContext;
    context: React.ContextType<typeof LoggerContext> | undefined;
    withLogging: LoggingDecorator<"clientId" | "controlType">;

    static defaultProps = {
        isDisabled: false,
        isLoading: false,
        shouldFitContainer: false,
        size: "large",
        renderAs: "button",
    };

    state = {
        isActive: false,
        isFocus: false,
        isHover: false,
    };

    constructor(
        props: BaseButtonProps<RootElementType>,
        context: typeof LoggerContext
    ) {
        super(props, context);

        this.withLogging = this.getWithLogging();
    }
    public componentDidUpdate(prevProps: BaseButtonProps<RootElementType>) {
        if (this.props.clientId !== prevProps.clientId) {
            this.withLogging = this.getWithLogging();
        }
    }

    isInteractive = () => !this.props.isDisabled && !this.props.isLoading;

    onMouseEnter = (e: React.MouseEvent<RootElementType>) => {
        this.setState({ isHover: true });
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(e);
        }
    };

    onMouseLeave = (e: React.MouseEvent<RootElementType>) => {
        this.setState({ isHover: false, isActive: false });
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(e);
        }
    };

    onMouseDown = (e: React.MouseEvent<RootElementType>) => {
        this.setState({ isActive: true });
        if (this.props.onMouseDown) {
            void this.withLogging.disabledByDefault(
                {
                    actionId: LogActionId.MOUSE_DOWN,
                },
                this.props.onMouseDown
            )(e);
        }
    };

    onMouseUp = (e: React.MouseEvent<RootElementType>) => {
        this.setState({ isActive: false });
        if (this.props.onMouseUp) {
            void this.withLogging.disabledByDefault(
                {
                    actionId: LogActionId.MOUSE_UP,
                },
                this.props.onMouseUp
            )(e);
        }
    };

    onClick = (e: React.MouseEvent<RootElementType>) => {
        if (this.isInteractive()) {
            if (this.props.onClick) {
                void this.withLogging.enabledByDefault(
                    {
                        actionId: LogActionId.CLICK,
                    },
                    this.props.onClick
                )(e);
            }
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    onDoubleClick = (e: React.MouseEvent<RootElementType>) => {
        if (this.isInteractive()) {
            if (this.props.onDoubleClick) {
                void this.withLogging.enabledByDefault(
                    {
                        actionId: LogActionId.DOUBLE_CLICK,
                    },
                    this.props.onDoubleClick
                )(e);
            }
        } else {
            e.preventDefault();
            e.stopPropagation();
        }
    };

    onFocus: React.FocusEventHandler<RootElementType> = (event) => {
        this.setState({ isFocus: true });
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    onBlur: React.FocusEventHandler<RootElementType> = (event) => {
        this.setState({ isFocus: false });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    render() {
        const {
            renderAs,
            appearance = this.props.renderAs === "button" ? "primary" : "link",
            children,
            className,
            focusMode,
            innerRef,
            iconAfter,
            iconBefore,
            isDisabled = false,
            isLoading = false,
            shouldFitContainer = false,
            clientId,
            tabIndex,
            size = "large",
            ...rest
        } = this.props;

        const hasLeftIcon = iconBefore !== undefined;
        const hasRightIcon = iconAfter !== undefined;

        const styleProps: StyleProps = {
            state: { ...this.state, isDisabled },
            shouldFitContainer,
            size,
            focusMode,
            isLoading,
            appearance: appearance.replace("button-", "") as
                | ButtonAppearances
                | "link",
            hasOnlyIcons: children == null && (hasLeftIcon || hasRightIcon),
            hasNoIcons: !hasLeftIcon && !hasRightIcon,
            renderAs: renderAs,
        };

        // TODO: swap out custom component instead of merging it with our css
        return (
            <LodestarCoreTheme.Consumer>
                {(theme) => (
                    <ClassNames>
                        {({ cx }) => {
                            return (
                                <LinkOrButton
                                    {...rest}
                                    renderAs={renderAs}
                                    data-client-id={clientId}
                                    innerRef={innerRef}
                                    onMouseEnter={this.onMouseEnter}
                                    onMouseLeave={this.onMouseLeave}
                                    onMouseDown={
                                        this
                                            .onMouseDown as React.MouseEventHandler<
                                            | HTMLButtonElement
                                            | HTMLAnchorElement
                                        >
                                    }
                                    onMouseUp={
                                        this
                                            .onMouseUp as React.MouseEventHandler<
                                            | HTMLButtonElement
                                            | HTMLAnchorElement
                                        >
                                    }
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    aria-disabled={isDisabled}
                                    className={cx(
                                        className,
                                        FOCUS_USING_BEFORE_CLASS_NAME
                                    )}
                                    onClick={
                                        this.onClick as React.MouseEventHandler<
                                            | HTMLButtonElement
                                            | HTMLAnchorElement
                                        >
                                    }
                                    onDoubleClick={
                                        this
                                            .onDoubleClick as React.MouseEventHandler<
                                            | HTMLButtonElement
                                            | HTMLAnchorElement
                                        >
                                    }
                                    tabIndex={tabIndex}
                                    css={getButtonStyles(styleProps, theme)}
                                >
                                    {isLoading && (
                                        <LoadingSpinner
                                            appearance={
                                                appearance as ButtonAppearances
                                            }
                                            isDisabled={isDisabled}
                                            styles={getSpinnerStyles()}
                                        />
                                    )}
                                    {iconBefore && (
                                        <IconWrapper
                                            isLoading={isLoading}
                                            icon={
                                                typeof iconBefore === "function"
                                                    ? iconBefore(this.state)
                                                    : iconBefore
                                            }
                                        />
                                    )}
                                    {children && (
                                        <Content
                                            isLoading={isLoading}
                                            hasLeftIcon={hasLeftIcon}
                                            hasRightIcon={hasRightIcon}
                                        >
                                            {children}
                                        </Content>
                                    )}
                                    {iconAfter && (
                                        <IconWrapper
                                            isLoading={isLoading}
                                            icon={
                                                typeof iconAfter === "function"
                                                    ? iconAfter(this.state)
                                                    : iconAfter
                                            }
                                        />
                                    )}
                                </LinkOrButton>
                            );
                        }}
                    </ClassNames>
                )}
            </LodestarCoreTheme.Consumer>
        );
    }

    private getWithLogging() {
        return new LoggingDecorator(this.context, {
            clientId: this.props.clientId,
            controlType: "btn",
        });
    }
}
