import { css, Global } from "@emotion/core";
import React from "react";

export const MotionResetStyles: React.FC = () => {
    const styles = css`
        @media (prefers-reduced-motion: reduce) {
            *,
            *::before,
            *::after {
                animation-duration: 0.01ms !important;
                animation-iteration-count: 1 !important;
                transition-duration: 0.01ms !important;
                scroll-behavior: auto !important;
            }
        }
    `;

    return <Global styles={styles} />;
};
