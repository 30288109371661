/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { FC, HTMLAttributes } from "react";
import {
    StyledModalHeader,
    StyledModalTitle,
    StyledTitleIcon,
    StyledTitleText,
} from "../styled/Header";
import { ModalType } from "../types";
import { XButton, XButtonProps } from "./XButton";

export interface ModalHeaderProps {
    /**
     * the aria-label attribute associated with the modal's close button. Defaults to "Close" if not explicitly set.
     */
    "aria-label"?: string;
    /**
     * the aria-label attribute associated with the button.
     *
     * @deprecated Please use aria-label instead. The image is aria-hidden.
     */
    ariaLabel?: string;
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
    /**
     * Makes the title wrap if necessary
     * If false and the title is longer than one line, the title will elide
     */
    isHeadingMultiline?: boolean;
    /**
     * Function that will be called to initiate the exit transition.
     * If not provided, no `x` button will be rendered.
     */
    onCloseRequested?: () => void;
    /**
     * Content to render as the modal title
     */
    title?: React.ReactNode;
    /**
     * An optional icon. Renders to the left of the title
     */
    titleIcon?: React.ReactNode;
    /**
     * Props which should be passed along to the title span
     */
    titleProps?: HTMLAttributes<HTMLSpanElement>;
    /**
     * Type of modal header, helps determine styles and layout. Defaults to "default".
     */
    type?: ModalType;
    /**
     * Pass through props for the button
     */
    xButtonProps?: Partial<XButtonProps>;
}

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
    const {
        isHeadingMultiline = true,
        title,
        titleIcon,
        titleProps,
        onCloseRequested,
        className,
        "aria-label": ariaLabel = "Close",
        type = "default",
        xButtonProps,
    } = props;

    /**
     * This will be removed in a future release with this ticket:
     * https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/466
     */
    let realAriaLabel = ariaLabel;
    if (props.ariaLabel && ariaLabel === "Close") {
        realAriaLabel = props.ariaLabel;
    }

    if (process.env.NODE_ENV !== "production") {
        if (props.ariaLabel) {
            console.warn(
                "The 'ariaLabel' prop has been deprecated in favor of the standard 'aria-label'. Please use that instead."
            );
        }
    }

    return (
        <StyledModalHeader className={className} type={type}>
            <StyledModalTitle
                variant={type === "simple" ? "headerLarge" : "headlineMedium"}
                renderAs="div"
            >
                {titleIcon && <StyledTitleIcon>{titleIcon}</StyledTitleIcon>}
                <StyledTitleText
                    {...titleProps}
                    type={type}
                    isHeadingMultiline={isHeadingMultiline}
                >
                    {title}
                </StyledTitleText>
                {onCloseRequested && (
                    <XButton
                        onClick={onCloseRequested}
                        aria-label={realAriaLabel}
                        xButtonProps={xButtonProps}
                    />
                )}
            </StyledModalTitle>
        </StyledModalHeader>
    );
};
