/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgArrowRight: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M14.376 12.416l-4.599 3.066A.5.5 0 019 15.066V8.934a.5.5 0 01.777-.416l4.599 3.066a.5.5 0 010 .832z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
