/**
 * This file is generated from scripts/genIconComponents.js. Any
 * changes should be made in that file.
 */
import React, { forwardRef, Ref } from "react";
import { getSvgResourceForSize, getDefaultSize } from "../../utils";
import { Icon, LodestarIconProps } from "../../icon";
import { SvgEdit as EditMedium } from "./medium/Edit";
import { SvgEdit as EditXxSmall } from "./xxSmall/Edit";
const sizeMap = {
    medium: EditMedium,
    xxSmall: EditXxSmall,
};
const defaultSize = getDefaultSize(sizeMap);

export const EditIcon = forwardRef(
    (
        { size = defaultSize, ...rest }: LodestarIconProps,
        ref: Ref<HTMLElement>
    ) => (
        <Icon
            ref={ref}
            svgComponent={getSvgResourceForSize(sizeMap, size)}
            size={size}
            {...rest}
        />
    )
);
EditIcon.displayName = "EditIcon";
