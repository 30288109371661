import styled from "@emotion/styled";

export interface FlyoutContainerProps {
    // Unusual for a styled-component, but this component is being exposed
    // as a ComponentType to make the API easier.
    // It's also the root element of the Flyout so it needs to take a className
    // to align with the rest of the API.
    className?: string;
}

export const FlyoutContainer = styled.div<FlyoutContainerProps>`
    background-color: transparent;
`;
