import { ClassNames } from "@emotion/core";
import React, { forwardRef } from "react";
import { FOCUS_USING_BEFORE_CLASS_NAME } from "./focusRing";

/**
 * HOC that is useful for wrapping @emotion/styled components to provide them a className
 * and still allow other classNames to be applied.
 * This specifically is used to apply the classNames used in focus toggling. {@see FocusToggleStyles} for more info.
 * @param focusTypeClassName Defaults to {@see FOCUS_USING_BEFORE_CLASS_NAME}, but can be
 *                           set to {@see FOCUS_USING_ELEMENT_CLASS_NAME} as well.
 */
export const withFocusToggleClassName = <Props extends { className?: string }>(
    InnerComponent: React.ComponentType<Props>,
    focusTypeClassName = FOCUS_USING_BEFORE_CLASS_NAME
) => {
    const NewComponent = forwardRef<HTMLElement, Props>((props, ref) => {
        return (
            <ClassNames>
                {({ cx }) => (
                    <InnerComponent
                        {...props}
                        ref={ref}
                        className={cx(focusTypeClassName, props.className)}
                    >
                        {props.children}
                    </InnerComponent>
                )}
            </ClassNames>
        );
    });
    NewComponent.displayName = `${
        InnerComponent.displayName || InnerComponent.name || ""
    }WithFocusToggleClassName`;
    return NewComponent;
};
