import React, { useState, createContext, useMemo } from "react";
import { StackOrderManager } from "./StackOrderManager";
import { createDefaultStackOrderManager } from "./StackOrderManagerDefaultImpl";

export interface StackOrderContextValue {
    stackOrderManager: StackOrderManager;
}

/**
 * Used to keep track of the stack order of components subscribing to the context.
 *
 * This can be null because its consumers should be able to check whether or not there's a stack
 * around them. If there's no stack around them, floating elements shouldn't make assumptions
 * about stacking, in general.
 *
 * @see: useStackOrder
 */
export const StackOrderContext = createContext<StackOrderContextValue | null>(
    null
);

export interface StackOrderContextProviderProps {
    /**
     * The object used to interact with the stack itself.
     */
    stackOrderManager?: StackOrderManager;
}

/**
 * ComponentsContainers rendered within a `StackOrderProvider`, allows closing coordination
 * among Components that subscribe to the behavior. The `StackOrderContextProvider` if not passed anything
 * will use a default implementation to manage the stackOrder, which should be sufficient for most cases (the exception being
 * coordinating across react instances, which would require a state-service or similar).
 */
export const StackOrderContextProvider: React.FC<
    StackOrderContextProviderProps
> = ({ children, stackOrderManager: providedStackOrderManager }) => {
    const [stackManager] = useState(
        () => providedStackOrderManager || createDefaultStackOrderManager()
    );

    if (process.env.NODE_ENV !== "production") {
        if (
            providedStackOrderManager !== undefined &&
            stackManager !== providedStackOrderManager
        ) {
            throw new Error(
                "The stackManager has changed. The manager should never change."
            );
        }
    }

    const contextValue = useMemo(
        () => ({ stackOrderManager: stackManager }),
        [stackManager]
    );

    return (
        <StackOrderContext.Provider value={contextValue}>
            {children}
        </StackOrderContext.Provider>
    );
};
