/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgAlertErrorAlt: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm-.001 15l-.11.006a1 1 0 10.227.001L11.999 17zM12 6c-.552 0-1 .465-1 1.038v6.924l.01.14c.065.507.484.898.99.898.552 0 1-.465 1-1.038V7.038l-.01-.14A1.01 1.01 0 0012 6z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
