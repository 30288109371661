/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgAlertWarning: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M14.029 4.139l7.659 13.404c.89 1.558-.235 3.497-2.029 3.497H4.341c-1.795 0-2.92-1.939-2.029-3.497L9.971 4.139c.897-1.571 3.161-1.571 4.058 0zm-2.03 11.236l-.11.006a1 1 0 10.227.001l-.117-.007zM12 8.63a.75.75 0 00-.75.75v3.74l.007.102a.75.75 0 001.493-.102V9.38l-.007-.102A.75.75 0 0012 8.63z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
