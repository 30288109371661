import { usePrevious } from "@react-hookz/web";
import {
    LogActionId,
    useLogger,
    Logger,
} from "@smartsheet/logging-state-service";
import { useEffect, useMemo, useState, SyntheticEvent } from "react";
import { Omit } from "utility-types";

export interface LogContent {
    /**
     * The action the user took to trigger this log event e.g. click, mouseover, etc.
     */
    actionId: LogActionId;

    /**
     * The unique identifier for a single instance of a component. This should be
     * unique across an app.
     */
    clientId: string;

    /**
     * The category of control e.g. Button (btn), Checkbox (cbx), etc.
     */
    controlType: string;
}

export type LoggedByDefault = { loggedByDefault: true; needsActionId: false };
export type NotLoggedByDefault = {
    loggedByDefault: false;
    needsActionId: false;
};
export type NeedsActionID = { loggedByDefault: false; needsActionId: true };
type DefaultLogBehavior = LoggedByDefault | NotLoggedByDefault | NeedsActionID;

type IsLoggedByDefault<T extends DefaultLogBehavior> = T extends LoggedByDefault
    ? true
    : T extends NotLoggedByDefault
    ? false
    : never;

export class LoggableEvent<T extends DefaultLogBehavior> {
    private _isLoggedByDefault: IsLoggedByDefault<T>;

    private _shouldLog: boolean;

    public constructor(
        logByDefault: IsLoggedByDefault<T>,
        public content: LogContent
    ) {
        this._shouldLog = logByDefault;
        this._isLoggedByDefault = logByDefault;
    }

    public isLoggedByDefault() {
        return this._isLoggedByDefault;
    }

    public preventLogging() {
        this._shouldLog = false;
    }

    public enableLogging() {
        this._shouldLog = true;
    }

    public shouldLog(): boolean {
        return this._shouldLog;
    }
}

const isSyntheticEvent = (arg: unknown): arg is SyntheticEvent<HTMLElement> => {
    return (
        arg !== null &&
        typeof arg === "object" &&
        "nativeEvent" in (arg as { [key: string]: unknown }) &&
        "persist" in (arg as { [key: string]: unknown })
    );
};

type VoidPromiseOrVoid = void | Promise<void>;

export class LoggingDecorator<DefaultLogContent extends keyof LogContent> {
    constructor(
        private readonly logger?: Logger,
        private readonly defaultContent?: Pick<
            Partial<LogContent>,
            DefaultLogContent
        >
    ) {}

    public enabledByDefault = <Args extends unknown[]>(
        content: Partial<LogContent> & Omit<LogContent, DefaultLogContent>,
        fn: (
            ...args: [...args: Args, log: LoggableEvent<LoggedByDefault>]
        ) => VoidPromiseOrVoid
    ) =>
        this.partialCallWithLogging(
            fn,
            new LoggableEvent(true, {
                ...this.defaultContent,
                ...content,
            } as LogContent)
        );

    public disabledByDefault = <Args extends unknown[]>(
        content: Partial<LogContent> & Omit<LogContent, DefaultLogContent>,
        fn: (
            ...args: [
                ...args: Args,
                log: LoggableEvent<NotLoggedByDefault | NeedsActionID>
            ]
        ) => VoidPromiseOrVoid
    ) =>
        this.partialCallWithLogging(
            fn,
            new LoggableEvent(false, {
                ...this.defaultContent,
                ...content,
            } as LogContent)
        );

    private partialCallWithLogging<
        Args extends unknown[],
        Def extends DefaultLogBehavior
    >(
        fn: (
            ...args: [...args: Args, log: LoggableEvent<Def>]
        ) => VoidPromiseOrVoid,
        log: LoggableEvent<Def>
    ): (...args: Args) => Promise<void> {
        return async (...args: Args) => {
            // In the majority case, an event is passed somewhere here.
            // Because this is all async, we should persist those events so they don't
            // get recycled back to the SyntheticEvent pool.
            args.forEach((arg) => {
                if (isSyntheticEvent(arg)) {
                    arg.persist();
                }
            });
            await fn(...args, log);

            if (log.shouldLog()) {
                const { clientId, controlType, actionId } = log.content;

                if (clientId) {
                    this.logger?.logClientEvent(
                        controlType,
                        clientId,
                        actionId
                    );
                }
            }
        };
    }
}

// Adapted from http://adripofjavascript.com/blog/drips/object-equality-in-javascript.html
function objectsShallowEqual(
    a: Record<string, unknown>,
    b: Record<string, unknown>
) {
    // Short-circuit in the case the object is the same.
    if (a === b) {
        return true;
    }
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

/**
 * Hook access to a LoggingDecorator.
 * Some effort is expended here to ensure we only return a new LoggingDecorator if the
 * `defaultContent` actually changes its contents to prevent re-renders.
 * @param defaultContent
 */
export const useLoggingDecorator = <K extends keyof LogContent>(
    defaultContent: Pick<Partial<LogContent>, K>
) => {
    const logger = useLogger();
    const [loggingDecorator, setLoggingDecorator] = useState(
        new LoggingDecorator(logger, defaultContent)
    );
    const previousDefaultContent = usePrevious(defaultContent);
    useEffect(() => {
        if (
            previousDefaultContent &&
            !objectsShallowEqual(previousDefaultContent, defaultContent)
        ) {
            setLoggingDecorator(new LoggingDecorator(logger, defaultContent));
        }
    }, [defaultContent, logger, previousDefaultContent]);
    return loggingDecorator;
};

export function useLoggingEnabledByDefault<
    K extends keyof LogContent,
    Args extends unknown[]
>(
    withLogging: LoggingDecorator<K>,
    newContent: Partial<LogContent> & Omit<LogContent, K>,
    handler?: (
        ...args: [...args: Args, log: LoggableEvent<LoggedByDefault>]
    ) => VoidPromiseOrVoid
): (...args: Args) => VoidPromiseOrVoid {
    const [content, setContent] = useState(newContent);
    const previousContent = usePrevious(content);

    useEffect(() => {
        if (
            previousContent &&
            !objectsShallowEqual(previousContent, newContent)
        ) {
            setContent(newContent);
        }
    }, [previousContent, newContent]);
    return useMemo(
        () =>
            handler
                ? withLogging.enabledByDefault(content, handler)
                : () => undefined,
        [withLogging, content, handler]
    );
}

export function useLoggingDisabledByDefault<
    K extends keyof LogContent,
    Args extends unknown[]
>(
    withLogging: LoggingDecorator<K>,
    newContent: Partial<LogContent> & Omit<LogContent, K>,
    handler?: (
        ...args: [...args: Args, log: LoggableEvent<NotLoggedByDefault>]
    ) => VoidPromiseOrVoid
): (...args: Args) => VoidPromiseOrVoid {
    const [content, setContent] = useState(newContent);
    const previousContent = usePrevious(content);

    useEffect(() => {
        if (
            previousContent &&
            !objectsShallowEqual(previousContent, newContent)
        ) {
            setContent(newContent);
        }
    }, [previousContent, newContent]);
    return useMemo(
        () =>
            handler !== undefined
                ? withLogging.disabledByDefault(content, handler)
                : () => undefined,
        [withLogging, content, handler]
    );
}
