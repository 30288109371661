import styled from "@emotion/styled";
import { tag as typography } from "../../../constants/typography";

export const TokenLabel = styled.span`
    align-items: center;
    font-size: ${typography.size}px;
    font-weight: 500;
    line-height: 28px;
    padding: 0 6px 0 4px;

    // Ellide if the token is wider than its container.
    display: inline;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
