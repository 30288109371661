/**
 * This is a function to unwrap the current theming implementation to hide it from the public API
 * until we're ready to expose it in a unified manner.
 */

import { createContext } from "react";

export type ValueOrValueGetter = string | (() => string);

/**
 * The named themes that lodestar-core supports.
 *
 * We've added a dark theme here that DOES NOTHING YET!
 *
 * You're not allowed to add the 'dark' key to a ThemeSwitch in lodestar-core,
 * and any usages in non-ts code will fall back to 'light' for that value.
 * Since most of this code isn't exposed, we don't really have to worry about
 * that case.
 *
 * For more info, please see:
 * https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/396
 *
 */
export type ThemeMode = "light" | "oldestar" | "dark";

export const LodestarCoreTheme = createContext<ThemeMode>("light");
LodestarCoreTheme.displayName = "LodestarCoreTheme";

// Theme values can be strings e.g. font color or numbers e.g. font weight.
export type ThemeValueTypes = string | number;

// This is a leaf node in a theme object.
// It is the switch that should contain the different values to use for a given theme.
export type ThemeSwitch<R extends ThemeValueTypes = ThemeValueTypes> = {
    [key in Exclude<ThemeMode, "dark">]: R;
};

// Used to validate a ThemeSwitch at runtime. We just need to ensure we create a mapping of all the
// possible theme names to extract into an array that will break if we add another theme.
const basicThemeSwitchForAllThemesInference: ThemeSwitch<string> = {
    light: "not",
    oldestar: "used",
} as const;
export const allThemes = Object.keys(basicThemeSwitchForAllThemesInference);
const isThemeSwitch = (t: unknown): t is ThemeSwitch<string | number> => {
    return (
        !!t &&
        typeof t === "object" &&
        allThemes.every((themeName) =>
            Object.prototype.hasOwnProperty.call(t, themeName)
        )
    );
};

// Each property in a theme object can be mapped to a switch, value, or another theme object.
export type ThemeNode = {
    [key: string]: ThemeSwitch | ThemeValueTypes | DeepThemeObject;
};

// This is the type of the root of a theme object. It's either a deep theme object or a switch.
// Having just a value passed to themed would be silly, and makes the typings more complicated.
export type DeepThemeObject = ThemeSwitch | ThemeNode;

// Ok here's the big ol' mapped type. This is the thing that will map the type of the theme object
// you pass to themed to the thing that will be returned. It makes sure that the shape is mostly the same,
// but adds in ThemeSwitch types at each leaf of the returned theme tree.
export type ThemeResolver<R extends ThemeValueTypes> = (mode: ThemeMode) => R;
export type DeepThemeResolver<T extends DeepThemeObject> =
    T extends ThemeSwitch<infer SwitchType>
        ? ThemeResolver<SwitchType>
        : {
              [P in keyof T]: T[P] extends string
                  ? ThemeResolver<string>
                  : T[P] extends number
                  ? ThemeResolver<number>
                  : T[P] extends ThemeSwitch<infer SwitchType>
                  ? ThemeResolver<SwitchType>
                  : T[P] extends {
                        [key: string]:
                            | ThemeSwitch
                            | ThemeValueTypes
                            | DeepThemeObject;
                    }
                  ? DeepThemeResolver<T[P]>
                  : never;
          };

/**
 * Used to create a theme from an object arbitrary shape which will be mapped
 * into an object of similar shape with the leaf nodes replaced.
 *
 * Ex:
 * ```
 * const theme = themed({
 *   keyA: {
 *     light: 'lightValue',
 *     oldestar: 'oldestarValue',
 *   }
 * });
 * ```
 *
 * `theme` will now be of the shape:
 * ```
 * {
 *   keyA: (themeName: keyof ThemeMode) => string; // Where that string is "lightValue" | "oldestarValue"
 * }
 * ```
 *
 * And you would get a themed value by passing in the theme to the right key function:
 *
 * ```
 * const keyAValue = theme.keyA('light'); // => returns 'lightValue`
 * ```

 * @param obj A DeepThemeObject that will be converted to a ThemeResolver.
 */
export const themed = <T extends DeepThemeObject>(
    obj: T
): DeepThemeResolver<T> => {
    function helper(inner: DeepThemeObject | ThemeValueTypes) {
        if (typeof inner === "string" || typeof inner === "number") {
            return () => inner;
        } else if (isThemeSwitch(inner)) {
            return (mode: ThemeMode) =>
                // See: https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/396
                mode === "dark" ? inner["light"] : inner[mode];
        } else {
            // Oh jeez. These lint disabled. I know. Don't worry it's SUPER well tested!
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
            const newObj = {} as any;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/ban-types
            Object.keys(inner).forEach((key) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                newObj[key] = helper(inner[key]);
            });
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return newObj;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return helper(obj);
};
