/**
 * This file is generated from scripts/genIconComponents.js. Any
 * changes should be made in that file.
 */
import React, { forwardRef, Ref } from "react";
import { getSvgResourceForSize, getDefaultSize } from "../../utils";
import { Icon, LodestarIconProps } from "../../icon";
import { SvgInfo as InfoMedium } from "./medium/Info";
import { SvgInfo as InfoXxSmall } from "./xxSmall/Info";
const sizeMap = {
    medium: InfoMedium,
    xxSmall: InfoXxSmall,
};
const defaultSize = getDefaultSize(sizeMap);

export const InfoIcon = forwardRef(
    (
        { size = defaultSize, ...rest }: LodestarIconProps,
        ref: Ref<HTMLElement>
    ) => (
        <Icon
            ref={ref}
            svgComponent={getSvgResourceForSize(sizeMap, size)}
            size={size}
            {...rest}
        />
    )
);
InfoIcon.displayName = "InfoIcon";
