/**
 * The Flyout shows a floating content area on screen, positioning it next to
 * the given target element. It detects screen edges and will re-position itself
 * to another side of the target element if it gets too close to an edge.
 *
 * It exposes props that control its visibility and is leveraged by components
 * such as Tooltip and FlyoutButton.
 */

import { StyledComponent } from "@emotion/styled";
import * as PopperJS from "@popperjs/core";
import React, {
    RefObject,
    useState,
    ForwardRefExoticComponent,
    forwardRef,
    useEffect,
    ComponentType,
    useCallback,
    ReactNode,
    RefCallback,
} from "react";
import { usePopper } from "react-popper";
import { Omit } from "utility-types";
import { useStackOrder } from "../../util/StackOrder";
import {
    LayerContextProvider,
    useLayerZIndex,
} from "../../util/layerContext/LayerContext";
import { useForkRef } from "../../util/useForkRef";
import { Portal as DefaultPortal, PortalProps } from "../portal";
import {
    FlyoutArrow as DefaultFlyoutArrow,
    FlyoutArrowProps,
} from "./styled/FlyoutArrow";
import {
    FlyoutContainer as DefaultFlyoutContainer,
    FlyoutContainerProps,
} from "./styled/FlyoutContainer";
import { FlyoutPlacement } from "./types";

/**
 * This is the only piece that Popper needs to position itself.
 * To support virtual elements, we'll only require this interface for
 * the target element.
 */
export type LikeHTMLElement = Pick<HTMLElement, "getBoundingClientRect">;

function thingIsRefObject(thing: unknown): thing is RefObject<HTMLElement> {
    return Object.prototype.hasOwnProperty.call(thing, "current");
}

function thingIsLikeHTMLElementEnough(
    thing: unknown
): thing is LikeHTMLElement {
    return "getBoundingClientRect" in (thing as Record<string, unknown>);
}

function elementOrNull(
    elementRefOrNull: FlyoutProps["targetElement"]
): LikeHTMLElement | null {
    if (!elementRefOrNull) {
        return null;
    } else if (thingIsRefObject(elementRefOrNull)) {
        return elementRefOrNull.current;
    } else if (thingIsLikeHTMLElementEnough(elementRefOrNull)) {
        // This is kind of a weird check, but we want to allow fake elements to be passed in here
        // that just satisfy popper's requirements on a bounding rect.
        // This is for test and to allow a flyout to be free e.g. follow the mouse or some other feature.
        // https://popper.js.org/docs/v2/virtual-elements/
        return elementRefOrNull;
    }
    return null;
}

interface ArrowProps extends FlyoutArrowProps {
    // Not great, but Popper doesn't give us much flexibility here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

interface FlyoutChildRenderProps {
    getArrowProps: () => ArrowProps;
}

type FlyoutChildRenderFunction = (
    childProps: FlyoutChildRenderProps
) => JSX.Element;

export interface FlyoutProps {
    /**
     * The id of the flyout. This should be set as the "aria-owns" attribute on the
     * target element that opens/closes the flyout.
     */
    id: string;

    /**
     * Whether this flyout is visible or not.
     */
    isOpen: boolean;

    /**
     * Function that will be called when the flyout wants to close.
     */
    onCloseRequested: () => void;

    /**
     * The element to pass on to Popper to calculate positioning.
     * This is the element the Flyout will show up next to.
     *
     * This can be anything of the signature:
     * `type Element = {
     *      getBoundingClientRect: () => ClientRect | DOMRect,
     *  };`
     *
     * This is the only piece that Popper needs to position itself.
     * To support virtual elements, we'll only require this interface for
     * the target element.
     */
    targetElement: LikeHTMLElement | RefObject<LikeHTMLElement | null> | null;

    /**
     * Since this is a dialog, we need to provide the user with a description of it.
     * Use either this, aria-label, or aria-labelledby.
     */
    "aria-describedby"?: string;

    /**
     * Since this is a dialog, we need to provide the user with a description of it.
     * Use either this, aria-labelledby, or aria-describedby.
     */
    "aria-label"?: string;

    /**
     * Since this is a dialog, we need to provide the user with a description of it.
     * Use either this, aria-label, or aria-describedby
     */
    "aria-labelledby"?: string;

    /**
     * The arrow is created using borders so it will apply this color as border-color.
     *
     * NOTE: This is mostly for convenience:
     * If you are also overriding the `components.FlyoutArrow` prop, ensure you use this prop
     * in that custom component.
     */
    arrowColor?: string;

    /**
     * The contents of the Flyout.
     *
     * Optionally, if you want more power over the Flyout, Arrow, etc, you can pass a
     * function in here and manually re-attach some state to the default Arrow component or
     * a custom one.
     *
     * One use-case this was introduced for was animations. Popper is VERY particular around
     * animating its container, so allowing the container to be placed properly and then
     * animating its children is much more reliable.
     *
     */
    children?: ReactNode | FlyoutChildRenderFunction;

    /**
     * A classname to be applied to the root element of the Flyout.
     */
    className?: string;

    /**
     * A `clientId` prop is provided for specified elements, which is a unique string that
     * appears as a data attribute `data-client-id` in the rendered code, serving as a hook
     * for automated tests
     */
    clientId?: string;

    /**
     * Allows flyout to be closed by the escape key. Defaults to true.
     */
    closeOnEscape?: boolean;

    /**
     * Specify a set of components to use instead of the defaults.
     *
     * - FlyoutArrow: the component that points to the target element. Receives popper arrow attributes and styles
     * - FlyoutContainer: the root component which receives the popper container attributes and styles.
     * - Portal: the component to use as the Portal around everything.
     */
    components?: {
        FlyoutArrow?:
            | ForwardRefExoticComponent<FlyoutArrowProps>
            // eslint-disable-next-line @typescript-eslint/ban-types
            | StyledComponent<FlyoutArrowProps, object, object>;
        FlyoutContainer?:
            | ForwardRefExoticComponent<FlyoutContainerProps>
            // eslint-disable-next-line @typescript-eslint/ban-types
            | StyledComponent<FlyoutContainerProps, object, object>;
        Portal?: ComponentType<PortalProps>;
    };

    /**
     * Whether or not to show the arrow pointing at the targetElement.
     */
    hasArrow?: boolean;

    /**
     * A number (in px) to offset the location of the flyout from it's target element.
     *
     * Note: This is relative to the target, not the default location of the Flyout i.e.
     * there's a default distance of 8px so if you want another 4px on top of the default,
     * please specify 12.
     *
     * These can be negative or positive and are relative to the `placement` prop.
     * - `distance` is the distance away from the target element.
     * - `skidding` displaces the popper along the reference element.
     *
     * For example: if `placement` is "right":
     * - a positive `distance` moves the Flyout right.
     * - a negative `distance` moves the Flyout left.
     * - a positive `skidding` moves the Flyout down.
     * - a positive `skidding` moves the Flyout up.
     *
     * "Skidding" is the term used by the Popper.js library we use
     * under the hood here.
     *
     * Note: if offset is passed in `popperProps.modifiers`, that offset will be used and the
     * property here will have no effect.
     */
    offset?: {
        distance?: number;
        skidding?: number;
    };

    /**
     * Where to place the flyout.
     */
    placement?: FlyoutPlacement;

    /**
     * Options passed along to the Popper instance used internally.
     * For the most part this will be unused. Some Popper props (like placement) are
     * unavailable in this object because we have hoisted them to the Flyout's
     * prop interface instead.
     */
    popperProps?: Omit<Partial<PopperJS.Options>, "placement">;

    /**
     * Props to be passed to the underlying Portal component.
     */
    portalProps?: Omit<Partial<PortalProps>, "children">;

    /**
     * The aria role to apply to the FlyoutContainer.
     * If null is provided, a role will not be applied.
     */
    role?: string | null;

    /**
     * Trigger repositioning of Flyout by updating the prop with a new string or number value. The actual
     * content of the value is arbitrary as it is only used as a trigger for Popper's `update` method.
     * Examples of values which could be passed in include: an auto-incremented index, an array length, a timestamp,
     * TextInput value, or a css style string - really any stateful value that the Flyout's position
     * may be dependent upon.
     */
    updateKey?: string | number | null;
}

export const Flyout = forwardRef<HTMLDivElement, FlyoutProps>(
    (
        {
            arrowColor = "transparent",
            children,
            clientId,
            components = {},
            hasArrow = false,
            isOpen,
            offset,
            onCloseRequested,
            placement = "auto",
            popperProps,
            portalProps,
            targetElement,
            role = "dialog",
            updateKey,
            closeOnEscape = true,
            ...rest
        },
        providedRef
    ) => {
        const layerZIndex = useLayerZIndex();

        // Using state vs RefObject since Popper needs to re-render once its
        // refs are available.
        const [containerElement, setContainerElement] =
            useState<HTMLDivElement | null>(null);

        useStackOrder({
            isOpen,
            onCloseRequested,
            shouldCloseOnEscape: closeOnEscape,
        });

        // Sync the content element back to the provided ref (if there is one).
        const setElementRef = useCallback<RefCallback<HTMLDivElement>>(
            (newContainerElement) => {
                setContainerElement(newContainerElement);
            },
            []
        );
        const containerElementRef = useForkRef(providedRef, setElementRef);

        // One more "ref" for the arrow element. Again, popper needs to re-render
        // if this element changes.
        const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(
            null
        );

        const target: LikeHTMLElement | null = elementOrNull(targetElement);

        const { styles, attributes, state, update } = usePopper(
            target,
            containerElement,
            {
                ...popperProps,
                placement,
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [
                                offset?.skidding || 0,
                                offset?.distance || 0,
                            ],
                        },
                    },
                    ...(popperProps?.modifiers || []),
                    { name: "arrow", options: { element: arrowElement } },
                ],
            }
        );

        // Because the target and container elements might not be instantiated immediately,
        // we need to fire off an update post-render once they are.
        // This solves issues where the flyout and arrow aren't properly positioned the first render.
        // This *should* only happen once as `update` is initially null but is defined once after the initial render.
        useEffect(() => {
            if (update) {
                void update();
            }
        }, [update, updateKey]);

        const FlyoutArrow = components.FlyoutArrow || DefaultFlyoutArrow;
        if (!FlyoutArrow.displayName) {
            FlyoutArrow.displayName = DefaultFlyoutArrow.displayName;
        }

        const FlyoutContainer =
            components.FlyoutContainer || DefaultFlyoutContainer;
        if (!FlyoutContainer.displayName) {
            FlyoutContainer.displayName = DefaultFlyoutContainer.displayName;
        }

        const Portal = (components && components.Portal) || DefaultPortal;

        const getArrowProps = () => ({
            backgroundColor: arrowColor,
            placement: state?.placement || "auto",
            ref: setArrowElement,
            style: styles.arrow,
            // This attribute is only for test.
            ["data-flyout-arrow"]: "true",
            ...attributes.arrow,
        });

        const isChildrenFunction = typeof children === "function";

        return (
            (isOpen && (
                <Portal
                    // Ensure we allow overriding this z-index manually.
                    // We'd love to have Flyout use layers["flyout"] by default, but that would be
                    // a breaking change since everyone has already manually specified z-indexes
                    // everywhere from 3 to 490. We'll have to do this in a major version bump.
                    // See https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/501
                    zIndex={layerZIndex}
                    {...portalProps}
                >
                    <LayerContextProvider defaultLayer="flyout">
                        <FlyoutContainer
                            style={styles.popper}
                            ref={containerElementRef}
                            data-client-id={clientId || undefined}
                            role={role || undefined}
                            {...attributes.popper}
                            {...rest}
                        >
                            {typeof children === "function"
                                ? children({
                                      getArrowProps,
                                  })
                                : children}
                            {!isChildrenFunction && hasArrow && (
                                <FlyoutArrow {...getArrowProps()} />
                            )}
                        </FlyoutContainer>
                    </LayerContextProvider>
                </Portal>
            )) ||
            null
        );
    }
);

Flyout.displayName = "Flyout";
