import styled from "@emotion/styled";

export const RadioInputWrapper = styled.div`
    display: flex;
    flex-shrink: 0;
    height: 20px;
    width: 20px;

    /* This is relatively positioned so the radio icon can be absolutely positioned. */
    position: relative;
`;

export const HiddenInput = styled.input`
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 0;
    margin: 0;
    transform: translate(-50%, -50%);
    opacity: 0;
    cursor: inherit;
`;
