import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import {
    buttonPrimary,
    focus,
    neutral,
    neutralAccessible,
    buttonDestructive,
    toRgba,
} from "../../../constants/colors";
import { getFocusRingStyles } from "../../../util/focusRing";
import { themed, ThemeMode } from "../../../util/theme";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";
import {
    getHeight,
    getWidth,
    ToggleSize,
    transition,
} from "./toggleStyleConstants";

function getFocusRing({ themeMode, size }: SlideStyleProps) {
    switch (size) {
        case "medium":
            return getFocusRingStyles({
                theme: themeMode,
                offset: 4,
                radius: 20,
            });
        case "large":
        default:
            return getFocusRingStyles({
                theme: themeMode,
                offset: 4,
                radius: 28,
            });
    }
}

const colorOptions = themed({
    bgChecked: {
        light: buttonPrimary,
        oldestar: "#0073ec",
    },
    bgCheckedHover: {
        light: focus,
        oldestar: "#005cbc",
    },
    bgCheckedPressed: {
        light: neutral,
        oldestar: "#005cbc",
    },
    bgCheckedDisabled: {
        light: toRgba(buttonPrimary, 0.4),
        oldestar: toRgba("#0073ec", 0.3),
    },
    bgInvalid: buttonDestructive,
    bgUnchecked: neutralAccessible,
    bgUncheckedHover: {
        light: neutral,
        oldestar: "#005cbc",
    },
    bgUncheckedPressed: {
        light: focus,
        oldestar: "#005cbc",
    },
    bgUncheckedDisabled: toRgba(neutralAccessible, 0.4),
});

const getBgColor = ({
    isActive,
    isChecked,
    isDisabled,
    isInvalid,
    themeMode,
}: SlideStyleProps) => {
    let color = "";
    if (!isActive) {
        if (isChecked) {
            color = colorOptions.bgChecked(themeMode);
        } else {
            color = colorOptions.bgUnchecked(themeMode);
        }
    } else {
        if (isChecked) {
            color = colorOptions.bgCheckedPressed(themeMode);
        } else {
            color = colorOptions.bgUncheckedPressed(themeMode);
        }
    }

    if (isDisabled) {
        if (isChecked) {
            color = colorOptions.bgCheckedDisabled(themeMode);
        } else {
            color = colorOptions.bgUncheckedDisabled(themeMode);
        }
    }
    if (isInvalid) {
        color = colorOptions.bgInvalid(themeMode);
    }

    return color;
};

const getHoverStyles = ({
    isActive,
    isChecked,
    isDisabled,
    isInvalid,
    themeMode,
}: SlideStyleProps) => {
    let bgcolor: string | undefined;
    if (!isDisabled && !isActive) {
        // Disabled and currently active toggles have no hover change.
        bgcolor = isInvalid
            ? colorOptions.bgInvalid(themeMode)
            : isChecked
            ? colorOptions.bgCheckedHover(themeMode)
            : colorOptions.bgUncheckedHover(themeMode);
    }

    return css`
        &:hover {
            ${bgcolor ? `background-color: ${bgcolor};` : ""};
            cursor: ${isDisabled ? "not-allowed" : "pointer"};
        }
    `;
};

export interface SlideStyleProps extends HTMLAttributes<HTMLDivElement> {
    isActive: boolean;
    isChecked: boolean;
    isDisabled: boolean;
    isFocused: boolean;
    size: ToggleSize;
    themeMode: ThemeMode;
    isInvalid?: boolean;
}

export const Slide = withFocusToggleClassName(styled.div<SlideStyleProps>`
    background-color: ${getBgColor};
    border-radius: ${({ size }) => getHeight(size)}px;
    height: ${({ size }) => getHeight(size)}px;
    width: ${({ size }) => getWidth(size)}px;
    box-sizing: border-box;
    padding: 0;
    cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: center;

    transition: ${transition};

    // Needed for the outline component.
    position: relative;

    ${getHoverStyles};

    ${({ isFocused }) => isFocused && getFocusRing}
`);
Slide.displayName = "Slide";
