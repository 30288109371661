/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgKebab: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z" />
            <path
                d="M7 9.75a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm0-4a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5zm0-4a1.25 1.25 0 110 2.5 1.25 1.25 0 010-2.5z"
                fill="currentColor"
                fillRule="nonzero"
            />
        </g>
    </svg>
);
