/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ReactChild } from "react";
import { sizes } from "../../../constants/spacing";
import { getLoadingStyle } from "../getStyles";

interface Props {
    icon: ReactChild;
    isLoading?: boolean;
}

export const IconWrapper = ({ icon, isLoading, ...rest }: Props) => (
    <div
        css={{
            alignSelf: "center",
            display: "flex",
            flexShrink: 0,
            lineHeight: 0,
            fontSize: 0,
            userSelect: "none",
            margin: `0 ${sizes.xSmall - 1}px`, // -1px for border
            ...getLoadingStyle(isLoading),
        }}
        {...rest}
    >
        {icon}
    </div>
);
