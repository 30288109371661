import styled from "@emotion/styled";
import { Placement } from "@popperjs/core";

function startsWith(str: string, prefix: string): boolean {
    return str.startsWith(prefix);
}

export interface FlyoutArrowProps {
    backgroundColor: string;
    placement: Placement;
    size?: number;
}

export const defaultArrowSize = 4;

export const FlyoutArrow = styled.div<FlyoutArrowProps>(
    {
        width: 0,
        height: 0,
        borderStyle: "solid",
    },
    ({ size = defaultArrowSize }) => ({
        borderWidth: `${size}px`,
    }),
    // Choose the right border color based on positioning.
    ({ backgroundColor, placement }) => ({
        borderRightColor:
            (startsWith(placement, "right") && backgroundColor) ||
            "transparent",
        borderLeftColor:
            (startsWith(placement, "left") && backgroundColor) || "transparent",
        borderTopColor:
            (startsWith(placement, "top") && backgroundColor) || "transparent",
        borderBottomColor:
            (startsWith(placement, "bottom") && backgroundColor) ||
            "transparent",
    }),
    // Offset the arrow a bit to account for its size.
    ({ placement, size = defaultArrowSize }) => ({
        bottom: startsWith(placement, "top") ? `-${size * 2}px` : "initial",
        top: startsWith(placement, "bottom") ? `-${size * 2}px` : "initial",
        right: startsWith(placement, "left") ? `-${size * 2}px` : "initial",
        left: startsWith(placement, "right") ? `-${size * 2}px` : "initial",
    })
);
