/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgUpgrade: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.5a8.5 8.5 0 10.001 17.001A8.5 8.5 0 0012 3.5zm.513 3.965c.923.903 1.845 1.806 2.764 2.712.397.392.24 1.03-.284 1.175-.282.079-.525.003-.73-.197l-1.41-1.381c-.036-.035-.073-.068-.12-.11-.028.066-.018.12-.018.173v6.629a.823.823 0 01-.077.391.715.715 0 01-.796.376.716.716 0 01-.553-.65c-.003-.055-.002-.11-.002-.165V9.84c0-.053.014-.108-.015-.16-.058.011-.085.058-.12.092-.474.463-.944.93-1.42 1.39-.411.396-1.065.232-1.207-.302-.071-.271.012-.503.215-.7.66-.643 1.317-1.29 1.975-1.934.258-.253.514-.508.773-.76a.715.715 0 011.025 0z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
