import styled from "@emotion/styled";
import { sizes } from "../../../constants/spacing";

export const StyledModalFooter = styled.footer`
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    padding: ${sizes.xLarge}px;
`;

export const StyledFooterButtons = styled.div`
    display: flex;
    margin-left: auto;
`;
