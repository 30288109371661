/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { components } from "react-select";
import { neutralDark40, toRgba } from "../../../constants/colors";

const disabledColor = toRgba(neutralDark40, 0.3);

export const Placeholder: typeof components.Placeholder = ({
    children,
    ...rest
}) => {
    return (
        <components.Placeholder
            {...rest}
            css={css`
                color: ${rest.selectProps.isDisabled
                    ? disabledColor
                    : neutralDark40};
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                margin: 0;
                padding: 0 8px;
                pointer-events: none;

                // IE11 Why do you stink worse than a musk ox's underwear!?!
                // https://stackoverflow.com/a/27022218
                // Why are you the 3rd-worst thing in web dev right after the memory of <marquee> and GWT?
                // A haiku for you:
                //
                // Soon, August next year
                // A curse upon us lifted
                // Hearts shan't weep for thee
                //
                top: auto;
                bottom: 50%;
                transform: translateY(50%);
            `}
        >
            {children}
        </components.Placeholder>
    );
};
