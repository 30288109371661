import styled, { StyledComponent } from "@emotion/styled";
import React, {
    AllHTMLAttributes,
    DetailedHTMLProps,
    forwardRef,
    HTMLAttributes,
    Ref,
} from "react";
import { ReactNode, useMemo } from "react";
import { sizeNames, sizes } from "../../constants/spacing";

interface SpacingProps {
    orientation: "row" | "column";
    sizeName: sizeNames;
    className?: string;
}

function shouldForwardProp(propName: keyof SpacingProps) {
    switch (propName) {
        case "orientation":
        case "sizeName":
            return false;
    }
    return true;
}

// Note: We're using double "&"" to increase specificity of the margin styles,
// which allows us to override any margins set on the Spacer's direct children
const VerticallySpacedDiv = styled("div", {
    shouldForwardProp,
})<SpacingProps>`
    display: flex;
    flex-direction: column;
    && > * + * {
        margin-top: ${(props) => sizes[props.sizeName]}px;
    }
`;

/**
 * Renders child elements horizontally, with given space between them.
 */
const HorizontallySpacedDiv = styled("div", {
    shouldForwardProp,
})<SpacingProps>`
    display: flex;
    flex-direction: row;
    && > * + * {
        margin-left: ${(props) => sizes[props.sizeName]}px;
    }
`;

// We need to leave this generic in here to avoid breaking changes, but it's not actually used.
// It wasn't truly constraining anything, and the union types it created was causing a lot of
// slowdown in the typecript build.
// Remove this in a major release.
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
export interface SpacerProps<EType extends keyof JSX.IntrinsicElements = "div">
    extends AllHTMLAttributes<HTMLElement> {
    /**
     * The list of children to render inline.
     */
    children: ReactNode;

    /**
     * Which way to align - column or row;
     */
    orientation: "row" | "column";

    /**
     * How much space should be included between each child.
     * Vertical space for column alignment and horizontal space for row alignment.
     */
    space: sizeNames;

    /**
     * A `clientId` which is a unique string that appears as a data attribute `data-client-id`
     * in the rendered code, serving as a hook for automated tests. Will be applied to the root
     * element of this component.
     */
    clientId?: string;

    /**
     * What element to render the wrapper as. Defaults to "div".
     */
    renderAs?: keyof JSX.IntrinsicElements;
}

export const Spacer = forwardRef(
    (
        {
            renderAs,
            children,
            clientId,
            orientation,
            space,
            ...rest
        }: SpacerProps,
        ref: Ref<HTMLElement>
    ): JSX.Element => {
        // Memoizing this to ensure we won't create a new component each time.
        // withComponent returns a new component.
        const SpacerRoot = useMemo(() => {
            const TheComponent =
                orientation === "column"
                    ? VerticallySpacedDiv
                    : HorizontallySpacedDiv;
            return renderAs
                ? TheComponent.withComponent(renderAs)
                : TheComponent || "div";
        }, [renderAs, orientation]) as StyledComponent<
            DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
            SpacingProps,
            Record<string, unknown>
        >;

        return (
            <SpacerRoot
                ref={ref}
                sizeName={space}
                orientation={orientation}
                data-client-id={clientId}
                {...rest}
            >
                {children}
            </SpacerRoot>
        );
    }
);

Spacer.displayName = "Spacer";
