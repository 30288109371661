import { createContext } from "react";

export const SelectAllContext = createContext<{
    enabled: boolean;
    getText: (selectedCount: number, totalCount: number) => string;
}>({
    // Default to false so this is not enabled in single select.
    enabled: false,
    getText: () => "",
});
