import { Global } from "@emotion/core";
import React, { useEffect } from "react";
import {
    FOCUS_USING_BEFORE_CLASS_NAME,
    FOCUS_USING_ELEMENT_CLASS_NAME,
} from "./focusRing";
import { SHOW_FOCUS_MARKER, useFocusMode } from "./useFocusMode";

export const FocusToggleStyles = () => {
    // Turn on/off the focus marker.
    const focusMode = useFocusMode();

    useEffect(() => {
        switch (focusMode) {
            case "keyboard":
                document.body.classList.add(SHOW_FOCUS_MARKER);

                break;
            case "mouse":
                document.body.classList.remove(SHOW_FOCUS_MARKER);
                break;
        }
        return () => {
            document.body.classList.remove(SHOW_FOCUS_MARKER);
        };
    }, [focusMode]);

    return (
        <Global
            styles={{
                // This style will hide all before elements with the FOCUS_ELEMENT_CLASS_NAME applied to them.
                // Most focus styles are applied as ::before elements in lodestar-core.
                [`body:not(.${SHOW_FOCUS_MARKER}) .${FOCUS_USING_BEFORE_CLASS_NAME}::before`]:
                    {
                        display: "none",
                    },

                // This style will hide the actual element (as opposed to the pseudoelement ::before) with the className
                // Most focus styles are applied as ::before elements in lodestar-core, but some need to be regular elements (like Tabs).
                [`body:not(.${SHOW_FOCUS_MARKER}) .${FOCUS_USING_ELEMENT_CLASS_NAME}`]:
                    {
                        display: "none",
                    },
            }}
        />
    );
};
