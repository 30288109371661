import React from "react";
import { components } from "react-select";
import { IE11Flexbug3Fix } from "./IE11Flexbug3Fix";

export const IndicatorsContainer: typeof components.IndicatorsContainer = ({
    children,
    ...props
}) => {
    return (
        <IE11Flexbug3Fix>
            <components.IndicatorsContainer {...props}>
                {children}
            </components.IndicatorsContainer>
        </IE11Flexbug3Fix>
    );
};
