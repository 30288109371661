/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgInfo: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.5a8.5 8.5 0 10.001 17.001A8.5 8.5 0 0012 3.5zm-.066 7.5c.178 0 .347.03.494.13a.67.67 0 01.321.58v4.595c0 .326-.237.6-.579.676-.458.102-.78-.217-.796-.654v-3.844c0-.086 0-.086-.094-.086-.096 0-.193 0-.288-.016-.328-.053-.633-.337-.61-.736.017-.308.3-.595.648-.637.056-.007.113-.009.17-.009l.734.001zm.065-3a.796.796 0 01.801.8c0 .442-.36.8-.802.8a.795.795 0 01-.798-.803A.79.79 0 0112 8z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
