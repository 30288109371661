/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgEdit: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 14 14" fill="none" {...props}>
        <path
            d="M11.09 1.245l.112.09.177.166 1.2 1.204c.237.235.375.506.41.813.042.351-.04.671-.24.94l-.094.111-.126.134-7.875 7.876a1.4 1.4 0 01-.667.384l-.154.029-.052.005-.104.002h-1.93a.725.725 0 01-.72-.547l-.021-.113L1 12.251 1 10.363a1.367 1.367 0 01.307-.888l.111-.123 7.933-7.934c.237-.238.514-.377.828-.41.338-.035.649.045.911.237zm-2.501 3l-6.125 6.129v1.162h1.16L9.751 5.41 8.59 4.246zm1.735-1.733l-.702.702 1.162 1.162.7-.698-1.16-1.166z"
            fill="currentColor"
        />
    </svg>
);
