import { focus } from "../constants/colors";
import { themed, ThemeMode } from "./theme";

/**
 * Apply this to any element that has a focus ring created with a ::before pseudoelement.
 * {@see createFocusRing} for more details.
 */
export const FOCUS_USING_BEFORE_CLASS_NAME = "lodestar--focus-using-before";

/**
 * Apply this to any element that has a focus ring created with a real
 * DOM element (as opposed to a ::before pseudoelement).
 * {@see createFocusRing} for more details.
 */
export const FOCUS_USING_ELEMENT_CLASS_NAME = "lodestar--focus-using-element";

export interface CreateFocusRingProps {
    /**
     * Distance away from the component. This will be applied as negative top/right/bottom/left.
     */
    offset: number;

    /**
     * Border-radius. If number, assume px. If string, assume units come with it.
     */
    radius: number | string;

    /**
     * The ring's color.
     * This is useful in theming, but shouldn't be overridden usually.
     */
    theme: ThemeMode;

    /**
     * border-width
     */
    borderWidth?: number;

    /**
     * If specified, overrides the default colors from the theme.
     */
    colorOverride?: string;

    /**
     * Defaults to false. If true, will include the :focus modifier on the root selector.
     * This allows the consumer to apply the focusRing directly to a focusable element more easily.
     * In lodestar-core, this mixin is commonly applied to an element that doesn't actually receive focus.
     * example: Toggle, Select.
     */
    includeFocusPseudoClass?: boolean;

    /**
     * Position to apply to the root element.
     */
    position?: "relative" | "absolute";

    /**
     * Which pseudoelement to use for the ring. Defaults to ::before.
     * Useful in the case where you have 2 rings on a component i.e. select.
     */
    pseudoElement?: "before" | "after";
}

const focusRingTheme = themed({
    color: {
        light: focus,
        oldestar: "#0073ec",
    },
});

export const getFocusRingStyles = ({
    colorOverride: color,
    theme,
    offset,
    radius: borderRadius,
    includeFocusPseudoClass = false,
    borderWidth = 2,
    position = "relative",
    pseudoElement = "before",
}: CreateFocusRingProps): string => `
    & {
        position: ${position};
        outline: none;
    }

    &:focus {
        outline: none;
    }

    &${includeFocusPseudoClass ? ":focus" : ""}::${pseudoElement} {
        content: "";
        display: block;
        position: absolute;
        top: -${offset}px;
        right: -${offset}px;
        bottom: -${offset}px;
        left: -${offset}px;
        border: ${borderWidth}px solid ${color || focusRingTheme.color(theme)};
        border-radius: ${
            typeof borderRadius === "string"
                ? borderRadius
                : `${borderRadius}px`
        };
        pointer-events: none;
    }
`;
