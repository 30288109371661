import { MouseEventHandler, useEffect, useMemo, useState } from "react";
import { callAllHandlers } from "./callAllHandlers";

export interface UseActiveOptions<E> {
    /**
     * A custom handler for this event that will be folded into the composite handlers
     * that are returned from this hook. This function is required for this hook to work,
     * so to make sure your function doesn't get overridden by this one, please pass it
     * along here.
     */
    onMouseDown?: MouseEventHandler<E>;

    /**
     * A custom handler for this event that will be folded into the composite handlers
     * that are returned from this hook. This function is required for this hook to work,
     * so to make sure your function doesn't get overridden by this one, please pass it
     * along here.
     */
    onMouseEnter?: MouseEventHandler<E>;

    /**
     * A custom handler for this event that will be folded into the composite handlers
     * that are returned from this hook. This function is required for this hook to work,
     * so to make sure your function doesn't get overridden by this one, please pass it
     * along here.
     */
    onMouseLeave?: MouseEventHandler<E>;
}

export interface UseActiveResult<E> {
    /**
     * If the user's mouse is pressing down on the target element, this will be true.
     */
    isActive: boolean;

    /**
     * The resulting handler to be applied to the target element.
     * If you passed a customization in, it will be called first before this hook
     * calls its own.
     */
    onMouseDown: MouseEventHandler<E>;

    /**
     * The resulting handler to be applied to the target element.
     * If you passed a customization in, it will be called first before this hook
     * calls its own.
     */
    onMouseEnter: MouseEventHandler<E>;

    /**
     * The resulting handler to be applied to the target element.
     * If you passed a customization in, it will be called first before this hook
     * calls its own.
     */
    onMouseLeave: MouseEventHandler<E>;
}

/**
 * Provides a css-in-js alternative to using the native :active selector.
 * Works on non-button elements!
 *
 * Spread all the resulting handlers onto the target element. Make sure to pass
 * along any custom handlers into this function so they are composed together.
 */
export const useActive = <
    TargetElementType extends HTMLElement = HTMLButtonElement
>({
    onMouseEnter: providedOnMouseEnter,
    onMouseDown: providedOnMouseDown,
    onMouseLeave: providedOnMouseLeave,
}: UseActiveOptions<TargetElementType> = {}) => {
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [isMouseWithin, setIsMouseWithin] = useState(false);

    const onMouseDown: MouseEventHandler<TargetElementType> = useMemo(
        () =>
            callAllHandlers(providedOnMouseDown, () => {
                setIsMouseDown(true);
            }),
        [providedOnMouseDown]
    );

    const onMouseEnter: MouseEventHandler<TargetElementType> = useMemo(
        () =>
            callAllHandlers(providedOnMouseEnter, () => {
                setIsMouseWithin(true);
            }),
        [providedOnMouseEnter]
    );

    const onMouseLeave: MouseEventHandler<TargetElementType> = useMemo(
        () =>
            callAllHandlers(providedOnMouseLeave, () => {
                setIsMouseWithin(false);
            }),
        [providedOnMouseLeave]
    );

    // Can't expect the mouseup to be fired directly on the element.
    // The user could mouseleave and then mouseup.
    useEffect(() => {
        const onMouseUp = () => {
            setIsMouseDown(false);
        };
        document.addEventListener("mouseup", onMouseUp);
        return () => {
            document.removeEventListener("mouseup", onMouseUp);
        };
    }, []);

    return {
        isActive: isMouseDown && isMouseWithin,
        onMouseDown,
        onMouseEnter,
        onMouseLeave,
    };
};
