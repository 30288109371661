import React from "react";
import { components } from "react-select";
import { StyledOption } from "./StyledOption";

export const Option: typeof components.Option = (props) => {
    const { className, innerRef, children, isDisabled, isFocused, isSelected } =
        props;

    return (
        <StyledOption
            className={className}
            ref={innerRef}
            // TODO optionHeight should be moved to the LayoutContext.
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            height={props.selectProps.optionHeight}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            {...props.innerProps}
        >
            {children}
        </StyledOption>
    );
};
