/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgInfoFilled: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            fill="currentColor"
            d="M2 12a10 10 0 1020 0 10 10 0 10-20 0m9.25-4a1.001 1.001 0 111 1 1 1 0 01-1-1zm.25 2.5a.75.75 0 000 1.5h.25v4.25a.75.75 0 001.5 0v-5a.75.75 0 00-.75-.75h-1z"
        />
    </svg>
);
