export interface ScrollInfo {
    contentHeight: number;
    top: number;
    viewportHeight: number;
}

// Breaking this out greatly improves unit testability since the
// bare element properties do not function as expected in the node-based
// jest unit test environment.
/**
 * A simple wrapper to get scroll properties from a dom element.
 **/
export const getScrollInfo = (element: HTMLDivElement): ScrollInfo => {
    const info: ScrollInfo = {
        top: element.scrollTop,
        viewportHeight: element.clientHeight,
        contentHeight: element.scrollHeight,
    };
    return info;
};
