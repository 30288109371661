import styled from "@emotion/styled";
import { neutralLight40 } from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import {
    getHandleSize,
    getHeight,
    getWidth,
    ToggleSize,
    transition,
} from "./toggleStyleConstants";

const translateXValues = {
    large: {
        offNoHover: "initial",
        offHover: `translateX(${sizes.xxSmall}px)`,
        onHover: `translateX(${
            getWidth("large") - getHeight("large") - sizes.xxSmall
        }px)`,
        onNoHover: `translateX(${getWidth("large") - getHeight("large")}px)`,
    },
    medium: {
        offNoHover: "initial",
        offHover: `translateX(${sizes.xxSmall / 2}px)`,
        onHover: `translateX(${
            getWidth("medium") - getHeight("medium") - sizes.xxSmall / 2
        }px)`,
        onNoHover: `translateX(${getWidth("medium") - getHeight("medium")}px)`,
    },
};

const margin = {
    large: 4,
    medium: 3,
};

/**
 * There are 4 horizontal states for the handle:
 * 1) unchecked + no hover: handle is far-left (on ltr screens).
 * 2) unchecked + hovered: handle scoots a bit to the "on" position.
 * 3) checked + hovered: handle scoots a bit to the "off" position, away from fully "on".
 * 4) checked + no hover: handle is fully "on" and is far-right (on ltr screens).
 *
 * The hover state options - 2 and 3 - do not apply if the Toggle is disabled.
 */
const getTransform = ({
    isChecked,
    isHovered,
    isDisabled,
    size,
}: HandleStyleProps) => {
    const translation = translateXValues[size];
    if (isChecked && (!isHovered || isDisabled)) {
        return translation.onNoHover;
    } else if (!isDisabled && !isChecked && isHovered) {
        return translation.offHover;
    } else if (!isDisabled && isChecked && isHovered) {
        return translation.onHover;
    } else {
        return translation.offNoHover;
    }
};

export interface HandleStyleProps {
    isChecked: boolean;
    isDisabled: boolean;
    isHovered: boolean;
    size: ToggleSize;
}

export const Handle = styled.span<HandleStyleProps>`
    position: absolute;
    background-color: ${neutralLight40};
    border-radius: 50%;
    height: ${({ size }) => getHandleSize(size)}px;
    // The Slide itself has the other 2px padding to bring this to 4px of distance.
    margin-left: ${({ size }) => margin[size]}px;
    transform: ${getTransform};
    transition: ${transition};
    width: ${({ size }) => getHandleSize(size)}px;
`;
