/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { components } from "react-select";

export const ValueContainer: typeof components.ValueContainer = (props) => {
    return (
        <components.ValueContainer
            {...props}
            css={css`
                // Need to ensure that we show as much of the SingleValue component as possible.
                align-self: stretch;
                padding-left: 0;
            `}
        >
            {props.children}
        </components.ValueContainer>
    );
};
