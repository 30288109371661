import React, { useCallback, useContext } from "react";
import { components as rsComponents } from "react-select";
import {
    DEFAULT_ITEM_HEIGHT,
    MAX_OPTIONS_UNTIL_VIRTUALIZATION,
} from "../selectConstants";
import { ComponentsConfig } from "../types";
import { SelectAllContext } from "./SelectAllContext";
import { VirtualizedMenuList } from "./VirtualizedMenuList";

export const MenuList: typeof rsComponents.MenuList = (props) => {
    const {
        options = [],
        optionHeight = DEFAULT_ITEM_HEIGHT,
        components,
    } = props.selectProps;

    const selectAllEnabled = useContext(SelectAllContext).enabled;

    const getOptionHeight = useCallback(
        (index: number) => {
            if (selectAllEnabled && index === 0) {
                // TODO move optionHeight to the LayoutContext.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return optionHeight + 24; // 24 is the height of the Separator.
            } else {
                // TODO move optionHeight to the LayoutContext.
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return optionHeight;
            }
        },
        [selectAllEnabled, optionHeight]
    );

    if (options.length > MAX_OPTIONS_UNTIL_VIRTUALIZATION) {
        const VirtualizedMenuListComponent =
            (components as ComponentsConfig<unknown>).VirtualizedMenuList ||
            VirtualizedMenuList;

        return (
            <VirtualizedMenuListComponent
                maxHeight={props.maxHeight}
                getOptionHeight={getOptionHeight}
                innerRef={props.innerRef}
            >
                {props.children}
            </VirtualizedMenuListComponent>
        );
    }
    return (
        <rsComponents.MenuList {...props}>
            {props.children}
        </rsComponents.MenuList>
    );
};
