import { CloseIcon } from "@smartsheet/lodestar-icons";
import React, { ButtonHTMLAttributes, FC, forwardRef } from "react";
import { Assign } from "utility-types";
import { BannerAction } from "./BannerAction";

export type BannerDismissButtonProps = Assign<
    ButtonHTMLAttributes<HTMLButtonElement>,
    {
        /**
         * Requied for this button since there's no text content within it.
         */
        "aria-label": string;
    }
>;

export const BannerDismissButton: FC<BannerDismissButtonProps> = forwardRef<
    HTMLButtonElement,
    BannerDismissButtonProps
>((props, ref) => {
    return (
        <BannerAction tabIndex={0} ref={ref} {...props}>
            <CloseIcon size="small" />
        </BannerAction>
    );
});

BannerDismissButton.displayName = "BannerDismissButton";
