import { Ref, MutableRefObject, useRef, useEffect } from "react";

/**
 * Allows a ref forwarding component to optionally pass a ref in and returns a
 * ref every time. Using this hook allows subsequent code to always have a
 * ref to operate against.
 *
 * Ref mutations to the returned ref will be propagated to the passed in ref.
 *
 * I wanted to use @react-hookz/web for this, but the types don't handle null well and
 * only want to play nicely with MutableRefs. This is fine since that's the type
 * we'll actually have at runtime but is a bad dev xp since React.createRef and useRef
 * return a RefObject (immutable) sometimes.
 *
 * See more here: https://github.com/streamich/@react-hookz/web/issues/743
 * This code is adapted from a comment in that thread.
 */
export function useEnsuredForwardedRef<T>(
    forwardedRef: Ref<T> | undefined
): MutableRefObject<T | null> {
    const ensuredRef = useRef<T>(null);

    // Sync the passed in ref w/ the local one when it changes, accounting for functional refs as well.
    useEffect(() => {
        if (!forwardedRef) {
            return;
        } else if (typeof forwardedRef === "function") {
            // TODO if this ref's element is mounted after a tick, this will ALWAYS be null!
            forwardedRef(ensuredRef.current);
        } else {
            (forwardedRef as MutableRefObject<T | null>).current =
                ensuredRef.current;
        }
    }, [forwardedRef]);

    return ensuredRef;
}
