import styled from "@emotion/styled";
import { Modifier, Placement } from "@popperjs/core";
import { Options as ArrowModiferOptions } from "@popperjs/core/lib/modifiers/arrow";
import { usePrevious } from "@react-hookz/web";
import React, {
    FC,
    forwardRef,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Transition } from "react-transition-group";
import { TransitionStatus } from "react-transition-group/Transition";
import { Omit } from "utility-types";
import { tooltipBackground } from "../../constants/colors";
import { Flyout, FlyoutArrow, FlyoutProps } from "../flyout";
import {
    defaultArrowSize,
    FlyoutArrowProps,
} from "../flyout/styled/FlyoutArrow";
import { TooltipFlyoutContainer } from "./components/TooltipFlyoutContainer";
import { TooltipContainerProps } from "./styled/BasicTooltip";
import { ANIMATION_DURATION, tooltipFlyoutDefaults } from "./tooltipConstants";
import { ArrowEdge, DirectionOrAuto } from "./types";

export type TooltipProps = Omit<FlyoutProps, "onCloseRequested" | "components">;

interface TooltipFlyoutArrowProps extends FlyoutArrowProps {
    arrowAtEdge: ArrowEdge;
}

/**
 * For each placement, we need to figure out if we should square off a corner of the flyout
 * to mesh well with the arrow when it's at the edge.
 * @param placement
 */
function placementToSquaredCorner(
    placement: Placement
): TooltipContainerProps["squareCorner"] {
    switch (placement) {
        case "auto":
        case "auto-end":
        case "auto-start":
            return undefined;
        case "top-start":
        case "left-start":
            return "borderBottomRight";
        case "top-end":
        case "right-start":
            return "borderBottomLeft";
        case "right-end":
        case "bottom-end":
            return "borderTopLeft";
        case "bottom-start":
        case "left-end":
            return "borderTopRight";
        default:
            return undefined;
    }
}

function getArrowEdge(placement: Placement, offset: number): ArrowEdge {
    if (offset === 0) {
        return false;
    }
    switch (placement) {
        case "auto":
        case "auto-end":
        case "auto-start":
            return false;
        case "top-start":
        case "top":
        case "top-end":
        case "bottom-start":
        case "bottom":
        case "bottom-end":
            return offset > 0 ? "left" : "right";
        case "right-start":
        case "right":
        case "right-end":
        case "left-start":
        case "left":
        case "left-end":
            return offset > 0 ? "top" : "bottom";
        default:
            return false;
    }
}

const CustomStyledTooltipArrow = styled(FlyoutArrow)<TooltipFlyoutArrowProps>`
    // Turn off (rest) the default arrow styles from the Flyout so we can do some
    // fancier stuff w/ the :before here.
    // We originally thought we might take this code and apply it all to the Flyout so all
    // Flyouts could have the same arrow styling, but that would require also managing the corners
    // of all Flyouts to get the right rounding. We'll have to pass that by UX before we do anything.
    width: 8px;
    height: 8px;
    background-color: transparent;
    border: none;
    border-width: 0;
    // We shift the inner :before arrow around to cut part of it off when it's at the
    // Tooltip's edge.
    overflow: hidden;

    // The arrow itself.
    &::before {
        content: "";
        position: absolute;

        // Set the right size, and turn off the border color by default.
        // We'll override below.
        border: ${({ size = defaultArrowSize }) =>
            `${size}px solid transparent`};

        // Set the background color into the correct border to give us a triangle.
        ${({ backgroundColor, placement }) => {
            // "top" | "left" | "right" | "bottom"
            const whichBorderColor = placement.split("-")[0];
            return `border-${whichBorderColor}-color: ${backgroundColor}`;
        }};

        // If the arrow is at the edge, offset it so only half of it shows.
        // This gives the arrow that pointy look that gets the people GOING!
        ${({ arrowAtEdge, size = defaultArrowSize }) =>
            arrowAtEdge ? `${arrowAtEdge}: -${size}px;` : ""};
    }
`;

interface TooltipFlyoutArrowProps extends FlyoutArrowProps {
    arrowAtEdge: ArrowEdge;
}

const TooltipFlyoutArrow = forwardRef<HTMLDivElement, TooltipFlyoutArrowProps>(
    ({ arrowAtEdge, ...props }, ref) => {
        return (
            <CustomStyledTooltipArrow
                arrowAtEdge={arrowAtEdge}
                {...props}
                ref={ref}
            />
        );
    }
);
TooltipFlyoutArrow.displayName = "TooltipFlyoutArrow";

/**
 * Basic tooltip container.
 * Adds a standard backdrop to the Flyout and should be combined with an
 * inner wrapper like TooltipBody to provide the correct spacing.
 */
export const Tooltip: FC<TooltipProps> = (props) => {
    const [arrowAtEdge, setArrowAtEdge] = useState<
        "top" | "left" | "right" | "bottom" | false
    >(false);

    // If auto, the actual placement can be different with different target dimensions and/or scroll positions.
    const [actualPlacement, setActualPlacement] = useState<Placement>(
        props.placement || "auto"
    );

    // Initially do not transition until this is true.
    const [showTransition, setShowTransition] = useState(false);
    const showTransitionRafRef = useRef<number>();

    // Clean this up so we aren't setting state on unmounted components.
    useEffect(
        () => () => {
            if (showTransitionRafRef.current) {
                cancelAnimationFrame(showTransitionRafRef.current);
            }
        },
        []
    );

    // Adds a write-phase modifer to check if the arrow is going to be rendered at the edge of the
    // Flyout's content. If so, we want to render a different arrow shape.
    const arrowEdgeModifier: Modifier<"arrowAtEdge", ArrowModiferOptions> =
        useMemo(
            () => ({
                name: "arrowAtEdge",
                enabled: true,
                phase: "write",
                fn({ state }) {
                    if (state.elements.arrow && state.modifiersData.arrow) {
                        setActualPlacement(state.placement);
                        if (
                            state.modifiersData.arrow.centerOffset !==
                                undefined &&
                            Math.abs(state.modifiersData.arrow.centerOffset) > 0
                        ) {
                            setArrowAtEdge(
                                getArrowEdge(
                                    state.placement,
                                    state.modifiersData.arrow.centerOffset
                                )
                            );
                        } else {
                            setArrowAtEdge(false);
                        }
                    }
                },
            }),
            []
        );

    const enterDirection = actualPlacement.split("-")[0] as DirectionOrAuto;
    const { isOpen } = props;
    const previousIsOpen = usePrevious(isOpen);

    return (
        <Transition
            // The tooltip should animate on mount so this is always true.
            in={isOpen}
            timeout={{ enter: 0, exit: ANIMATION_DURATION }}
            appear
        >
            {(transitionStatus: TransitionStatus) => {
                return (
                    <Flyout
                        {...tooltipFlyoutDefaults}
                        {...props}
                        popperProps={{
                            ...props.popperProps,
                            modifiers: [
                                arrowEdgeModifier,
                                ...(props.popperProps?.modifiers || []),
                            ],
                            onFirstUpdate: (args) => {
                                if (
                                    typeof props.popperProps?.onFirstUpdate ===
                                    "function"
                                ) {
                                    props.popperProps.onFirstUpdate(args);
                                }
                                showTransitionRafRef.current =
                                    requestAnimationFrame(() => {
                                        setShowTransition(true);
                                    });
                            },
                        }}
                        // Once isOpen is set to false, we want to keep it rendered until the exit animation completes.
                        isOpen={
                            isOpen ||
                            (!!previousIsOpen &&
                                !isOpen &&
                                transitionStatus !== "exited")
                        }
                        role="tooltip"
                    >
                        {({ getArrowProps }) => (
                            <TooltipFlyoutContainer
                                enterDirection={enterDirection}
                                transitionStatus={transitionStatus}
                                showTransition={showTransition}
                                squareCorner={
                                    (arrowAtEdge &&
                                        placementToSquaredCorner(
                                            actualPlacement
                                        )) ||
                                    undefined
                                }
                            >
                                {props.children}
                                <TooltipFlyoutArrow
                                    {...getArrowProps()}
                                    backgroundColor={tooltipBackground}
                                    arrowAtEdge={arrowAtEdge}
                                    size={defaultArrowSize}
                                />
                            </TooltipFlyoutContainer>
                        )}
                    </Flyout>
                );
            }}
        </Transition>
    );
};
