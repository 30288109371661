/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgKebab: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                d="M12 16a2 2 0 11.001 3.999A2 2 0 0112 16zm0-6a2 2 0 11.001 3.999A2 2 0 0112 10zm0-6a2 2 0 11.001 3.999A2 2 0 0112 4z"
            />
        </g>
    </svg>
);
