/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgSortDescending: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M11.472 19.785l-5.742-5.54c-.41-.392-.248-1.03.293-1.176a.753.753 0 01.752.197l4.346 4.21.122.11c.03-.066.02-.12.02-.173V4.784a.79.79 0 01.08-.391.743.743 0 01.82-.376.722.722 0 01.57.65l.002.083v12.66c0 .053-.015.108.016.16.06-.011.087-.058.123-.092l4.357-4.218c.423-.396 1.097-.232 1.243.302.074.27-.012.502-.221.7l-4.928 4.762-.797.76a.752.752 0 01-1.056 0z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
