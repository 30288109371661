/**
 * Returns a clientId if the provided clientId is defined, optionally appending a suffix.
 * This is used to generate client ids for multiple unique portions of a component
 * based on a provided clientId.
 */
export const getClientId = (
    clientId: string | undefined,
    append: string | undefined
): string | undefined => {
    if (!clientId) return undefined;
    return [clientId, append].filter(Boolean).join("-");
};
