/**
 * This file is generated from scripts/genIconComponents.js. Any
 * changes should be made in that file.
 */
import React, { forwardRef, Ref } from "react";
import { getSvgResourceForSize, getDefaultSize } from "../../utils";
import { Icon, LodestarIconProps } from "../../icon";
import { SvgCheck as CheckMedium } from "./medium/Check";
const sizeMap = {
    medium: CheckMedium,
};
const defaultSize = getDefaultSize(sizeMap);

export const CheckIcon = forwardRef(
    (
        { size = defaultSize, ...rest }: LodestarIconProps,
        ref: Ref<HTMLElement>
    ) => (
        <Icon
            ref={ref}
            svgComponent={getSvgResourceForSize(sizeMap, size)}
            size={size}
            {...rest}
        />
    )
);
CheckIcon.displayName = "CheckIcon";
