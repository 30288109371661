import invariant from "tiny-invariant";

/**
 * Button Primary
 */
export const buttonPrimary = "#3576ba";
export const buttonPrimaryHover = "#6097d2";
export const buttonPrimaryPressed = "#4c649d";
export const buttonPrimaryFocus = buttonPrimary;
export const buttonPrimaryDisabled = "#b3ccea";

/**
 * Button Secondary
 */
// todo: rename to buttonSecondary to match color names in style guide.
export const buttonDefault = "#eeeeee";
export const buttonDefaultHover = "#e0e0e0";
export const buttonDefaultPressed = "#c0c0c0";
export const buttonDefaultFocus = buttonDefault;
export const buttonDefaultDisabled = "#f2f2f2";

export const buttonSecondary = "#32b1ad"; // todo: remove, obsolete.

/**
 * Button Share
 */
export const ctaShare = "#007e84";
export const ctaShareHover = "#40bac0";
export const ctaShareActive = "#01595d"; // todo: rename to Pressed
export const ctaShareFocus = ctaShare;
export const ctaShareDisabled = "#aed5d6";

/**
 * Button Destructive
 */
export const buttonDestructive = "#cc2e3b";
export const buttonDestructiveHover = "#d8656f";
export const buttonDestructivePressed = "#a62833";
export const buttonDestructiveFocus = buttonDestructive;
export const buttonDestructiveDisabled = "#edbec2";

/**
 * Button Upgrade
 */
// todo: replace existing ctaUpgrade with this set.
export const buttonUpgrade = "#007a49";
export const buttonUpgradeHover = "#40c28f";
export const buttonUpgradePressed = "#00693f";
export const buttonUpgradeFocus = buttonUpgrade;
export const buttonUpgradeDisabled = "#b3d7c8";

export const ctaUpgrade = "#2c6665";

/**
 * Link and Focus
 */
export const ctaLink = "#0970be";

export const focusDark = "#4c649d"; // focus dark 10 in figma
export const focus = "#6097d2";
export const focusLight = "#b0cae9"; // todo: remove, obsolete?
export const focusLight10 = "#b3ccea"; // focus light 10 in figma
export const focusLight20 = "#c5d7ed"; // focus light 20 in figma

export const active = "#204a76"; // todo: remove, obsolete?
export const focusActiveLight = "#b0cae9";
export const focusActiveDark = "#566ae3"; // visPurpleDark10

/**
 * Success
 */
export const successDark30 = "#00462a";
export const successDark20 = "#00693f";
export const successDark10 = "#008c54";
export const success = "#01af6a";
export const successLight10 = "#40c28f";
export const successLight20 = "#7dd4b1";
export const successLight30 = "#bbe7d6";

/**
 * Warning
 */
export const warningDark30 = "#664e18";
export const warningDark20 = "#997525";
export const warningDark10 = "#cc9c31";
export const warning = "#ffc33e";
export const warningLight10 = "#fdd16e";
export const warningLight20 = "#fcde9b";
export const warningLight30 = "#fbeccb";

/**
 * Danger
 */
export const dangerDark30 = "#531419";
export const dangerDark20 = "#7c1e26";
export const dangerDark10 = "#a62833";
export const danger = "#d03340";
export const dangerLight10 = "#db656f";
export const dangerLight20 = "#e4969c";
export const dangerLight30 = "#efc8cb";

/**
 * Info
 */
export const infoDark30 = "#3f4952";
export const infoDark20 = "#5e6d7b";
export const infoDark10 = "#7e92a4";
export const info = "#9eb7ce";
export const infoLight10 = "#b6c8da";
export const infoLight20 = "#cbd8e3";
export const infoLight30 = "#e3e9ef";

/**
 * Visualization Blue
 */
export const visBlueDark30 = "#002847";
export const visBlueDark20 = "#004173";
export const visBlueDark10 = "#007cc5";
export const visBlue = "#5996d6";
export const visBlueLight10 = "#a7c1e4";
export const visBlueLight20 = "#d8e4fa";
export const visBlueLight30 = "#f0f5fd";

/**
 * Visualization Green
 */
export const visGreenDark30 = "#193700";
export const visGreenDark20 = "#275600";
export const visGreenDark10 = "#488306";
export const visGreen = "#70a13c";
export const visGreenLight10 = "#b2c997";
export const visGreenLight20 = "#e1ebd6";
export const visGreenLight30 = "#f3f7ef";

/**
 * Visualization Orange
 */
export const visOrangeDark30 = "#492400";
export const visOrangeDark20 = "#653300";
export const visOrangeDark10 = "#ab6600";
export const visOrange = "#e18502";
export const visOrangeLight10 = "#ffb761";
export const visOrangeLight20 = "#fee3c0";
export const visOrangeLight30 = "#fff4e6";

/**
 * Visualization Yellow
 */
export const visYellowDark30 = "#3d2c00";
export const visYellowDark20 = "#5a4000";
export const visYellowDark10 = "#996f00";
export const visYellow = "#e6a907";
export const visYellowLight10 = "#f5cf6a";
export const visYellowLight20 = "#ffefc5";
export const visYellowLight30 = "#fff8e7";

/**
 * Visualization Red
 */
export const visRedDark30 = "#6d0800";
export const visRedDark20 = "#9d0c00";
export const visRedDark10 = "#d14424";
export const visRed = "#ee6f4c";
export const visRedLight10 = "#f6b09d";
export const visRedLight20 = "#ffe0d7";
export const visRedLight30 = "#fff3ef";

/**
 * Visualization Purple
 */
export const visPurpleDark30 = "#0c245b";
export const visPurpleDark20 = "#0c3491";
export const visPurpleDark10 = "#566ae3";
export const visPurple = "#848be9";
export const visPurpleLight10 = "#bab8f1";
export const visPurpleLight20 = "#e6e5fa";
export const visPurpleLight30 = "#f5f5fd";

/**
 * Visualization Berry
 */
export const visBerryDark30 = "#610e44";
export const visBerryDark20 = "#8b2066";
export const visBerryDark10 = "#b94d90";
export const visBerry = "#c76ca1";
export const visBerryLight10 = "#e5b8d1";
export const visBerryLight20 = "#f5e1ec";
export const visBerryLight30 = "#fbf3f8";

/**
 * Visualization Teal
 */
export const visTealDark30 = "#002923";
export const visTealDark20 = "#004e43";
export const visTealDark10 = "#008476";
export const visTeal = "#4eaa9b";
export const visTealLight10 = "#a1c9c2";
export const visTealLight20 = "#dceae7";
export const visTealLight30 = "#f1f7f6";

/**
 * Neutrals
 */
export const neutralDark40 = "#111111";
export const neutralDark30 = "#444444";
export const neutralDark20 = "#727272";
export const neutralDark10 = "#979797";
export const neutral = "#c0c0c0";
export const neutralLight10 = "#e0e0e0";
export const neutralLight20 = "#ebebeb";
export const neutralLight30 = "#f2f2f2";
export const neutralLight40 = "#ffffff";

// This is "accessible" against black (7:1) but not against white(2.9:1).
export const neutralAccessible = neutralDark10;

/**
 * Backgrounds
 */
export const defaultBackground = "#f8f7f5"; // figma: light bg
export const tooltipBackground = "rgba(84, 84, 84, 1)"; // figma: tooltip gray
export const blanketBackground = "rgba(0, 0, 0, 0.1)"; // figma: light overlay
export const leftNavBackground = "#003059"; // figma: left nav

/**
 * Shadows
 */
export const shadowMenu = "rgba(0, 0, 0, 0.16)";
export const shadowModal = "rgba(0, 0, 0, 0.12)";
export const shadowBorder = "rgba(0, 0, 0, 0.12)";

/**
 * Adapted from https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param hexColor "#rrggbb"
 * @param alpha a transparency number between 0.0 - 1.0;
 */
export function toRgba(hexColor: string, alpha: number) {
    if (process.env.NODE_ENV !== "production") {
        invariant(
            hexColor.length === 7,
            `You've passed an illegal argument to toRgba. It should be of the pattern #aaaaaa: "${hexColor}".`
        );
        invariant(
            alpha >= 0 && alpha <= 1,
            `You've passed an illegal alpha number to toRgba. It should be between 0 and 1, inclusive: ${alpha}.`
        );
    }

    const r = "0x" + hexColor[1] + hexColor[2];
    const g = "0x" + hexColor[3] + hexColor[4];
    const b = "0x" + hexColor[5] + hexColor[6];
    const a = +alpha.toFixed(3);

    return `rgba(${+r},${+g},${+b},${a})`;
}
