import React, { useContext } from "react";
import { neutralDark40 } from "../../constants/colors";
import { LodestarCoreTheme } from "../../util/theme";
import { TagStyled } from "./TagStyled";
import * as Theme from "./TagTheme";
import { TagColor, CustomColor } from "./TagTypes";

export interface TagProps {
    /**
     * Identifies the element (or elements) that describes the object.
     */
    "aria-describedby"?: string;

    /**
     * Applies aria-hidden to the hidden input inside the Checkbox.
     */
    "aria-hidden"?: boolean;

    /**
     * Defines a string value that labels the current element.
     */
    "aria-label"?: string;

    /**
     * Identifies the element (or elements) that labels the current element.
     */
    "aria-labelledby"?: string;
    /**  A classname to be applied to the root element of this component. */
    className?: string;
    /**
     A `clientId` prop is provided for specified elements, which is a unique string that
     appears as a data attribute `data-client-id` in the rendered code, serving as a hook
     for automated tests
     */
    clientId?: string;
    /** The color theme to apply, or custom color object comprised of css values for `backgroundColor` and `textColor`. */
    color?: TagColor | CustomColor;
}

/**
 * A Tag labels UI objects for quick recognition and navigation.
 */
export const Tag: React.FC<TagProps> = ({
    "aria-hidden": ariaHidden,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledby,
    "aria-describedby": ariaDescribedBy,
    children,
    className,
    color = "blue",
    clientId,
}) => {
    let backgroundColor, textColor;
    const theme = useContext(LodestarCoreTheme);
    if (typeof color === "string") {
        backgroundColor = Theme.backgroundColor(color, theme);
        textColor = neutralDark40;
    } else {
        ({ backgroundColor, textColor } = color);
    }
    return (
        <TagStyled
            className={className}
            backgroundColor={backgroundColor}
            textColor={textColor}
            data-client-id={clientId}
            aria-hidden={ariaHidden}
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedBy}
        >
            {children}
        </TagStyled>
    );
};
