import styled from "@emotion/styled";
import React from "react";
import { components } from "react-select";
import { neutralDark40, visBlueLight10 } from "../../../constants/colors";
import { Token } from "../../token";

const StyledMultiValueToken = styled(Token)`
    margin-right: 4px;
    margin-top: 6px;
`;

export const MultiValue: typeof components.MultiValue = (props) => {
    return (
        <StyledMultiValueToken
            className={props.className}
            isDisabled={props.isDisabled}
            onCloseSelected={props.removeProps.onClick}
            onMouseDown={props.removeProps.onMouseDown}
            color={{
                backgroundColor: visBlueLight10,
                textColor: neutralDark40,
            }}
        >
            {props.children}
        </StyledMultiValueToken>
    );
};
