import { createContext, useContext } from "react";
import { BannerType } from "../BannerTypes";

export interface BannerContextValue {
    type: BannerType;
}

const defaultContext: BannerContextValue = {
    type: "info",
};

const BannerContext = createContext<BannerContextValue>(defaultContext);

export const BannerContextProvider = BannerContext.Provider;

export const useBannerContext = () => useContext(BannerContext);
