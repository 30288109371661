/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { ArrowDownIcon } from "@smartsheet/lodestar-icons";
import { components } from "react-select";
import { neutral } from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";

export const DropdownIndicator: typeof components.DropdownIndicator = (
    props
) => (
    <components.DropdownIndicator
        {...props}
        css={css`
            ${props.selectProps.isDisabled
                ? `
                    color: ${neutral};
                    &:hover {
                        color: ${neutral};
                    }
                `
                : `
                    color: inherit;
                    &:hover {
                        color: inherit;
                    }
                `}
            padding: 0 ${sizes.xSmall}px;
        `}
    >
        <ArrowDownIcon aria-label="open" />
    </components.DropdownIndicator>
);
