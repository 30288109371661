import styled from "@emotion/styled";
import { sizes } from "../../../constants/spacing";
import { TypeRamp } from "../../typography";
import { ModalType } from "../types";

interface ModalHeaderProps {
    type: ModalType;
}

function omitModalHeaderProps(propName: string) {
    switch (propName) {
        case "type":
            return false;
        default:
            return true;
    }
}

export const StyledModalHeader = styled("header", {
    shouldForwardProp: omitModalHeaderProps,
})<ModalHeaderProps>`
    // For the absolutely-positioned top-right buttons.
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    ${(props) =>
        props.type === "simple"
            ? `
            padding: ${sizes.xLarge}px ${sizes.xxLarge}px 0px ${sizes.xLarge}px;
            `
            : `
            padding: ${sizes.xLarge}px ${sizes.xxLarge}px ${sizes.large}px ${sizes.xLarge}px;
    `}
    transition: box-shadow 200ms;
`;

export const StyledModalTitle = styled(TypeRamp)`
    display: flex;
    align-items: center;
    margin: 0;
    flex: 1 1 auto;
    // To align the icon and text
    align-items: flex-start;
    min-width: 0; // to support eliding single-line headers
`;

export const StyledTitleIcon = styled("div")`
    margin-right: ${sizes.small}px;
    flex: 0 0 auto;
`;

interface TitleTextProps {
    type: ModalType;
    isHeadingMultiline?: boolean;
}

function omitTitleTextProps(propName: string) {
    switch (propName) {
        case "isHeadingMultiline":
        case "type":
            return false;
        default:
            return true;
    }
}

export const StyledTitleText = styled("span", {
    shouldForwardProp: omitTitleTextProps,
})<TitleTextProps>`
    flex: 1 1 auto;
    min-width: 0;
    word-wrap: break-word;
    ${(props) =>
        !props.isHeadingMultiline &&
        `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

    ${({ type }) => {
        switch (type) {
            case "simple":
                return `
                    margin-top: 3px;
                `;
        }
    }}
`;
