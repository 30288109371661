import React from "react";
import { components as rsComponents } from "react-select";
import { useLayoutContext } from "./LayoutContext";

export const MenuPortal: typeof rsComponents.MenuPortal = ({
    children,
    ...props
}) => {
    // Just by using this context here, we'll re-render this component when the
    // context value changes. We don't actually need to use anything here,
    // just force a re-render to reposition the portaled menu.
    useLayoutContext();
    return (
        <rsComponents.MenuPortal {...props}>{children}</rsComponents.MenuPortal>
    );
};
