import { css } from "@emotion/core";
import styled from "@emotion/styled";
import * as colors from "../../constants/colors";
import { ThemeMode } from "../../util/theme";

export const BORDER_WIDTH = 2;

const outerContainerCommon = css`
    display: flex;
    align-items: center;
`;

export const BoxStyledOuterContainer = styled.div<{
    borderColor: string;
    isDisabled: boolean;
    themeMode: ThemeMode;
}>`
    ${outerContainerCommon};
    border: ${(props) => (props.themeMode === "oldestar" ? 1 : 2)}px solid
        ${(props) => props.borderColor};
    border-radius: ${(props) => (props.themeMode === "oldestar" ? 2 : 4)}px;
    background-color: ${({ isDisabled }) =>
        isDisabled ? colors.neutralLight30 : "transparent"};
`;

export const UnderlineStyledOuterContainer = styled.div<{
    borderColor: string;
    isDisabled: boolean;
    themeMode: ThemeMode;
}>`
    ${outerContainerCommon};
    border: ${(props) => (props.themeMode === "oldestar" ? 1 : 2)}px solid
        transparent;
    border-bottom: ${(props) => (props.themeMode === "oldestar" ? 1 : 2)}px
        solid ${(props) => props.borderColor};
    border-radius: ${(props) => (props.themeMode === "oldestar" ? 2 : 4)}px;
    background-color: ${({ isDisabled }) =>
        isDisabled ? colors.neutralLight30 : "transparent"};
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0;
`;

// not using sizing constant due to the 2px border (would be 12/sizes.small)
export const StyledContentBeforeContainer = styled.div`
    padding-left: 10px;
    display: flex;
`;

export const StyledInputContainer = styled.div`
    flex: 1;
`;

// not using sizing constant due to the 2px border (would be 8/sizes.xSmall)
export const StyledContentAfterContainer = styled.div`
    padding-right: 6px;
    display: flex;
`;

export const StyledInput = styled.input<{
    isHovered: boolean;
    themeMode: ThemeMode;
    variant: "box" | "underline";
}>`
    font-size: ${(props) => (props.variant === "underline" ? "15px" : "13px")};
    font-family: inherit;
    font-weight: ${(props) => (props.themeMode === "oldestar" ? 400 : 500)};
    margin: 0;
    padding: 9px 10px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    color: ${colors.neutralDark40};
    line-height: 18px;
    border-style: none;
    border-radius: ${(props) => (props.themeMode === "oldestar" ? 2 : 4)}px;
    &[aria-disabled="true"] {
        background-color: ${colors.neutralLight30};
        color: ${colors.neutralDark10};
    }

    &::placeholder {
        color: ${colors.neutralDark20};
    }

    ${({ isHovered }) =>
        isHovered
            ? `&::placeholder {
                color: ${colors.neutralDark30};
            }`
            : ""}
`;
