import { Omit } from "utility-types";
import { useFlyout, UseFlyoutOptions, UseFlyoutResult } from "../flyout";
import { useDelayedOpen } from "../flyout/hooks/useDelayedOpen";
import { tooltipFlyoutDefaults } from "./tooltipConstants";

export type UseTooltipOptions<TargetElement> = Omit<
    UseFlyoutOptions<TargetElement>,
    "isOpen" | "onCloseRequested"
> & {
    /**
     * If specified, will adjust how quickly in ms the tooltip opens after mouse enters on button.
     * Default is 500ms
     */
    enterDelay?: number;

    /**
     * If specified, will be composed into the internally-used `onBlur` prop and spread
     * into the `targetProps` key on `UseTooltipResult`;
     */
    onBlur?: React.FocusEventHandler<TargetElement>;

    /**
     * If specified, will be composed into the internally-used `onFocus` prop and spread
     * into the `targetProps` key on `UseTooltipResult`;
     */
    onFocus?: React.FocusEventHandler<TargetElement>;

    /**
     * If specified, will be composed into the internally-used `onMouseEnter` prop and spread
     * into the `targetProps` key on `UseTooltipResult`;
     */
    onMouseEnter?: React.MouseEventHandler<TargetElement>;

    /**
     * If specified, will be composed into the internally-used `onMouseLeave` prop and spread
     * into the `targetProps` key on `UseTooltipResult`;
     */
    onMouseLeave?: React.MouseEventHandler<TargetElement>;
};

export type UseTooltipResult<TargetElement> = Omit<
    UseFlyoutResult<TargetElement>,
    "flyoutProps" | "targetProps"
> & {
    targetProps: Omit<
        UseFlyoutResult<TargetElement>["targetProps"],
        "aria-owns"
    > & {
        /**
         * Handler used to manage hover state on the target.
         */
        onMouseEnter: React.MouseEventHandler<TargetElement>;

        /**
         * Handler used to manage hover state on the target.
         */
        onMouseLeave: React.MouseEventHandler<TargetElement>;

        /**
         * This will be an id to match the target and tooltip.
         * If you provide an id, the Tooltip will use that.
         *
         * Only provided when the Tooltip is open, otherwise undefined.
         */
        "aria-describedby"?: string;

        /**
         * Handler used to manage focus state on the target
         */
        onBlur?: React.FocusEventHandler<TargetElement>;

        /**
         * Handler used to manage focus state on the target
         */
        onFocus?: React.FocusEventHandler<TargetElement>;
    };
    // Just aliasing this property for readability.
    tooltipProps: UseFlyoutResult<TargetElement>["flyoutProps"];
};

/**
 * Provides a convenience wrapper over the `useFlyout` hook with reasonable
 * defaults for a Tooltip.
 *
 * If `isOpen` is provided, we'll use it. Otherwise, set up some functionality to manage it internally.
 * This is an example of controlled/uncontrolled behavior that usually we try and stay away
 * from in lodestar-core, but Tooltip is an example of where the most common use-case will be
 * uncontrolled. We still want to provide our users with an escape hatch.
 */
export const useTooltip = <
    TargetElementType extends HTMLElement = HTMLButtonElement
>({
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    enterDelay = 500,
    ...rest
}: UseTooltipOptions<TargetElementType> = {}): UseTooltipResult<TargetElementType> => {
    const { isOpen, getDelayedOpenTargetProps } =
        useDelayedOpen<TargetElementType>({
            enterDelay,
        });

    const {
        flyoutProps,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        targetProps: { "aria-owns": _0, ...restTargetProps },
    } = useFlyout({
        // These defaults are also specified in the Tooltip component, but repeated here in case
        // someone wants to manually set up a Tooltip-like Flyout.
        ...tooltipFlyoutDefaults,
        ...rest,
        isOpen: isOpen,
    });

    return {
        tooltipProps: flyoutProps,
        targetProps: {
            ...restTargetProps,
            "aria-describedby": flyoutProps.isOpen ? flyoutProps.id : undefined,
            ...getDelayedOpenTargetProps({
                onMouseEnter,
                onMouseLeave,
                onFocus,
                onBlur,
            }),
        },
    };
};
