import styled from "@emotion/styled";
import { DeleteIcon, SearchIcon } from "@smartsheet/lodestar-icons";
import React from "react";
import { forwardRef } from "react";
import { focus, neutralDark20, neutralDark30 } from "../../constants/colors";
import { warnOnce } from "../../util/devUtil";
import { Clickable, ClickableProps } from "../clickable";
import { TextInput, TextInputProps } from "./TextInput";

const StyledButton = styled.button<{
    isDisabled: boolean;
}>`
    border-style: none;
    background-color: transparent;
    padding: 0;
    height: 30px;
    width: 30px;
    cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
    // To force the height to be the children's height.
    display: inline-block;
    vertical-align: middle;

    &:focus {
        outline-color: ${focus};
    }
`;

const StyledSearchIcon = styled(SearchIcon)`
    // Otherwise the icon is inline-block which gives it a bit more than the specified height.
    display: block;

    cursor: text;
`;

const warnOnDeprecatedLeftIconButtonProps = warnOnce(
    "Please use the `contentBeforeButtonProps` prop for <TextInput/> instead of `leftIconButtonProps`. The `leftIconButtonProps` prop has been deprecated for better RTL better support."
);

// Reserving namespace for consistency.
export interface SearchInputProps extends TextInputProps {
    /**
     * Optional props to make the search icon clickable. By using this you must define an
     * aria-label as well to explain to users what clicking on the button will do.
     */
    contentBeforeButtonProps?: Omit<ClickableProps, "children">;
    /**
     * @deprecated `contentBeforeButtonProps` should be used instead of `leftIconButtonProps`.
     */
    leftIconButtonProps?: Omit<ClickableProps, "children">;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
    (props, parentRef) => {
        // TODO: remove this after deprecation in TextInput, use just the `isDisabled` prop.
        const reallyDisabled =
            props.isDisabled !== undefined
                ? props.isDisabled
                : props.disabled
                ? props.disabled
                : false;

        // TODO: Remove this in https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/516
        const { contentBeforeButtonProps, leftIconButtonProps } = props;
        const realContentBeforeButtonProps =
            contentBeforeButtonProps !== undefined
                ? contentBeforeButtonProps
                : leftIconButtonProps;

        if (process.env.NODE_ENV !== "production") {
            if (leftIconButtonProps !== undefined) {
                warnOnDeprecatedLeftIconButtonProps();
            }
        }

        return (
            <TextInput
                contentBefore={({ isHovered }) => {
                    const icon = (
                        <StyledSearchIcon
                            size="small"
                            color={isHovered ? neutralDark30 : neutralDark20}
                        />
                    );

                    if (realContentBeforeButtonProps !== undefined) {
                        return (
                            <Clickable {...realContentBeforeButtonProps}>
                                {icon}
                            </Clickable>
                        );
                    } else {
                        return icon;
                    }
                }}
                contentAfter={
                    (props.value &&
                        (({ isHovered }) => (
                            <StyledButton
                                isDisabled={reallyDisabled}
                                onClick={
                                    reallyDisabled
                                        ? undefined
                                        : (e) => props.onChange("", e)
                                }
                                aria-label="clear"
                                type="button"
                            >
                                <DeleteIcon
                                    size="small"
                                    color={
                                        isHovered
                                            ? neutralDark30
                                            : neutralDark20
                                    }
                                />
                            </StyledButton>
                        ))) ||
                    null
                }
                ref={parentRef}
                {...props}
            />
        );
    }
);

SearchInput.displayName = "SearchInput";
