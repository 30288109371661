import { gridSize } from "../../../constants/grid";
import { sizes } from "../../../constants/spacing";

const dimensions = {
    large: {
        height: gridSize * 6,
        width: gridSize * 12,
        handle: gridSize * 6 - sizes.xSmall,
    },
    medium: {
        height: gridSize * 4,
        width: gridSize * 8,
        handle: gridSize * 4 - sizes.xxSmall - sizes.xxSmall / 2,
    },
};

export type ToggleSize = "medium" | "large";

export const getHeight = (size: ToggleSize) => dimensions[size].height;
export const getWidth = (size: ToggleSize) => dimensions[size].width;
export const getHandleSize = (size: ToggleSize) => dimensions[size].handle;

export const transition = "0.2s";
