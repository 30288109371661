/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgEdit: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M20.513 6.168c.005-.11-.049-.187-.12-.258l-2.297-2.297c-.175-.175-.343-.174-.52.002-.551.552-1.102 1.105-1.656 1.654-.064.063-.063.097 0 .16.623.618 1.242 1.239 1.862 1.86.268.267.537.533.802.803.052.053.086.054.135 0 .056-.064.119-.122.18-.182l1.493-1.493c.071-.071.127-.147.12-.249M4.879 20.513c.417 0 .834-.001 1.25.001a.381.381 0 00.29-.12c3.702-3.704 7.404-7.407 11.107-11.108.059-.059.064-.093.002-.154a833.242 833.242 0 01-2.658-2.66c-.06-.06-.094-.058-.153.002l-11.1 11.103a.4.4 0 00-.129.305c.003.84.003 1.679 0 2.518 0 .09.026.116.115.115.425-.004.85-.002 1.276-.002M4.497 22H2.77c-.45-.001-.769-.32-.77-.773 0-1.107.004-2.213-.001-3.32a1.876 1.876 0 01.575-1.39c2.116-2.114 4.23-4.23 6.345-6.345l5.206-5.206c.797-.797 1.595-1.593 2.39-2.392.313-.314.681-.516 1.126-.563.52-.054.986.087 1.375.437.412.37.79.779 1.183 1.17.409.405.814.813 1.221 1.22.31.307.514.667.565 1.106.062.538-.079 1.018-.446 1.414-.35.379-.725.736-1.09 1.1-4.319 4.32-8.639 8.64-12.956 12.96-.314.316-.68.515-1.122.572-.07.009-.14.01-.211.01H4.497"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
