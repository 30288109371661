import { shadowMenu, shadowModal } from "./colors";
import { gridSize } from "./grid";

/**
 * These constants are used to express the different layers of the application.
 * They map to z-index.
 *
 * These layers are intended to be a deterministic order for elements that float
 * above the rest of the application. These kinds of elements should normally be
 * attached near the DOM's body element, either in the root div of the app or
 * whichever element is the parent for the normal app surface and floating elements.
 *
 * We're leaving a LOT of space between these to ensure we have room to mutate.
 *
 * To use these, apply their values as the z-index of whatever element you want to lift up.
 * If possible, don't add/subtract from them. In many cases, DOM order will take care of the
 * stacking i.e. a modal w/ z-index 600 that renders a Flyout w/ z-index 600 will be in
 * the correct order since the Flyout is added after the Modal.
 */
export const layers = {
    /**
     * This is applied to the base layer of the application. This means that it will
     * never interact with any other floating elements.
     */
    surface: 0,

    /**
     * This is for the left/right panels. The rails and the panels should both be contained
     * within this layer, and the proper sub-z-indexes should be appled to ensure the panels
     * slide behind the rails i.e. 200 on the rail/panel container, 2 on the rail itself, 1
     * on the panel itself.
     */
    panel: 100,

    /**
     * This is just for the modal's blanket
     *
     * TODO it would be nice to get rid of this. We don't need to formalize this layer. It should
     * just be modal-1.
     */
    blanket: 300,

    /**
     * This is for flyouts that operate just above the surface layer.
     */
    flyout: 490,

    /**
     * This is just for the toast layer which needs to be behind the modals, but on top of
     * flyouts.
     */
    toast: 540,

    /**
     * For modals and any of their floating children.
     */
    modal: 600,

    /**
     * Originally for tooltips above Modals, but it doesn't actually make much sense.
     */
    // TODO: not used by many: https://git.lab.smartsheet.com/search?repository_ref=mainline&scope=blobs&search=layers.context&snippets=false
    context: 900,
} as const;

export const shadows = {
    modal: () => `
        0 ${gridSize * 6}px ${gridSize * 16}px ${shadowModal}
    `,
    context: () => `
        0 ${gridSize * 0.5}px ${gridSize * 3}px ${shadowMenu}
    `,
    notifications: () => `
       0 ${gridSize * 12}px ${gridSize * 8}px rgba(128, 127, 127, 0.24)
    `,
};
