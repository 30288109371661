import styled from "@emotion/styled";
import { neutralLight30, tooltipBackground } from "../../../constants/colors";

export interface TooltipContainerProps {
    squareCorner?:
        | "borderTopLeft"
        | "borderTopRight"
        | "borderBottomLeft"
        | "borderBottomRight";
}

export const TooltipContainer = styled.div<TooltipContainerProps>(
    `
    background-color: ${tooltipBackground};
    border-radius: 2px;
    color: ${neutralLight30};
    max-width: 256px;
    // Allow breaking inside words so the max-width will work even when a single word is > 256px wide.
    overflow-wrap: break-word;

    font-size: 12px;
    line-height: 18px;
`,
    ({ squareCorner }) =>
        (squareCorner && { [`${squareCorner}Radius`]: 0 }) || ""
);

export const TooltipBody = styled.div`
    padding: 3px 8px;

    // These are set to ensure the arrow calculations don't create an infinite loop due. See #468.
    min-height: 8px;
    min-width: 8px;
`;
