/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgSyncLeft: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                fillRule="nonzero"
                d="M2.215 11.48l4.127-4.254c.39-.402 1.029-.243 1.175.288.078.286.002.532-.198.74l-2.795 2.882-.11.12c.066.03.12.019.173.019h16.629c.136 0 .268.013.391.079a.726.726 0 01.376.806.717.717 0 01-.65.56l-.083.003H4.59c-.053 0-.108-.015-.16.015.011.059.058.086.092.121l2.804 2.893c.396.416.232 1.079-.302 1.222-.27.073-.503-.012-.7-.217l-3.348-3.454-.76-.784a.732.732 0 010-1.039z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
