import styled from "@emotion/styled";

export const Input = styled.input`
    opacity: 0;
    width: 48px;
    height: 24px;
    position: absolute;

    // UA reset to ensure the virtual cursor is properly positioned for screen readers.
    margin: 0;
    padding: 0;

    &:focus {
        outline: none !important;
    }
`;
