export type IconSizeTypes =
    | "xxSmall"
    | "xSmall"
    | "small"
    | "medium"
    | "large"
    | "xLarge"
    | "xxLarge";

export const iconSizes: Record<IconSizeTypes, string> = {
    xxSmall: "14px",
    xSmall: "16px",
    small: "20px",
    medium: "24px",
    large: "32px",
    xLarge: "48px",
    xxLarge: "64px",
};
