import { ClassNames } from "@emotion/core";
import { BasePlacement } from "@popperjs/core";
import React, { forwardRef, ReactNode } from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import {
    TooltipContainer,
    TooltipContainerProps,
} from "../styled/BasicTooltip";
import { ANIMATION_DURATION } from "../tooltipConstants";

const slideTransition = `transform ${ANIMATION_DURATION}ms cubic-bezier(0.23, 1, 0.32, 1)`;
const opacityTransition = `opacity ${ANIMATION_DURATION / 2}ms`;

const slideTransitionStyles = {
    top: {
        transform: "translateY(4px)",
    },
    bottom: {
        transform: "translateY(-4px)",
    },
    left: {
        transform: "translateX(4px)",
    },
    right: {
        transform: "translateX(-4px)",
    },
};

export interface TooltipFlyoutContainerProps extends TooltipContainerProps {
    /**
     * The children of this component
     */
    children: ReactNode;

    /**
     * The actual (not necessarily the requested) direction the Tooltip is placed
     * relative to its target.
     */
    enterDirection: BasePlacement | "auto";

    /**
     * For transitions, we need to wait until the Tooltip is positioned initially,
     * then we can enable the transition. Otherwise it will start from the wrong position.
     *
     */
    showTransition: boolean;

    transitionStatus: TransitionStatus;
}

export const TooltipFlyoutContainer = forwardRef<
    HTMLDivElement,
    TooltipFlyoutContainerProps
>(
    (
        {
            children,
            enterDirection,
            showTransition,
            transitionStatus,
            ...props
        },
        ref
    ) => {
        return (
            <ClassNames>
                {({ css }) => (
                    <TooltipContainer
                        {...props}
                        ref={ref}
                        className={css({
                            opacity: transitionStatus === "entered" ? 1 : 0,
                            transform:
                                transitionStatus === "entering" &&
                                enterDirection !== "auto"
                                    ? slideTransitionStyles[enterDirection]
                                          .transform
                                    : "translateY(0) translateX(0)",
                            // Always show opacity, slide transition nees to happen AFTER the first popper update.
                            transition: `${opacityTransition}, ${
                                showTransition ? slideTransition : ""
                            }`,
                        })}
                    >
                        {children}
                    </TooltipContainer>
                )}
            </ClassNames>
        );
    }
);
TooltipFlyoutContainer.displayName = "TooltipFlyoutContainer";
