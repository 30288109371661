import { createContext, useContext } from "react";
import { Logger } from "./LoggingStateService";

const defaultLogFn = (): null => null;

/**
Context for providing logging functionality to React components. 
The value must implement the {@link Logger} interface
*/
export const LoggerContext = createContext<Logger>({
    warn: defaultLogFn,
    error: defaultLogFn,
    log: defaultLogFn,
    logClientEvent: defaultLogFn,
    logEventWithoutPrivateInfo: defaultLogFn
});

/**
 * Hook to gain access to a logger provided via React's Context Api.
 */
export const useLogger = (): Logger => useContext(LoggerContext);
