import { SpinnerSizes } from "./types";

export const SPINNER_CONTAINER_SIZES_MAP: {
    [key in SpinnerSizes]: number;
} = {
    small: 10,
    medium: 16,
    xLarge: 28,
};

export const SPINNER_DOT_SIZES_MAP: {
    [key in SpinnerSizes]: number;
} = {
    small: 3,
    medium: 4,
    xLarge: 7,
};
