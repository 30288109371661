import { IconSizeTypes } from "@smartsheet/lodestar-icons";
import React, {
    FC,
    useCallback,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { uid } from "react-uid";
import { neutralLight40 } from "../../constants/colors";
import { LodestarCoreTheme } from "../../util/theme";
import { useActive } from "../../util/useActive";
import { Handle } from "./styled/Handle";
import { Input } from "./styled/Input";
import { Label } from "./styled/Label";
import { LabelText } from "./styled/LabelText";
import { Slide } from "./styled/Slide";
import { Spacer } from "./styled/Spacer";
import { ToggleOffIcon } from "./styled/ToggleOffIcon";
import { ToggleOnIcon } from "./styled/ToggleOnIcon";
import { ToggleSize } from "./styled/toggleStyleConstants";
import { LabelPlacement } from "./types";

export interface ToggleProps {
    /** Whether the toggle is checked or not */
    isChecked: boolean;

    /** Handler to be called when native 'change' event happens internally. */
    onClick: React.MouseEventHandler<HTMLElement>;

    /**
     * Identifies the element (or elements) that describes the object.
     */
    "aria-describedby"?: string;

    /**
     * Defines a string value that labels the current element.
     */
    "aria-label"?: string;

    /**
     * Identifies the element (or elements) that labels the current element.
     */
    "aria-labelledby"?: string;

    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;

    /**
     * A `clientId` prop is provided for specified elements, which is a unique string that appears
     * as a data attribute `data-client-id` in the rendered code, serving as a hook for automated tests.
     * we have added 2 ids:
     *
     * - `clientId` that targets the Label component to interact with the component.
     * - `{clientId}--input` to check if the toggle has been checked/unchecked.
     */
    clientId?: string;

    /**
     * Flag to set the disabled state ot the tab.
     *
     * **deprecated** use isDisabled instead
     * @deprecated use isDisabled instead
     */
    disabled?: boolean;

    /**
     * An id to apply to the root element of the Toggle.
     */
    id?: string;

    /** Whether the toggle is disabled or not. This will prevent any interaction with the user */
    isDisabled?: boolean;

    /** Whether the toggle is invalid or not. */
    isInvalid?: boolean;

    /** Label to be set for accessibility */
    label?: React.ReactNode;

    /** Display label before or after the toggle. */
    labelPlacement?: LabelPlacement;

    /** Descriptive name for value property to be submitted in a form */
    name?: string;

    /** Handler to be called when toggle is unfocused */
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

    /** Handler to be called when toggle is focused. */
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;

    /**
     * Size of the component, defaults to large.
     * If set to large, it will take 24 by 48 pixels wide.
     * If set to medium, it will take 16 by 32 pixels wide.
     */
    size?: ToggleSize;

    /**
     * A value for the Toggle's tabindex. Defaults to 0.
     *
     * Please only ever set this to -1 or 0;
     */
    tabIndex?: number;

    /** The value to be submitted in a form. */
    value?: string;
}

const iconSizes: {
    large: IconSizeTypes;
    medium: IconSizeTypes;
} = {
    large: "medium",
    medium: "xSmall",
};

/**
 * This toggle does all your toggling.
 */
export const Toggle: FC<ToggleProps> = ({
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledby,
    "aria-describedby": ariaDescribedby,
    isChecked,
    onClick,
    className,
    clientId,
    id,
    disabled,
    isDisabled = false,
    isInvalid = false,
    label = "",
    labelPlacement = "end",
    name,
    onBlur = () => {},
    onFocus = () => {},
    tabIndex = 0,
    value,
    size = "large",
}: ToggleProps) => {
    /**
     * TODO: remove this in a future release with this ticket:
     * https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/466
     */
    isDisabled = isDisabled ? isDisabled : disabled ? disabled : false;
    if (process.env.NODE_ENV !== "production") {
        if (disabled !== undefined) {
            console.warn(
                "The 'disabled' prop has been deprecated in favor of 'isDisabled'. Please use that instead."
            );
        }
    }

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const setHoveredTrue = useCallback(() => setIsHovered(true), []);
    const setHoveredFalse = useCallback(() => setIsHovered(false), []);

    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        setIsFocused(false);
        onBlur(event);
    };

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (event) => {
        setIsFocused(true);
        onFocus(event);
    };

    const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
        if (isDisabled) {
            return;
        }
        // The default action of a label around an input is to re-trigger the click event on the
        // input element. We need to ignore the first event on the label here or we'll call this
        // handler twice since the event will be re-fired on the input as well.
        // We could preventDefault in the first event, but for screen-reader support we must
        // have the event on the input element be handled, or the SR won't say "Space, on".
        // It would just say "Space" and not provide the new state, or say "off" all the time??!
        if (label && event.target !== inputRef.current) {
            event.stopPropagation();
            return;
        }

        onClick(event);
    };

    const { isActive, ...isActiveHandlers } = useActive<HTMLDivElement>({
        onMouseEnter: setHoveredTrue,
        onMouseLeave: setHoveredFalse,
    });

    const theme = useContext(LodestarCoreTheme);

    const defaultId = useMemo(() => uid({ id: "Toggle" }), []);

    const idToUse = id || defaultId;
    const labelId = `${idToUse}-label`;

    // Allow multiple labels to be applied.
    // If supplied, the prop aria-labelledby will be applied first, as it will
    // be read first, followed by the more closely scoped local label.
    const finalLabelledBy =
        [ariaLabelledby, (label && labelId) || false]
            .filter(Boolean)
            .join(" ") || undefined;

    const labelText = label ? (
        <LabelText size={size} id={labelId} isDisabled={isDisabled}>
            {label}
        </LabelText>
    ) : undefined;

    // If there's no text label prop provided, this component will fail
    // some a11y scanners due to there being an empty <label>. This happens
    // even when an aria-label is provided anyways since that is stuck on
    // the inner input and the label is still empty.
    // If this is the case, we'll just use a div here.
    const Root = useMemo(
        () => (label ? Label : Label.withComponent("div")),
        [label]
    );

    return (
        <Root
            data-client-id={clientId}
            className={className}
            onClick={handleClick}
        >
            <Spacer orientation="row" space="small">
                {label && labelPlacement === "start" && labelText}
                <Slide
                    isActive={isActive}
                    isChecked={isChecked}
                    isDisabled={isDisabled}
                    isFocused={isFocused}
                    isInvalid={isInvalid}
                    themeMode={theme}
                    size={size}
                    {...isActiveHandlers}
                >
                    <Input
                        aria-label={ariaLabel}
                        aria-labelledby={finalLabelledBy}
                        aria-describedby={ariaDescribedby}
                        aria-disabled={isDisabled}
                        aria-readonly={isDisabled}
                        // TODO: remove this in a future release with this ticket:
                        // https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/466
                        disabled={disabled}
                        id={idToUse}
                        name={name}
                        onBlur={handleBlur}
                        onChange={noopChangeHandler}
                        onFocus={handleFocus}
                        role="switch"
                        type="checkbox"
                        aria-checked={isChecked}
                        {...(value !== undefined && { value })}
                        data-client-id={clientId && `${clientId}--input`}
                        ref={inputRef}
                        tabIndex={tabIndex}
                    />
                    <ToggleOnIcon
                        visible={isChecked}
                        color={neutralLight40}
                        size={iconSizes[size]}
                        toggleSize={size}
                    />
                    <ToggleOffIcon
                        visible={!isChecked}
                        color={neutralLight40}
                        size={iconSizes[size]}
                        toggleSize={size}
                    />
                    <Handle
                        isChecked={isChecked}
                        isDisabled={isDisabled}
                        isHovered={isHovered}
                        size={size}
                    />
                </Slide>
                {label && labelPlacement === "end" && labelText}
            </Spacer>
        </Root>
    );
};

const noopChangeHandler = () => {
    // This is provided to the onChange handler of the input. It's only
    // here because React throws out a warning if you don't include it AND the value prop.
    // However, we do something special with the onClick event in the label
    // so we're all good.
    // Quiet please, React. ty.
};
