/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { components, Props } from "react-select";
import * as colors from "../../../constants/colors";
import { OptionType } from "../types";

/**
 * Returns true if there is at least one option selected
 */
const isOptionSelected = (selectProps: Props<OptionType>) => {
    const selectedValue = selectProps.value;
    // For multi-select, the value should always be an array
    if (selectProps.isMulti) {
        // At least one option is selected if the array length is positive
        return Array.isArray(selectedValue) && selectedValue.length > 0;
    }

    // For single select, a non-null value indicates an option is selected
    return selectedValue != null;
};

export const IndicatorSeparator: typeof components.IndicatorSeparator = ({
    children,
    ...props
}) => {
    if (
        props.selectProps.isClearable &&
        isOptionSelected(props.selectProps as Props<OptionType>)
    ) {
        return (
            <components.IndicatorSeparator
                {...props}
                css={css`
                    // If we add 1 px border around this we get a width of 2 and a height of 2 + <height of element>.
                    // Need to just add a single 2 px line so we'll use either border-left or right.
                    border-left: 2px solid ${colors.neutralLight10};
                    height: 24px;
                    // Remove the margins react-select adds.
                    margin: 0;
                    display: block;
                    align-self: unset;
                `}
            >
                {children}
            </components.IndicatorSeparator>
        );
    }
    return null;
};
