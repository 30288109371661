import React, { ReactNode, useMemo } from "react";
import { sizeNames } from "../../constants/spacing";
import { Spacer } from "../layout";
import { ValidRadioValue, Radio, RadioProps } from "./Radio";

export type RadioGroupOptionType = {
    /**
     * The text to display in the Radio's label.
     */
    label: ReactNode;

    /**
     * The value for the Radio button. Should be unique among Radio buttons sharing the same name.
     */
    value: ValidRadioValue;
};

/** Object with Radio component props that can be given overrides */
export type RadioPropsOverride = Partial<Pick<RadioProps, "id">>;

type Orientation = "horizontal" | "vertical";

export interface RadioGroupProps<OptionType extends RadioGroupOptionType> {
    /** Name of the radio group. */
    name: string;
    /** Function that gets after each change event */
    onChange: (
        option: OptionType,
        event: React.ChangeEvent<HTMLInputElement>
    ) => void;
    /** An array of objects, each object is mapped onto a Radio element within the group */
    options: OptionType[];
    /** Once set, controls the selected value on the Radio Group */
    selected: OptionType | null;
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
    /**
     * The `clientId` prop appears as a data attribute `data-client-id` on the root DOM node of this component.
     * This serves as a hook for automated tests, and should be a unique string.
     * To provide clientIds for individual radio buttons, see the `getClientId` prop.
     */
    clientId?: string;
    /** Sets the disabled state of all Radio elements in the group */
    disabledOptions?: OptionType[];
    /** Getter function to provide a client id for each Radio component. */
    getClientId?: (option: OptionType) => string;
    /**
     * Getter function to provide specific props for each Radio component, like the 'id' prop.
     * Eventually we'll want to fold more overrides in here like clientId, but for now it's
     * a small set of overrides.
     */
    getOptionProps?: (option: OptionType) => RadioPropsOverride;
    /** Sets the disabled state of all Radio elements in the group */
    isDisabled?: boolean;
    /** Sets the invalid state of all Radio elements in the group */
    isInvalid?: boolean;
    /** Sets the required state of all Radio elements in the group */
    isRequired?: boolean;
    /** Function that gets fired after each invalid event */
    onInvalid?: (
        option: OptionType,
        event: React.FormEvent<HTMLInputElement>
    ) => void;
    /** Which direction to lay out the Radio buttons. */
    orientation?: Orientation;
    /**
     * How much space to insert between each Radio button.
     * This is overrideable, but please ensure UX has asked for this override specifically as the
     * Lodestar design system has a set of well-defined list spacings defined.
     */
    spacing?: sizeNames;
}

export const RadioGroup = <OptionType extends RadioGroupOptionType>({
    className,
    clientId,
    disabledOptions,
    getClientId,
    getOptionProps,
    isDisabled,
    isInvalid,
    isRequired = false,
    name,
    onChange,
    onInvalid = () => undefined,
    options,
    orientation = "vertical",
    selected,
    spacing,
}: RadioGroupProps<OptionType>): JSX.Element => {
    const disabledOptionLookup = useMemo(() => {
        const lookup: { [val: string]: OptionType } = {};
        (disabledOptions || []).forEach((opt) => {
            lookup[opt.value] = opt;
        });
        return lookup;
    }, [disabledOptions]);

    const changeHandler = (
        option: OptionType,
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.ariaDisabled === "true") {
            e.preventDefault();
        } else {
            onChange(option, e);
        }
    };

    return (
        <Spacer
            space={spacing || getDefaultSpacing(orientation)}
            orientation={orientation === "vertical" ? "column" : "row"}
            className={className}
            clientId={clientId}
        >
            {options.map((option) => {
                const optionProps: RadioPropsOverride = getOptionProps
                    ? getOptionProps(option)
                    : {};
                return (
                    <Radio
                        name={name}
                        id={optionProps.id}
                        value={option.value}
                        label={option.label}
                        clientId={
                            (getClientId && getClientId(option)) || undefined
                        }
                        key={option.value}
                        onChange={(e) => changeHandler(option, e)}
                        onInvalid={(e) => onInvalid(option, e)}
                        isRequired={isRequired}
                        isInvalid={isInvalid}
                        isChecked={
                            selected !== null && option.value === selected.value
                        }
                        isDisabled={
                            isDisabled ||
                            disabledOptionLookup[option.value] !== undefined
                        }
                    />
                );
            })}
        </Spacer>
    );
};

function getDefaultSpacing(orientation: Orientation): sizeNames {
    if (orientation === "horizontal") {
        return "large";
    } else {
        return "small";
    }
}
