import {
    AllHTMLAttributes,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { callAllHandlers } from "../../../util/callAllHandlers";

export type UseDelayedOpenOptions = {
    /**
     * If set to "each", the Flyout will show after `enterDelayMs` ms on mouseEnter/focus and will
     * disappear after `exitDelayMs` on mouseExit/blur.
     *
     * If set to "batched", subsequent mouseEnter/focus within `exitDelayMs` ms will cause other flyouts
     * using this hook to show immediately, rather than waiting `enterDelayMs` ms again. This can be
     * used  to create a "flyout" or "help" mode where things show quickly if you're looking at
     * many  Flyout-based components.
     */
    delayMode?: "each" | "batch";

    /**
     * If specified, will adjust how quickly in ms the flyout opens after mouse enters on button.
     * Default is 500ms
     */
    enterDelay?: number;

    /**
     * If delayMode === "batch", you can set this to change the number of milliseconds this hook
     * will wait before opening another flyout after `enterDelayMs` milliseconds.
     */
    resetDelay?: number;
};

export interface UseDelayedOpenResult<TargetElement> {
    /**
     * Returns event handlers that should be spread onto the Flyout's target.
     * If you'd like to include your own, please pass it in here and it will be merged
     * into the respective handler.
     */
    getDelayedOpenTargetProps: (
        overrides?: AllHTMLAttributes<TargetElement> & {
            onBlur?: React.FocusEventHandler<TargetElement>;
            onFocus?: React.FocusEventHandler<TargetElement>;
            onMouseEnter?: React.MouseEventHandler<TargetElement>;
            onMouseLeave?: React.MouseEventHandler<TargetElement>;
        }
    ) => {
        onBlur: React.FocusEventHandler<TargetElement>;
        onFocus: React.FocusEventHandler<TargetElement>;
        onMouseEnter: React.MouseEventHandler<TargetElement>;
        onMouseLeave: React.MouseEventHandler<TargetElement>;
    };

    isOpen: boolean;

    /**
     * Will reset the internal isOpen state. Respects the given enter and exit delays.
     */
    setIsOpen: (newIsOpen: boolean) => void;
}

// Helps control delay when hovering back-to-back over items w/ flyouts.
// Hovering back-to-back when flyout is showing will ignore initial flyout delay.
let ignoreEnterDelay = false;
let ignoreEnterDelayTimer: number;

// We don't want to allow tests to interact with each other. Sometimes if a Flyout doesn't
// go away quickly enough (tooltips, for instance) the next test will render one before
// these flags are reset. We always want to reset these between tests. See jest.setup.ts.
let resetUseDelayedOptionForTest = (): void => undefined;
if (process.env.NODE_ENV !== "production") {
    // Don't ship this code in production.
    resetUseDelayedOptionForTest = () => {
        ignoreEnterDelay = false;
        clearTimeout(ignoreEnterDelayTimer);
    };
}
export { resetUseDelayedOptionForTest };

/**
 * Provides a convenience wrapper over the `useFlyout` hook with reasonable
 * defaults for a Flyout.
 *
 * If `isOpen` is provided, we'll use it. Otherwise, set up some functionality to manage it internally.
 * This is an example of controlled/uncontrolled behavior that usually we try and stay away
 * from in lodestar-core, but Flyout is an example of where the most common use-case will be
 * uncontrolled. We still want to provide our users with an escape hatch.
 */
export const useDelayedOpen = <
    TargetElementType extends HTMLElement = HTMLButtonElement
>({
    enterDelay = 500,
    resetDelay = 500,
    delayMode = "batch",
}: UseDelayedOpenOptions = {}): UseDelayedOpenResult<TargetElementType> => {
    const [isOpen, setIsOpen] = useState(false);

    const enterTimer = useRef<number | undefined>();

    const handleOpen = useCallback(() => {
        window.clearTimeout(ignoreEnterDelayTimer);

        if (delayMode === "batch") {
            ignoreEnterDelay = true;
        }

        setIsOpen(true);
    }, [delayMode]);

    const handleClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const maybeDelayedOpen = useCallback(() => {
        // This ensures that the flyout doesn't open after you have quickly moved away from element.
        window.clearTimeout(enterTimer.current);

        if (delayMode === "each" || !ignoreEnterDelay) {
            enterTimer.current = window.setTimeout(() => {
                handleOpen();
            }, enterDelay);
        } else {
            handleOpen();
        }
    }, [delayMode, enterDelay, handleOpen]);

    const maybeDelayedClose = useCallback(() => {
        // this ensures that the flyout doesnt open after you have quickly moved away from element.
        window.clearTimeout(enterTimer.current);

        window.clearTimeout(ignoreEnterDelayTimer);
        if (delayMode === "batch") {
            ignoreEnterDelayTimer = window.setTimeout(() => {
                ignoreEnterDelay = false;
            }, resetDelay);
        }

        handleClose();
    }, [handleClose, delayMode, resetDelay]);

    // Clean up timers when unmounted.
    useEffect(() => {
        return () => {
            window.clearTimeout(enterTimer.current);
            window.clearTimeout(ignoreEnterDelayTimer);
        };
    }, []);

    return {
        setIsOpen: useCallback(
            (newIsOpen: boolean) =>
                newIsOpen ? maybeDelayedOpen() : maybeDelayedClose(),
            [maybeDelayedOpen, maybeDelayedClose]
        ),
        isOpen,
        getDelayedOpenTargetProps: ({
            onMouseEnter,
            onMouseLeave,
            onFocus,
            onBlur,
            ...restOverrides
        } = {}) => ({
            ...restOverrides,
            onMouseEnter: callAllHandlers(onMouseEnter, maybeDelayedOpen),
            onMouseLeave: callAllHandlers(onMouseLeave, maybeDelayedClose),
            onFocus: callAllHandlers(onFocus, maybeDelayedOpen),
            onBlur: callAllHandlers(onBlur, maybeDelayedClose),
        }),
    };
};
