import { CSSObject } from "@emotion/core";
import { borderRadius } from "../../constants/borders";
import * as colors from "../../constants/colors";
import { sizes } from "../../constants/spacing";
import { ThemeMode, themed } from "../../util/theme";
import { FocusMode } from "../../util/useFocusMode";
import { BUTTON_STYLE_PROPERTIES_BY_SIZE_MAP } from "./constants";
import { ButtonAppearances, StyleProps } from "./types";

const getCursor = ({ state }: StyleProps) =>
    state.isDisabled ? "not-allowed" : "pointer";

const getWidth = ({ shouldFitContainer }: StyleProps) =>
    shouldFitContainer ? "100%" : undefined;
const getHeight = ({ size, appearance }: StyleProps) => {
    return appearance !== "link"
        ? `${BUTTON_STYLE_PROPERTIES_BY_SIZE_MAP[size].height}px;`
        : "auto";
};

const getMinWidth = ({ hasNoIcons, appearance }: StyleProps) =>
    // Only non-borderless buttons with no icons have a min width
    hasNoIcons && appearance !== "borderless" && appearance !== "link"
        ? "96px"
        : undefined;

const getPadding = ({ hasOnlyIcons, hasNoIcons, appearance }: StyleProps) => {
    // If there's only icons, don't add any padding
    if (hasOnlyIcons || appearance === "link") {
        // 0 is important; some browsers have a user agent style with padding that we don't want
        return "0";
    }

    return hasNoIcons && appearance !== "borderless"
        ? `0 ${sizes.large}px` // Add large padding for text-only buttons
        : `0 ${sizes.xxSmall}px`; // Add xxSmall padding if it's a mixed or borderless button
};

export const buttonDestructionResting = "#cc2e3b";

// In app-core (oldestar theme), Buttons have an opacity of 0.3 applied to them when they are
// disabled. We've used gpick to approximate this for the oldestar theme w/o an opacity filter.
const backgroundColors = themed({
    primary: {
        rest: {
            background: {
                light: colors.buttonPrimary,
                oldestar: "#0073ec",
            },
        },
        focus: {
            background: {
                light: colors.buttonPrimaryFocus,
                oldestar: "#0073ec",
            },
        },
        hover: {
            background: {
                light: colors.buttonPrimaryHover,
                oldestar: "#005cbc",
            },
        },
        active: {
            background: {
                light: colors.buttonPrimaryPressed,
                oldestar: "#0050a5",
            },
        },
        disabled: {
            background: {
                light: colors.buttonPrimaryDisabled,
                oldestar: "#a9ccf0",
            },
        },
    },
    secondary: {
        rest: {
            background: {
                light: colors.buttonDefault,
                oldestar: "#888888",
            },
        },
        focus: {
            background: {
                light: colors.buttonDefaultFocus,
                oldestar: "#888888",
            },
        },
        hover: {
            background: {
                light: colors.buttonDefaultHover,
                oldestar: "#7d7d7d",
            },
        },
        active: {
            background: {
                light: colors.buttonDefaultPressed,
                oldestar: "#777777",
            },
        },
        disabled: {
            background: {
                light: colors.buttonDefaultDisabled,
                oldestar: "#d2d2d2",
            },
        },
    },
    destructive: {
        rest: {
            background: colors.buttonDestructive,
        },
        focus: {
            background: colors.buttonDestructiveFocus,
        },
        hover: {
            background: colors.buttonDestructiveHover,
        },
        active: {
            background: colors.buttonDestructivePressed,
        },
        disabled: {
            background: colors.buttonDestructiveDisabled,
        },
    },
    "secondary-destructive": {
        rest: {
            background: colors.buttonDefault,
        },
        focus: {
            background: colors.buttonDefaultFocus,
        },
        hover: {
            background: colors.buttonDefaultHover,
        },
        active: {
            background: colors.buttonDefaultPressed,
        },
        disabled: {
            background: colors.buttonDefaultDisabled,
        },
    },
    share: {
        rest: {
            background: colors.ctaShare,
        },
        focus: {
            background: colors.ctaShareFocus,
        },
        hover: {
            background: colors.ctaShareHover,
        },
        active: {
            background: colors.ctaShareActive,
        },
        disabled: {
            background: colors.ctaShareDisabled,
        },
    },
    borderless: {
        rest: {
            background: "transparent",
        },
        hover: {
            background: colors.buttonDefault,
        },
        active: {
            background: colors.buttonDefaultHover,
        },
        focus: {
            background: colors.buttonDefault,
        },
        disabled: {
            background: "transparent",
        },
    },
    link: {
        rest: {
            background: "transparent",
        },
        hover: {
            background: "transparent",
        },
        active: {
            background: "transparent",
        },
        focus: {
            background: "transparent",
        },
        disabled: {
            background: "transparent",
        },
    },
});

const fontSettings = themed({
    primary: {
        fontWeight: 700,
        color: {
            enabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            active: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            disabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
        },
    },
    secondary: {
        fontWeight: 600,
        color: {
            enabled: {
                light: colors.neutralDark30,
                oldestar: "#ffffff",
            },
            active: {
                light: colors.neutralDark30,
                oldestar: "#ffffff",
            },
            disabled: {
                light: colors.neutralAccessible,
                oldestar: "#ffffff",
            },
        },
    },
    destructive: {
        fontWeight: 700,
        color: {
            enabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            active: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            disabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
        },
    },
    "secondary-destructive": {
        fontWeight: 600,
        color: {
            enabled: colors.buttonDestructive,
            active: colors.buttonDestructive,
            disabled: colors.dangerLight20,
        },
    },
    share: {
        fontWeight: 700,
        color: {
            enabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            active: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
            disabled: {
                light: colors.neutralLight40,
                oldestar: "#ffffff",
            },
        },
    },
    borderless: {
        fontWeight: 600,
        color: {
            enabled: colors.ctaLink,
            active: colors.focusDark,
            disabled: colors.neutralDark10,
        },
    },
    link: {
        fontWeight: 400,
        color: {
            enabled: colors.ctaLink,
            active: colors.focusDark,
            disabled: colors.neutralDark10,
        },
    },
});

// TODO delete this function and all usages in #408
const getAppearanceMinusDeprecated = (
    appearance: ButtonAppearances | "link"
): Exclude<ButtonAppearances, "destructive-tertiary"> | "link" => {
    let actualAppearance = appearance;
    if (actualAppearance === "destructive-tertiary") {
        actualAppearance = "secondary-destructive";
    }
    return actualAppearance;
};

// For mapping state to the fontSettings color object above
const mapStateToFontSettingsString = ({
    isDisabled = false,
    isActive = false,
}) => {
    // Disabled should always win
    if (isDisabled) {
        return "disabled";
    }
    // Active should win over enabled
    if (isActive) {
        return "active";
    }
    return "enabled";
};

const getColor = (
    { appearance, state }: StyleProps,
    theme: ThemeMode
): string => {
    const resolvedState = mapStateToFontSettingsString(state);
    return fontSettings[getAppearanceMinusDeprecated(appearance)].color[
        resolvedState
    ](theme);
};

// For mapping state to the color object above
const mapStateToBackgroundString = (
    { isFocus = false, isDisabled = false, isActive = false, isHover = false },
    focusMode: FocusMode
) => {
    // Disabled should always win
    if (isDisabled) {
        return "disabled";
    }
    // Active should win over hover
    if (isActive) {
        return "active";
    }
    if (isFocus && focusMode === "keyboard") {
        return "focus";
    }
    if (isHover) {
        return "hover";
    }
    return "rest";
};

const getBackgroundColor = (
    { appearance, state, focusMode }: StyleProps,
    theme: ThemeMode
): string => {
    const resolvedState = mapStateToBackgroundString(state, focusMode);
    return backgroundColors[getAppearanceMinusDeprecated(appearance)][
        resolvedState
    ].background(theme);
};

const getFontSize = ({ size }: StyleProps): string => {
    return `${BUTTON_STYLE_PROPERTIES_BY_SIZE_MAP[size].fontSize}px;`;
};
const getFontWeight = (
    { appearance }: StyleProps,
    theme: ThemeMode
): number => {
    return fontSettings[getAppearanceMinusDeprecated(appearance)].fontWeight(
        theme
    );
};

const getTextDecoration = ({ state, renderAs }: StyleProps): string => {
    return renderAs === "a" &&
        !state.isDisabled &&
        (state.isActive || state.isHover)
        ? "underline"
        : "none";
};

const buttonResetStyles: CSSObject = {
    textTransform: "none", // Remove inheritance of text transform in Firefox
    overflow: "visible", // Show overflow in Edge
    margin: 0, // Remove margin in Firefox and Safari
    padding: 0, // Remove padding in webkit
    outline: "none", // Remove default focus outline in various browsers
    fontFamily: "inherit", // Ensure we inherit from parent since many browsers override this in user-agent stylesheet.

    "&::-moz-focus-inner": {
        border: 0,
        margin: 0,
        padding: 0,
    },
};

/**
 * BUTTON STYLES
 */
export const getButtonStyles = (
    props: StyleProps,
    theme: ThemeMode
): CSSObject => ({
    ...buttonResetStyles,
    display: "inline-flex",
    boxSizing: "border-box",
    alignItems: "center",
    height: getHeight(props),
    width: getWidth(props),
    minWidth: getMinWidth(props),
    maxWidth: "100%", // if the parent is smaller than the button, we want to truncate
    padding: getPadding(props),
    border: "1px solid transparent",
    textAlign: "center",
    borderRadius: `${borderRadius()}px`,
    backgroundColor: getBackgroundColor(props, theme),
    fontSize: getFontSize(props),
    fontWeight: getFontWeight(props, theme),
    lineHeight: `18px`,
    color: getColor(props, theme),
    whiteSpace: "nowrap",
    cursor: getCursor(props),
    textDecoration: getTextDecoration(props), // if the href prop is used, an anchor tag will be rendered instead
    position: "relative", // to anchor the focus "outline" element

    // Outline element
    ...(props.state.isFocus && {
        "&::before": {
            position: "absolute",
            top: "-5px",
            right: "-5px",
            bottom: "-5px",
            left: "-5px",
            border: `${props.appearance === "link" ? 1 : 2}px solid ${
                colors.focus
            }`,
            borderRadius: "7px",
            content: `""`,
            display: "block",
            pointerEvents: "none",
        },
    }),

    ...(props.isLoading && { pointerEvents: "none" }),
});

// fade in content after done loading data
export const getLoadingStyle = (isLoading?: boolean) => ({
    transition: "opacity 0.3s",
    opacity: isLoading ? 0 : 1,
});

/**
 * SPINNER STYLES
 */
export const getSpinnerStyles = (): CSSObject => ({
    display: "flex",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
});
