import React, { Ref } from "react";
import { BaseButtonProps } from "./BaseButton";

type LinkOrButtonProps = Omit<
    BaseButtonProps<HTMLButtonElement | HTMLAnchorElement>,
    "focusMode"
>;

export const LinkOrButton = ({
    renderAs,
    innerRef,
    ...props
}: LinkOrButtonProps) => {
    if (renderAs === "a") {
        return (
            <a
                {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
                ref={innerRef as Ref<HTMLAnchorElement>}
            >
                {props.children}
            </a>
        );
    } else {
        return (
            <button
                {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
                ref={innerRef as Ref<HTMLButtonElement>}
            >
                {props.children}
            </button>
        );
    }
};
