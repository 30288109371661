import {
    neutralDark20,
    neutralDark30,
    neutralDark40,
    neutralLight40,
} from "../../constants/colors";
import { ttNormsFontFamily } from "../../fonts/fonts";

/**
 * Font Sizing
 * (includes line height)
 */
export const fontSizeDefinitions = {
    "13": { fontSize: "13px", lineHeight: "18px" },
    "14": { fontSize: "14px", lineHeight: "18px" },
    "15": { fontSize: "15px", lineHeight: "18px" },
    "18": { fontSize: "18px", lineHeight: "24px" },
    "24": { fontSize: "24px", lineHeight: "28px" },
    "32": { fontSize: "32px", lineHeight: "34px" },
    "40": { fontSize: "40px", lineHeight: "48px" },
} as const;

/**
 * Font weight
 */
export const fontWeight = {
    thin: 100,
    extraLight: 200,
    light: 300,
    regular: 400,
    normal: 400,
    medium: 500,
    semiBold: 600,
    demiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
    extraBlack: 950,
} as const;

/**
 * Font Family
 */
export const fontFamily = {
    ttNorms: ttNormsFontFamily,
    inter: '"Inter", Arial, Helvetica, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";',
    interVar:
        '"Inter var", Arial, Helvetica, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";',
} as const;

/**
 * Type ramp style definitions
 */
export const typeRampDefinitions = {
    headlineXLarge: {
        fontWeight: fontWeight.bold,
        ...fontSizeDefinitions["40"],
        component: "span",
        theme: {
            light: {
                fontFamily: ttNormsFontFamily,
                color: neutralDark40,
            },
            dark: {
                fontFamily: ttNormsFontFamily,
                color: neutralLight40,
            },
            oldestar: { color: neutralDark40 },
        },
    },
    headlineLarge: {
        fontWeight: fontWeight.bold,
        component: "span",
        ...fontSizeDefinitions["32"],
        theme: {
            light: {
                fontFamily: ttNormsFontFamily,
                color: neutralDark40,
            },
            dark: {
                fontFamily: ttNormsFontFamily,
                color: neutralLight40,
            },
            oldestar: { color: neutralDark40 },
        },
    },
    headlineMedium: {
        fontWeight: fontWeight.bold,
        component: "span",
        ...fontSizeDefinitions["24"],
        theme: {
            light: {
                fontFamily: ttNormsFontFamily,
                color: neutralDark40,
            },
            dark: {
                fontFamily: ttNormsFontFamily,
                color: neutralLight40,
            },
            oldestar: { color: neutralDark40 },
        },
    },
    headlineSmall: {
        fontWeight: fontWeight.bold,
        component: "span",
        ...fontSizeDefinitions["18"],
        theme: {
            light: {
                fontFamily: ttNormsFontFamily,
                color: neutralDark40,
            },
            dark: {
                fontFamily: ttNormsFontFamily,
                color: neutralLight40,
            },
            oldestar: { color: neutralDark40 },
        },
    },
    headerLarge: {
        fontWeight: fontWeight.semiBold,
        component: "span",
        ...fontSizeDefinitions["15"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    headerSmall: {
        fontWeight: fontWeight.semiBold,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    buttonLarge: {
        fontWeight: fontWeight.bold,
        component: "span",
        ...fontSizeDefinitions["14"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    buttonMedium: {
        fontWeight: fontWeight.semiBold,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    titlesEmphasis: {
        fontWeight: fontWeight.semiBold,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    bodyStrong: {
        fontWeight: fontWeight.medium,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    bodyText: {
        fontWeight: fontWeight.regular,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark30 },
            dark: {},
            oldestar: { color: neutralDark30 },
        },
    },
    metadata: {
        fontWeight: fontWeight.regular,
        component: "span",
        ...fontSizeDefinitions["13"],
        theme: {
            light: { color: neutralDark20 },
            dark: {},
            oldestar: { color: neutralDark20 },
        },
    },
} as const;
