/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import classnames from "classnames";
import { useContext, useEffect, useMemo } from "react";
import { components } from "react-select";
import { borderRadius } from "../../../constants/borders";
import {
    danger,
    focus,
    neutral,
    neutralDark30,
    neutralDark40,
    neutralLight20,
    neutralLight30,
    neutralLight40,
} from "../../../constants/colors";
import {
    FOCUS_USING_BEFORE_CLASS_NAME,
    getFocusRingStyles,
} from "../../../util/focusRing";
import { LodestarCoreTheme, themed } from "../../../util/theme";
import { useEnsuredForwardedRef } from "../../../util/useEnsuredForwardedRef";
import { IE11Flexbug3Fix } from "./IE11Flexbug3Fix";
import { useLayoutContext } from "./LayoutContext";
import { StaticPropContext } from "./StaticPropContext";
import { ValidationContext } from "./ValidationContext";

const controlTheme = themed({
    borderRadius: {
        light: borderRadius() + "px",
        oldestar: 2 + "px",
    },
    border: {
        light: "none",
        oldestar: "1px solid #cccccc",
    },
    padding: {
        light: 4,
        // To account for the added 1px border on all sides.
        oldestar: 2,
    },
    background: {
        menuIsOpen: {
            light: neutralLight40,
            oldestar: "#ffffff",
        },
        menuIsClosed: {
            light: neutralLight30,
            oldestar: "#ffffff",
        },
    },
    backgroundActive: {
        light: neutral,
        oldestar: "#ffffff",
    },
    backgroundHover: {
        menuIsOpen: {
            light: neutralLight40,
            oldestar: "#ffffff",
        },
        menuIsClosed: {
            light: neutralLight20,
            oldestar: "#ffffff",
        },
    },
    textColor: {
        menuIsOpen: neutralDark30,
        menuIsClosed: neutralDark40,
    },
});

const ringTheme = themed({
    focus: {
        outlineColor: {
            default: {
                light: focus,
                oldestar: "#0073ec",
            },
            error: {
                light: danger,
                oldestar: "#ea352e",
            },
        },
        radius: {
            light: 7,
            oldestar: 4,
        },
        offset: {
            light: 4,
            oldestar: 4,
        },
    },
    active: {
        outlineColor: {
            default: {
                light: focus,
                oldestar: "#0073ec",
            },
            error: {
                light: danger,
                oldestar: "#ea352e",
            },
        },
        radius: {
            light: 4,
            oldestar: 2,
        },
        offset: {
            light: 0,
            oldestar: 2,
        },
    },
});

export const Control: typeof components.Control = ({
    children,
    innerRef: providedRef,
    className,
    ...props
}) => {
    const layoutContext = useLayoutContext();
    const validationState = useContext(ValidationContext);
    const { clientId } = useContext(StaticPropContext);
    const innerRef = useEnsuredForwardedRef<HTMLElement>(providedRef);

    // Using a normal effect here because though we're measuring the DOM and updating layout here,
    // the component that will be affected is the MenuPortal, not the Control.
    useEffect(() => {
        // If the control's height isn't the same as the layoutContext, notify!
        const height = innerRef.current?.offsetHeight;
        if (height && height !== layoutContext.controlHeight) {
            layoutContext.setControlHeight(height);
        }
    });

    const theme = useContext(LodestarCoreTheme);

    const focusRing = useMemo(
        () =>
            getFocusRingStyles({
                theme,
                offset: ringTheme.focus.offset(theme),
                radius: ringTheme.focus.radius(theme),
            }),
        [theme]
    );

    const activeRing = useMemo(
        () =>
            getFocusRingStyles({
                theme,
                colorOverride:
                    validationState === "error"
                        ? ringTheme.active.outlineColor.error(theme)
                        : ringTheme.active.outlineColor.default(theme),
                offset: ringTheme.active.offset(theme),
                radius: ringTheme.active.radius(theme),
                pseudoElement: "after",
            }),
        [theme, validationState]
    );

    const menuIsOpen = !!props.selectProps.menuIsOpen;
    return (
        <IE11Flexbug3Fix>
            <components.Control
                {...props}
                innerProps={{
                    ...props.innerProps,
                    // Yarg! React-select spreads this object onto the Control but doesn't allow an arbitrary shape.
                    // Let's just push through this since it's a safe thing to do in this case.
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    "data-client-id": clientId && `${clientId}--control`,
                }}
                innerRef={innerRef}
                className={classnames(FOCUS_USING_BEFORE_CLASS_NAME, className)}
                css={css`
                    border-radius: ${controlTheme.borderRadius(theme)};
                    border: ${controlTheme.border(theme)};
                    background: ${menuIsOpen
                        ? controlTheme.background.menuIsOpen(theme)
                        : controlTheme.background.menuIsClosed(theme)};
                    color: ${menuIsOpen
                        ? controlTheme.textColor.menuIsOpen(theme)
                        : controlTheme.textColor.menuIsClosed(theme)};
                    box-shadow: none;
                    padding: ${controlTheme.padding(theme)}px;
                    transition: background 0.2s;
                    cursor: pointer;
                    min-height: 40px;

                    &:hover {
                        background: ${menuIsOpen
                            ? controlTheme.backgroundHover.menuIsOpen(theme)
                            : controlTheme.backgroundHover.menuIsClosed(theme)};
                        border: ${controlTheme.border(theme)};
                    }
                    &:active {
                        border-color: transparent;
                        background: ${controlTheme.backgroundActive(theme)};
                    }

                    ${!menuIsOpen && props.isFocused && focusRing}

                    ${(validationState === "error" || menuIsOpen) && activeRing}
                `}
            >
                {children}
            </components.Control>
        </IE11Flexbug3Fix>
    );
};
