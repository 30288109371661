import styled from "@emotion/styled";
import { getFocusRingStyles } from "../../../util/focusRing";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";
import { StyledProps } from "./StyledProps";
import {
    getBorderWidth,
    getColors,
    getHeight,
    getWidth,
} from "./checkboxStyleConstants";

const getBackground = (props: StyledProps) =>
    getColors(props).background(props.themeMode);
const getBorder = (props: StyledProps) =>
    getColors(props).border(props.themeMode);

function createFocusRing({ themeMode }: StyledProps): string {
    return getFocusRingStyles({
        theme: themeMode,
        offset: 6,
        radius: 6,
        borderWidth: getBorderWidth(),
    });
}

export const CheckboxWrapper = withFocusToggleClassName(styled.div<StyledProps>`
    background-color: ${getBackground};
    border: ${getBorderWidth}px solid ${getBorder};
    border-radius: ${getBorderWidth}px;
    box-sizing: border-box;
    flex: none;
    // TODO: Fix animation
    // transition: background-color 1s, color 1s, border 1s;
    height: ${getHeight}px;
    width: ${getWidth}px;
    position: relative;

    cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};

    ${({ isFocused }) => isFocused && createFocusRing}
`);

CheckboxWrapper.displayName = "CheckboxWrapper";
