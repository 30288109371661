import styled from "@emotion/styled";
import { neutralLight40 } from "../../../constants/colors";
import { shadows } from "../../../constants/layers";
import { layers } from "../../../constants/layers";
import { body } from "../../../constants/typography";
import {
    flexMaxHeightIEFix,
    IEMaxHeightCalcPx,
} from "../../../util/flex-max-height-ie-fix";
import { WIDTH_ENUM, gutter, WidthNames } from "../shared-variables";

const maxWidthDimensions = `calc(100% - ${gutter * 2}px)`;

const maxHeightDimensions = (gutterHeight?: string | number): string => {
    if (typeof gutterHeight === "string") {
        const percent = parseInt(gutterHeight);

        return `calc(${100 - percent * 2}% - ${IEMaxHeightCalcPx}px)`;
    }

    const gutterHeightPx =
        typeof gutterHeight === "number" ? gutterHeight : gutter;

    return `calc(100% - ${gutterHeightPx * 2 - IEMaxHeightCalcPx}px)`;
};

export const modalWidth = ({ widthName, widthValue }: PositionerProps) => {
    if (typeof widthValue === "number") {
        return `${widthValue}px`;
    }

    return widthName
        ? `${WIDTH_ENUM.widths[widthName]}px`
        : widthValue || "auto";
};

export const modalHeight = ({
    heightValue,
}: {
    heightValue?: string | number;
}) => {
    if (typeof heightValue === "number") {
        return `${heightValue}px`;
    }
    return heightValue || "auto";
};

const positionerAbsoluteTop = (gutterHeight?: string | number): string => {
    if (typeof gutterHeight === "string") {
        return gutterHeight;
    }

    const gutterHeightPx =
        typeof gutterHeight === "number" ? gutterHeight : gutter;

    return `${gutterHeightPx}px`;
};

/**
  NOTE:
  z-index
  - TODO seems unecessary, can we remove?

  absolute + top
  - rather than fixed position so popper.js children are properly positioned

  overflow-y
  - only active when popper.js children invoked below the modal
*/
export const FillScreen = styled.div`
    height: 100vh;
    left: 0;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: ${layers.modal};
    -webkit-overflow-scrolling: touch;
`;

interface PositionerProps {
    gutterHeight?: number | string;
    widthName?: WidthNames;
    widthValue?: string | number;
}

export const PositionerAbsolute = styled.div<
    Pick<PositionerProps, "widthValue" | "gutterHeight">
>`
    position: absolute;
    top: ${({ gutterHeight }) => positionerAbsoluteTop(gutterHeight)};
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    height: ${({ gutterHeight }) => maxHeightDimensions(gutterHeight)};
    width: ${({ widthValue }) => widthValue};
    max-width: ${maxWidthDimensions};
    margin-left: auto;
    margin-right: auto;
    z-index: ${layers.modal};
    pointer-events: none;

    @media (min-width: 320px) and (max-width: 480px) {
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        max-width: 100%;
    }
`;

interface ModalContainerProps {
    heightValue?: string | number;
}

export const StyledModalContainer = styled.div<ModalContainerProps>`
    background-color: ${neutralLight40};
    box-shadow: ${shadows.modal()};
    color: ${body.color};
    display: flex;
    flex-direction: column;
    height: ${(props: ModalContainerProps) =>
        modalHeight({ heightValue: props.heightValue })};
    ${flexMaxHeightIEFix};

    /* the parent (PositionerAbsolute) turns off pointer events, so we need to turn it back on */
    pointer-events: auto;

    @media (min-width: 320px) and (max-width: 480px) {
        height: 100%;
        max-height: 100%;
        border-radius: 0;
    }
`;

PositionerAbsolute.displayName = "StyledPositionerAbsolute";
StyledModalContainer.displayName = "StyledModalContainer";
FillScreen.displayName = "StyledFillScreen";
