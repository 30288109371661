import styled from "@emotion/styled";
import { StyledProps } from "./StyledProps";
import {
    getBorderWidth,
    getColors,
    getHeight,
    getWidth,
} from "./checkboxStyleConstants";

const getCheckmarkColor = (props: StyledProps) =>
    getColors(props).checkmark(props.themeMode);
const getIndeterminateColor = (props: StyledProps) =>
    getColors(props).dash(props.themeMode);

export const CheckmarkIcon = styled.span<StyledProps>`
    position: absolute;
    width: ${getWidth}px;
    height: ${getHeight}px;
    fill: ${getCheckmarkColor};
    top: -${getBorderWidth}px;
    left: -${getBorderWidth}px;
`;

export const IndeterminateIcon = styled.span<StyledProps>`
    position: absolute;
    width: ${getWidth}px;
    height: ${getHeight}px;
    fill: ${getIndeterminateColor};
    top: -${getBorderWidth}px;
    left: -${getBorderWidth}px;
`;
