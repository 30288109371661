import styled from "@emotion/styled";
import React from "react";
import * as colors from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import { getFocusRingStyles } from "../../../util/focusRing";
import { themed, ThemeMode } from "../../../util/theme";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";
import * as styleConstants from "./constants";

interface LabelProps {
    isChecked?: boolean;
    isDisabled?: boolean;
    isFullWidth?: boolean;
    onMouseDown?: React.MouseEventHandler;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
    onMouseUp?: React.MouseEventHandler;
}

export const Label = styled.label<LabelProps>`
    display: flex;
    align-items: flex-start;
    // See https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/138
    color: ${(props) => (props.isDisabled ? colors.neutralDark20 : "inherit")};
    font-weight: ${(props) =>
        !props.isDisabled && props.isChecked ? 600 : 500};
    user-select: none;
`;

export const LabelText = styled.div`
    margin-left: ${sizes.xSmall}px;
    margin-top: 1px;
`;

interface IconWrapperProps {
    themeMode: ThemeMode;
    isActive?: boolean;
    isChecked?: boolean;
    isDisabled?: boolean;
    isFocused?: boolean;
    isHovered?: boolean;
    isInvalid?: boolean;
    onMouseDown?: React.MouseEventHandler;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
    onMouseUp?: React.MouseEventHandler;
}

const getBorderWidth = (props: IconWrapperProps) => {
    if (props.isHovered && !props.isDisabled)
        return styleConstants.borderWidthHover;
    if (props.isChecked) return styleConstants.borderWidthChecked;
    return styleConstants.borderWidth;
};

const borderTheme = themed({
    checked: {
        light: colors.buttonPrimary,
        oldestar: "#0073ec",
    },
    checkedDisabled: {
        light: colors.toRgba(colors.buttonPrimary, 0.4),
        oldestar: colors.toRgba("#0073ec", 0.3),
    },
    disabled: colors.neutralLight10,
    invalid: colors.buttonDestructive,
    active: colors.active,
    rest: {
        light: colors.neutralAccessible,
        oldestar: "#cccccc",
    },
});

const getBorderColor = (props: IconWrapperProps): string => {
    if (props.isDisabled && props.isChecked)
        return borderTheme.checkedDisabled(props.themeMode);
    if (props.isDisabled) {
        return borderTheme.disabled(props.themeMode);
    }
    if (props.isActive) {
        return borderTheme.active(props.themeMode);
    }
    if (props.isInvalid) {
        return borderTheme.invalid(props.themeMode);
    }
    if (props.isChecked) {
        return borderTheme.checked(props.themeMode);
    }
    return borderTheme.rest(props.themeMode);
};

function createFocusRing({ themeMode }: IconWrapperProps) {
    return getFocusRingStyles({
        theme: themeMode,
        offset: 4,
        radius: "50%",
    });
}

export const IconWrapper =
    withFocusToggleClassName(styled.span<IconWrapperProps>`
        line-height: 0;
        flex-shrink: 0;
        box-shadow: inset 0 0 0 ${getBorderWidth}px ${getBorderColor};
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 0;
        margin: 0;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        transition: box-shadow 0.2s;
        background: ${colors.neutralLight40};
        cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
        ${({ isFocused }) => isFocused && createFocusRing}
    `);
IconWrapper.displayName = "IconWrapper";
