/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgDelete: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                fill="currentColor"
                d="M16.243 7.757a1.2 1.2 0 010 1.697L13.696 12l2.547 2.547a1.2 1.2 0 11-1.697 1.697l-2.547-2.547-2.545 2.547a1.2 1.2 0 11-1.697-1.697l2.545-2.547-2.545-2.545a1.2 1.2 0 111.697-1.697L12 10.302l2.547-2.545a1.2 1.2 0 011.697 0z"
            />
        </g>
    </svg>
);
