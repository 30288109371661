import styled from "@emotion/styled";
import { CheckIcon } from "@smartsheet/lodestar-icons";
import { ToggleIconProps } from "./ToggleIconProps";

const left = {
    large: 4,
    medium: 3,
};

export const ToggleOnIcon = styled(CheckIcon)<ToggleIconProps>`
    transition: opacity 0.1s ease-in;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    position: relative;
    left: ${({ toggleSize }) => left[toggleSize]}px;
`;
