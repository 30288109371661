import React, {
    PropsWithChildren,
    MutableRefObject,
    useCallback,
    ComponentType,
    useMemo,
} from "react";
import ReactSelect, {
    OptionsType,
    ValueType,
    createFilter,
    SelectComponentsConfig as RSComponentsConfig,
} from "react-select";
import ReactSelectCreatable from "react-select/creatable";
import { LayerContextProvider } from "../..";
import { layers } from "../../constants/layers";
import { warnOnce } from "../../util/devUtil";
import { ClearIndicator } from "./components/ClearIndicator";
import { Control } from "./components/Control";
import { DropdownIndicator } from "./components/DropdownIndicator";
import { IndicatorSeparator } from "./components/IndicatorSeparator";
import { IndicatorsContainer } from "./components/IndicatorsContainer";
import { Input } from "./components/Input";
import { LoadingIndicator } from "./components/LoadingIndicator";
import { Menu } from "./components/Menu";
import { MenuList } from "./components/MenuList";
import { MenuPortal } from "./components/MenuPortal";
import { MultiValue } from "./components/MultiValue";
import { Option } from "./components/Option";
import { Placeholder } from "./components/Placeholder";
import { StaticPropContext } from "./components/StaticPropContext";
import { ValidationContext } from "./components/ValidationContext";
import { VirtualizedMenuListProps } from "./components/VirtualizedMenuList";
import { ActionMeta, CommonSelectProps, OptionType, Focusable } from "./types";

const warnOnDeprecatedSuccessValidationState = warnOnce(
    `validationState="success" is deprecated. It will be removed in the future. If you have a need for it, please contact the UI Engineering team.`
);

export const getComponents = <SelectOption extends OptionType>(
    components: ComponentsConfig<SelectOption> = {}
): ComponentsConfig<SelectOption> => {
    const defaultComponents: Partial<ComponentsConfig<SelectOption>> = {
        ClearIndicator,
        Control,
        DropdownIndicator,
        IndicatorSeparator,
        IndicatorsContainer,
        Input,
        LoadingIndicator,
        Menu,
        MenuList,
        MenuPortal,
        MultiValue,
        Option,
        Placeholder,
    };

    return {
        ...defaultComponents,
        ...components,
    };
};

interface ComponentsConfig<SelectOption>
    extends RSComponentsConfig<SelectOption> {
    VirtualizedMenuList?: ComponentType<VirtualizedMenuListProps<SelectOption>>;
}

export interface SelectBaseProps<SelectOption extends OptionType>
    extends CommonSelectProps<SelectOption> {
    /* Handle change events on the select */
    onChange: (
        value: ValueType<SelectOption>,
        action: ActionMeta<SelectOption>
    ) => void;
    // Options was pulled out of CommonSelectProps for docs purposes
    /** Array of options that populate the select menu */
    options: OptionsType<SelectOption>;
    /**
     *
     *   This object includes all the compositional components that are used
     *   in the Select component. If you wish to overwrite a component, pass in an object
     *   with the appropriate namespace:
     *
     *   - ClearIndicator
     *   - Control
     *   - DropdownIndicator
     *   - DownChevron
     *   - CrossIcon
     *   - Group
     *   - GroupHeading
     *   - IndicatorsContainer
     *   - IndicatorSeparator
     *   - Input
     *   - LoadingIndicator
     *   - Menu
     *   - MenuList
     *   - MenuPortal
     *   - LoadingMessage
     *   - NoOptionsMessage
     *   - MultiValue
     *   - MultiValueContainer
     *   - MultiValueLabel
     *   - MultiValueRemove
     *   - Option
     *   - Placeholder
     *   - SelectContainer
     *   - SingleValue
     *   - ValueContainer
     *   - VirtualizedMenuList
     *
     *   We suggest using this mechanism to apply custom styles to a component rather than
     *   reaching in with css selectors as the DOM can change from release to release in subtle ways.
     */
    components?: ComponentsConfig<SelectOption>;
    /* Hide the selected option from the menu */
    hideSelectedOptions?: boolean;
    /**
     * Used only to provide access to focus/blur functions to call imperatively.
     */
    innerRef?: React.Ref<Focusable>;
    /* Support multiple selected options */
    isMulti?: boolean;

    /* The value of the select; reflected by the selected option */
    value?: ValueType<SelectOption>;
}

export const SelectBase = <SelectOption extends OptionType>(
    props: PropsWithChildren<SelectBaseProps<SelectOption>>
) => {
    type RefType =
        | ReactSelect<SelectOption>
        | ReactSelectCreatable<SelectOption>
        | null;

    const { innerRef } = props;
    const onSelectRef = useCallback(
        (ref: RefType) => {
            const subsetOfReactSelect: Focusable = {
                blur: () => ref?.blur(),
                focus: () => ref?.focus(),
            };

            if (innerRef && typeof innerRef === "object") {
                (innerRef as MutableRefObject<Focusable>).current =
                    subsetOfReactSelect;
            }
            if (typeof innerRef === "function") {
                innerRef(subsetOfReactSelect);
            }
        },
        [innerRef]
    );

    const {
        validationState = "default",
        clientId,
        components,
        options,
        onCreateOption,
        getNewOptionData,
        value,
        filterOption,
        ...restProps
    } = props;

    if (process.env.NODE_ENV !== "production") {
        if (validationState === "success") {
            warnOnDeprecatedSuccessValidationState();
        }
    }

    const commonProps = {
        ...restProps,
        ref: onSelectRef,
        onCreateOption,
        options: options,
        components: getComponents(components),
        spacing: "default",
        escapeClearsValue: false,
        tabSelectsValue: false,
        closeMenuOnScroll: true,
        // This helps the Control not re-render as much, but the MenuList is still bad.
        // See https://blog.johnnyreilly.com/2019/04/react-select-with-less-typing-lag.html
        filterOption:
            (filterOption as
                | ((option: unknown, rawInput: string) => boolean)
                | undefined) || createFilter({ ignoreAccents: false }),

        // A null value is required for react-select to be cleared to show the placeholder
        value: value === undefined ? null : value,

        // Give the select menu the correct z-index.
        // Currently this is the most reasonable way to do it with react-select, as the MenuPortal component
        // doesn't play nice with styled components.
        // https://github.com/JedWatson/react-select/issues/2909
        styles: {
            menuPortal: (css = {}) => ({
                ...css,
                // See the LayerContext below as well.
                zIndex: layers.context,
            }),
        },
    } as const;

    // This is a workaround for a react-select creatable bug. Please be sure that the returned
    // object has an attribute (in this case, `__Fix3988`) with a function value.
    // https://github.com/JedWatson/react-select/issues/3988
    const defaultGetNewOptionData = (
        inputValue: string,
        optionLabel: React.ReactNode
    ) =>
        ({
            __Fix3988: () => false,
            ...(getNewOptionData
                ? getNewOptionData(inputValue, optionLabel as string)
                : {
                      label: optionLabel,
                      value: inputValue,
                  }),
        } as SelectOption);

    const memoizedStaticProps = useMemo(
        () => ({
            clientId,
        }),
        [clientId]
    );

    return (
        // StaticPropContext should be the outermost context since we don't expect it to change often.
        <StaticPropContext.Provider value={memoizedStaticProps}>
            <ValidationContext.Provider value={validationState}>
                {/* See the menuPortal z-index override above as well */}
                <LayerContextProvider defaultLayer="__unstable-select-v1-menu">
                    {onCreateOption !== undefined ? (
                        <ReactSelectCreatable
                            {...commonProps}
                            getNewOptionData={defaultGetNewOptionData}
                        />
                    ) : (
                        <ReactSelect {...commonProps} />
                    )}
                </LayerContextProvider>
            </ValidationContext.Provider>
        </StaticPropContext.Provider>
    );
};
