import styled from "@emotion/styled";
import { Omit } from "utility-types";
import { IconProps } from "../Icon";

type IconWrapperProps = Omit<
    IconProps,
    "svgComponent" | "dangerouslySetSvg" | "height" | "width"
> & {
    height: string;
    width: string;
};

const getSize = ({ height, width }: IconWrapperProps) =>
    `height: ${height}; width: ${width};`;

const getColor = ({ color }: IconWrapperProps) => color || "currentColor";

export const IconWrapper = styled.span<IconWrapperProps>`
    ${getSize}
    color: ${getColor};
    display: inline-block;
    flex-shrink: 0;
    line-height: 1;
    > svg {
        ${getSize}
        max-height: 100%;
        max-width: 100%;
        overflow: hidden;
        pointer-events: none;
        vertical-align: bottom;
    }
`;

IconWrapper.displayName = "IconWrapper";
