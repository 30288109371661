import styled from "@emotion/styled";
import React, { memo, PropsWithChildren, ReactNode, Ref } from "react";
import {
    ListChildComponentProps,
    VariableSizeList,
    areEqual,
} from "react-window";
import { Omit } from "utility-types";
import { OptionType } from "../types";

// Deactivating this for now - removing it is a breaking change.
// See https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/493
// eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
export interface VirtualizedMenuListProps<SelectOption> {
    getOptionHeight: (index: number) => number;
    innerRef: Ref<HTMLDivElement>;
    maxHeight: number;
    className?: string;
}

const VirtualizedMenuListContainer = styled.div`
    // Can't just use padding OR margin because of the way react-window needs
    // to calc heights :)
    padding-bottom: 4px;
    margin-top: 4px;
`;

type VItemInterface = Omit<ListChildComponentProps, "data"> & {
    data: ReactNode[];
};

// See https://react-window.now.sh/#/examples/list/memoized-list-items
// This helps a LOT because for the most part the object is the same
// just isFocused is changed on items the user is interacting with.
const VirtualItem = memo(({ data, index, style }: VItemInterface) => {
    return <div style={style}>{data[index]}</div>;
}, areEqual);
VirtualItem.displayName = "VirtualItem";

/**
 * Leverages react-window's FixedSizeList to virtualize the menu list. This implementation works
 * best if the menu options have a fixed size.
 *
 * If the menu options don't have a fixed size, adjusting the itemHeight to fit the maximum-height
 * option will produce the best results, but ultimately you might want to provide a different
 * virtualization via the components overrides (perhaps using react-window's VariableSizeList).
 */
export const VirtualizedMenuList = <SelectOption extends OptionType>(
    props: PropsWithChildren<VirtualizedMenuListProps<SelectOption>>
) => {
    const { className, children, innerRef, maxHeight, getOptionHeight } = props;
    const childrenArray = React.Children.toArray(children);
    return (
        <VariableSizeList
            className={className}
            width={"100%"}
            height={maxHeight}
            itemData={childrenArray}
            itemCount={childrenArray.length}
            itemSize={getOptionHeight}
            outerElementType={VirtualizedMenuListContainer}
            innerRef={innerRef}
        >
            {VirtualItem}
        </VariableSizeList>
    );
};
