import styled from "@emotion/styled";
import * as colors from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";

export interface LabelTextProps {
    isDisabled?: boolean;
    isRequired?: boolean;
}

const getLabelColor = (props: LabelTextProps) => {
    return props.isDisabled
        ? colors.toRgba(colors.neutralDark40, 0.4)
        : colors.neutralDark40;
};

export const LabelText = styled.span<LabelTextProps>`
    margin: 1px 0 0 ${sizes.xSmall}px;
    color: ${getLabelColor};
`;
