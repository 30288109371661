import styled from "@emotion/styled";

export const Input = styled.input`
    opacity: 0;
    position: absolute;
    user-select: none;
    &:focus {
        outline: none !important;
    }
`;
