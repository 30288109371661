import React, { FC } from "react";
import { StyledFooterButtons, StyledModalFooter } from "../styled/Footer";

export interface ModalFooterProps {
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
}

/**
 * ModalFooter is a component intended to be composed inside a Modal.
 *
 * It is a simple default implementation of a footer, only rendering the given
 * children (ideally buttons) flush-right
 */
export const ModalFooter: FC<ModalFooterProps> = ({
    /**
     * Content to render flush right. Generally set of ModalFooterButton components.
     */
    children,
    className,
}) => (
    <StyledModalFooter className={className}>
        <StyledFooterButtons>{children}</StyledFooterButtons>
    </StyledModalFooter>
);
