import React, { forwardRef } from "react";
import { iconSizes } from "./constants";
import { IconProps } from "./IconProps";
import { IconWrapper } from "./IconWrapper";

export const Icon = forwardRef<HTMLElement, IconProps>(
    (
        {
            svgComponent: Component,
            dangerouslySetSvg,
            size = "medium",
            "aria-hidden": ariaHidden = true,
            role = "presentation",
            clientId,
            children,
            ...props
        },
        ref
    ) => {
        const height = props.height || iconSizes[size];
        const width = props.width || iconSizes[size];

        if (process.env.NODE_ENV !== "production") {
            const renderableProps = [
                children ? "children" : null,
                Component ? "Component" : null,
                dangerouslySetSvg ? "dangerouslySetSvg" : null,
            ].filter(Boolean);
            if (renderableProps.length > 1) {
                console.warn(
                    `Icon: please pass only one of children, svgComponent, or dangerouslySetSvg, got [${renderableProps.join(
                        ", "
                    )}]`
                );
            }
        }

        if (Component) {
            return (
                <IconWrapper
                    height={height}
                    width={width}
                    aria-hidden={ariaHidden}
                    role={role}
                    ref={ref}
                    data-client-id={clientId}
                    {...props}
                >
                    <Component />
                </IconWrapper>
            );
        } else if (dangerouslySetSvg) {
            return (
                <IconWrapper
                    height={height}
                    width={width}
                    aria-hidden={ariaHidden}
                    role={role}
                    ref={ref}
                    data-client-id={clientId}
                    {...props}
                    dangerouslySetInnerHTML={{
                        __html: dangerouslySetSvg,
                    }}
                />
            );
        } else if (children) {
            return (
                <IconWrapper
                    height={height}
                    width={width}
                    aria-hidden={ariaHidden}
                    role={role}
                    ref={ref}
                    data-client-id={clientId}
                    {...props}
                >
                    {children}
                </IconWrapper>
            );
        }
        return null;
    }
);

Icon.displayName = "Icon";
