import styled from "@emotion/styled";
import { HTMLAttributes } from "react";
import { sizes } from "../../../constants/spacing";

export type BannerContentProps = HTMLAttributes<HTMLDivElement>;

export const BannerContent = styled("div")`
    margin: ${sizes.small}px ${sizes.xLarge}px ${sizes.small}px ${sizes.small}px;
    align-self: center;
    flex-grow: 1;

    // To allow the Links to properly ellide, we need to add some extra stuff:
    // Not hidden, because we want the focus outlines to be visible and they render outside
    // of the bounding box
    overflow: visible;
    // Ensure the container calcs its width.
    min-width: 1px;

    // IE11 flex-bug 2: https://github.com/philipwalton/flexbugs#flexbug-2
    max-width: 100%;
`;
