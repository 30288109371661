/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgSearch: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M10.239 3a7.238 7.238 0 015.623 11.797c.05.028.097.065.14.108l4.789 4.79a.749.749 0 010 1.06l-.036.036a.749.749 0 01-1.06 0l-4.79-4.789a.729.729 0 01-.11-.14 7.198 7.198 0 01-4.556 1.615C6.24 17.477 3 14.237 3 10.24S6.24 3 10.239 3zm0 1.551a5.687 5.687 0 10-.002 11.374 5.687 5.687 0 00.002-11.374z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
