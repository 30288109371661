/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { components, InputProps } from "react-select";
import { Props as SelectProps } from "react-select/src/Select";

// These types are missing from react-select types for InputProps:
// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react-select/src/components/Input.d.ts
interface MissingInputProps {
    selectProps: SelectProps;
}

export const Input: typeof components.Input = ({ children, ...rest }) => {
    return (
        <components.Input
            {...rest}
            css={css`
                padding-left: 6px;
                font-size: 13px;
                // Need to shift the top by a bit if we're in multiselect mode since we use a negative margin
                // fix to properly wrap the selection in MultiSelectValueContainer.
                position: relative;

                // Need to override the default line-height so we don't inherit the default 18px.
                line-height: 16px;

                ${(rest as unknown as InputProps & MissingInputProps)
                    .selectProps.isMulti
                    ? "top: 4px; padding: 4px 0 6px 6px;"
                    : ""}
            `}
        >
            {children}
        </components.Input>
    );
};
