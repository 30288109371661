import styled from "@emotion/styled";
import { CloseIcon } from "@smartsheet/lodestar-icons";
import { ToggleIconProps } from "./ToggleIconProps";

export const ToggleOffIcon = styled(CloseIcon)<ToggleIconProps>`
    transition: opacity 0.1s ease-in;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
    position: relative;
    right: 3px;
`;
