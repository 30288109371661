import * as colors from "../../constants/colors";
import { themed, ThemeMode } from "../../util/theme";

const defaultColor = "blue";

export const backgroundColorThemes = themed({
    berry: colors.visBerry,
    berryLight: colors.visBerryLight10,
    blue: colors.visBlue,
    blueLight: colors.visBlueLight10,
    green: colors.visGreen,
    greenLight: colors.visGreenLight10,
    orange: colors.visOrange,
    orangeLight: colors.visOrangeLight10,
    purple: colors.visPurple,
    purpleLight: colors.visPurpleLight10,
    red: colors.visRed,
    redLight: colors.visRedLight10,
    teal: colors.visTeal,
    tealLight: colors.visTealLight10,
    yellow: colors.visYellow,
    yellowLight: colors.visYellowLight10,

    success: colors.successLight10,
    warning: colors.warning,
    error: colors.dangerLight10,
    info: colors.infoLight20,
});

export const backgroundColor = (
    color: keyof typeof backgroundColorThemes,
    themeMode: ThemeMode
): string => {
    const theme =
        backgroundColorThemes[color] || backgroundColorThemes[defaultColor];
    return theme(themeMode);
};
