import { tooltipBackground } from "../../constants/colors";

const noopCloseRequested = () => undefined;

export const tooltipFlyoutDefaults = {
    hasArrow: true,
    arrowColor: tooltipBackground,
    offset: {
        distance: 8,
    },
    onCloseRequested: noopCloseRequested,
    // Tooltips are just a label basically and we'll use aria-describedby instead of a role for them.
    role: null,
} as const;

export const ANIMATION_DURATION = 500;
