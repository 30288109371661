/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { jsx } from "@emotion/core";

/**
 * Wraps jsx to provide an API similar to React.cloneElement.
 * This is so we can pass the `css` prop to the cloned child in Inline/Stack
 * and it will be translated and merged into `className`.
 *
 * Adapted from:
 * https://github.com/emotion-js/emotion/issues/1102#issuecomment-446887725
 * @param element
 * @param props
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function cloneElement(element: any, props: any) {
    return jsx(element.type, {
        key: element.key,
        ref: element.ref,
        ...element.props,
        ...props,
    });
}
