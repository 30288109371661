/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { ScrollArea } from "../../scrollarea/components/ScrollArea";

export interface ModalBodyProps {
    /**
     * A classname to be applied to the root element of this component.
     */
    className?: string;
    /**
     * A `clientId` prop is provided for specified elements, which is a unique string
     * that appears as a data attribute `data-client-id` in the rendered code, serving as
     * a hook for automated tests
     */
    scrollContainerClientId?: string;
}

/**
 * ModalBody is a component intended to be composed inside a Modal. It is responsible for:
 * - Rendering the main content area (aka the body) of a modal
 * - Providing a scrolling context for the modal body
 * - Locking browser scroll inside the modal, so the page doesn't scroll behind it
 * - Rendering an affordance that there's more content below the fold
 */
export const ModalBody: React.FC<ModalBodyProps> = ({
    children,
    scrollContainerClientId,
    className,
}) => {
    return (
        <ScrollArea
            className={className}
            clientId={scrollContainerClientId}
            lockPageScroll={true}
        >
            {children}
        </ScrollArea>
    );
};
