import { sizes } from "./spacing";

/**
 * @deprecated moved to @smartsheet/lodestar-icons
 */
export type iconSizeTypes = keyof typeof sizes;

/**
 * @deprecated moved to @smartsheet/lodestar-icons
 */
export const iconSizes: Record<iconSizeTypes, string> = {
    xxSmall: "14px",
    xSmall: "16px",
    small: "20px",
    medium: "24px",
    large: "32px",
    xLarge: "48px",
    xxLarge: "64px",
};

/**
 * @deprecated moved to @smartsheet/lodestar-icons
 */
export interface LodestarIconProps {
    clientId?: string;
    color?: string;
    size?: iconSizeTypes;
}
