import styled from "@emotion/styled";
import { neutral } from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import { ScrollAreaGutter } from "../types";

export interface KeylineProps {
    /**
     * gutter is the amount of margin that should be applied to the Keyline component.
     */
    gutter: ScrollAreaGutter;
    /**
     * Is the keyline currently visible?
     */
    isVisible: boolean;
    /**
     * If true, the keyline will render along the top edge of its parent instead of the bottom
     * Defaults to false.
     */
    alignTop?: boolean;
}

const verticalOffset = 0;
const keylineHeight = 2;

export const Keyline = styled.div<KeylineProps>`
    position: absolute;
    bottom: -${verticalOffset + keylineHeight}px;
    left: 0;
    right: 0;
    height: ${keylineHeight}px;
    background-color: ${neutral};
    opacity: 0;
    user-select: none;
    margin: ${(props) => {
        switch (props.gutter) {
            case "none":
                return "0px";
            case "legacy":
                return `0 ${sizes.xLarge}px`;
            default:
                return `0 ${sizes[props.gutter]}px`;
        }
    }};

    ${(props) =>
        props.alignTop &&
        `
        bottom: 0;
        top: -${verticalOffset + keylineHeight}px;
    `};

    ${(props) =>
        props.isVisible &&
        `
        opacity: 1;
    `};
`;
