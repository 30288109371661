import styled from "@emotion/styled";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";

interface ClickableStyles {
    focusRingStyles: string;
    isDisabled: boolean;
}

export const ClickableStyled = withFocusToggleClassName(styled(
    "button"
)<ClickableStyles>`
    // Remove inheritance of text transform in Firefox.
    text-transform: none;

    // Show overflow in Edge.
    overflow: visible;

    // Remove margin in Firefox and Safari.
    margin: 0;

    // Remove padding in webkit.
    padding: 0;

    // Remove default focus outline in various browsers.
    outline: none;

    // Ensure we inherit from parent since many browsers override
    // this in user-agent stylesheet.
    font-family: inherit;

    &::-moz-focus-inner {
        border: 0;
        margin: 0;
        padding: 0;
    }

    // Start off w/ transparent, borderless, nothingness and allow consumers
    // to fill the void.
    background-color: transparent;
    border: none;

    // To anchor the focus ring.
    position: relative;

    cursor: ${({ isDisabled }) => (isDisabled ? "disabled" : "pointer")};

    ${({ focusRingStyles }) => focusRingStyles}
`);

ClickableStyled.displayName = "ClickableStyled";
