import { getKey } from "@smartsheet/cufflink";

/**
 * These are set to match the app-core ClientLogger and GlobalLogManager.
 */
export enum LogLevel {
    WARN = 3,
    ERROR = 4
}

/**
 * The values in this enum number should map to an entry in ClientEventLookup.java in app-core. Action IDs are in the range 0-99.
 *
 * todo: generate and publish from client event lookup.
 */
export enum LogActionId {
    OPEN = 1,
    CLOSE = 2,
    DRAG = 5,
    SUBMIT = 15,
    MOUSE_DOWN = 20,
    MOUSE_UP = 21,
    CLICK = 22,
    DOUBLE_CLICK = 23,
    CONTEXT_CLICK = 24,
    MIDDLE_CLICK = 29,
    SHORTCUT_KEY = 31,
    KEY_STROKE = 32,
    COMPLETE = 53,
    ERROR = 55,
    DIAGNOSTIC = 99
}

export interface LegacyLogger {
    log(logLevel: number, message: string, exception?: Error): void;
    logClientEvent(
        controlType: string,
        controlId: string,
        actionId: LogActionId
    ): void;
    logEventWithoutPrivateInfo(
        objectID: number,
        actionID: number,
        parm1String?: string | number | null,
        parm1Int?: number | null,
        eventMetadata?: Map<string, unknown>
    ): void;
}

/**
 * Interface for logging using the same channel that app-core does.
 */
export interface Logger {
    /**
     * Convenience method to log with a level of ERROR.
     * @param message
     * @param exception
     */
    warn(message: string, exception?: Error): void;

    /**
     * Convenience method to log with a level of ERROR.
     * @param message
     * @param exception
     */
    error(message: string, exception?: Error): void;

    /**
     * Used to log errors and warnings within the application that don't necessarily relate to client events.
     *
     * @param logLevel
     * @param message
     * @param exception
     */
    log(logLevel: LogLevel, message: string, exception?: Error): void;

    /**
     * Logs a React control event to the click stream. All click stream events logged with this method will
     * have the object ID of LOG_ID_CONTROL_GENERIC, since the control ID is a string and cannot be used as
     * the object ID.
     *
     * @param controlType Type of control; not used at this time; see https://wiki.corp.smartsheet.com/display/DEV/Automation+testing.
     * @param controlId ID of the control; see https://wiki.corp.smartsheet.com/display/DEV/Control+IDs.
     * @param actionId Log ID that identifies the relevant action for the log, such as OPEN or CLICK.
     */
    logClientEvent(
        controlType: string,
        controlId: string,
        actionId: LogActionId
    ): void;

    /**
     * DO NOT LOG CUSTOMER DATA USING THIS METHOD!
     *
     * A more general purpose logging method when metadata is required.
     *
     * @param objectID Log ID that identifies the relevant object for the log, such as LOG_ID_SIGNOUT_LINK.
     * This number should map to an entry in ClientEventLookup.java in app-core. Object IDs are in the range 100-19999.
     * @param actionID Log ID that identifies the relevant action for the log, such as OPEN or CLICK.
     * This number should map to an entry in ClientEventLookup.java in app-core. Action IDs are in the range 0-99.
     * @param parm1String Additional string data you wish to log about the event. Even though this method will also take a number, it will be persisted as a string.
     * @param parm1Int Additional numeric data you wish to log. This will be persisted as an integer.
     * @param eventMetadata Additional data of any kind you wish to log with the event, which will be persisted as a JSON object.
     */
    logEventWithoutPrivateInfo(
        objectID: number,
        actionID: LogActionId,
        parm1String?: string | null,
        parm1Int?: number | null,
        eventMetadata?: Map<string, unknown>
    ): void;
}

/**
 * This service concerns itself with the simple act of communicating log strings to the server.
 *
 */
export class LoggingStateService implements Logger {
    constructor(private readonly proxyLogger: LegacyLogger) {}

    public warn = (message: string, exception?: Error): void => {
        this.log(LogLevel.WARN, message, exception);
    };

    public error = (message: string, exception?: Error): void => {
        this.log(LogLevel.ERROR, message, exception);
    };

    public log = (
        logLevel: LogLevel,
        message: string,
        exception?: Error
    ): void => {
        this.proxyLogger.log(logLevel, message, exception);
    };

    public logClientEvent = (
        controlType: string,
        controlId: string,
        actionId: LogActionId
    ): void => {
        this.proxyLogger.logClientEvent(controlType, controlId, actionId);
    };

    public logEventWithoutPrivateInfo = (
        objectID: number,
        actionID: LogActionId,
        parm1String?: string | null,
        parm1Int?: number | null,
        eventMetadata?: Map<string, unknown>
    ): void => {
        this.proxyLogger.logEventWithoutPrivateInfo(
            objectID,
            actionID,
            parm1String,
            parm1Int,
            eventMetadata
        );
    };
}

export const loggingStateServiceKey = getKey(
    "loggingStateService",
    LoggingStateService
);
