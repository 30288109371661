/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgClose: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M17.657 6.343a1.6 1.6 0 010 2.263l-3.394 3.393 3.394 3.395a1.6 1.6 0 11-2.263 2.263L12 14.262l-3.393 3.395a1.6 1.6 0 11-2.263-2.263L9.737 12 6.343 8.606a1.6 1.6 0 112.263-2.263L12 9.736l3.394-3.393a1.6 1.6 0 012.263 0z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
