import { css, keyframes } from "@emotion/core";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { neutralDark40 } from "../../constants/colors";
import {
    SPINNER_CONTAINER_SIZES_MAP,
    SPINNER_DOT_SIZES_MAP,
} from "./constants";
import { SpinnerProps, SpinnerSizes } from "./types";

export const Spinner: React.FC<SpinnerProps> = ({
    clientId,
    color = neutralDark40,
    initialDelay,
    size = "medium",
    ...rest
}) => {
    const [visible, setVisible] = useState(initialDelay === undefined);
    useEffect(() => {
        if (initialDelay) {
            const timer = setTimeout(() => {
                setVisible(true);
            }, initialDelay);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [initialDelay]);
    return (
        <Container size={size} {...rest} data-client-id={clientId}>
            {/* We reserve the space with the container and then fade the visible pieces in. */}
            {visible && (
                <>
                    <TopDot color={color} size={size} />
                    <BottomDot color={color} size={size} />
                </>
            )}
        </Container>
    );
};

const getCubeAnimation = (containerWidth: number, dotSize: number) => {
    const delta = `${containerWidth - dotSize}px`;

    return keyframes`
    25% {
        transform: translate(${delta}, 0);
    }
    50% {
        transform: translate(${delta}, ${delta});
    }
    75% {
        transform: translate(0, ${delta});
    }
    100% {
        transform: translate(0, 0);
    }
`;
};

const LayoutStyles = css`
    box-sizing: border-box;
`;

interface ContainerProps {
    size: SpinnerSizes;
}

const Container = styled.div<ContainerProps>`
    ${LayoutStyles};

    display: block;
    font-size: 0;
    height: ${(props) => SPINNER_CONTAINER_SIZES_MAP[props.size]}px;
    width: ${(props) => SPINNER_CONTAINER_SIZES_MAP[props.size]}px;
    position: relative;
    pointer-events: none;
`;

interface RowProps {
    color: string;
    size: SpinnerSizes;
}

const TopDot = styled.div<RowProps>`
    ${LayoutStyles};

    background-color: ${(props) => props.color};
    width: ${(props) => SPINNER_DOT_SIZES_MAP[props.size]}px;
    height: ${(props) => SPINNER_DOT_SIZES_MAP[props.size]}px;
    display: inline-block;
    float: none;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    animation: ${(props) =>
            getCubeAnimation(
                SPINNER_CONTAINER_SIZES_MAP[props.size],
                SPINNER_DOT_SIZES_MAP[props.size]
            )}
        1.6s 0s infinite cubic-bezier(0.2, 0, 0, 1);
`;

const BottomDot = styled(TopDot)`
    animation-delay: -0.8s;
`;
