/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { components } from "react-select";
import { Spinner } from "../../spinner";

export const LoadingIndicator: typeof components.LoadingIndicator = (props) => (
    <div css={props.getStyles("loadingIndicator", props)} {...props.innerProps}>
        <Spinner size="small" />
    </div>
);
