import React, { useContext } from "react";
import { LodestarCoreTheme } from "../../util/theme";
import { IconWrapper } from "./styled/Radio";

export type RadioIconProps = {
    /* Boolean for field active state */
    isActive?: boolean;
    /* Field checked state */
    isChecked?: boolean;
    /* Field disabled state */
    isDisabled?: boolean;
    /* Field focused state */
    isFocused?: boolean;
    /* Field hovered state */
    isHovered?: boolean;
    /* Field invalid state */
    isInvalid?: boolean;
    onMouseDown?: React.MouseEventHandler;
    onMouseEnter?: React.MouseEventHandler;
    onMouseLeave?: React.MouseEventHandler;
    onMouseUp?: React.MouseEventHandler;
};

export const RadioIcon: React.FC<RadioIconProps> = ({
    isActive,
    isChecked,
    isDisabled,
    isFocused,
    isHovered,
    isInvalid,
    onMouseDown,
    onMouseEnter,
    onMouseLeave,
    onMouseUp,
}) => {
    const theme = useContext(LodestarCoreTheme);
    return (
        <IconWrapper
            themeMode={theme}
            isActive={isActive}
            isChecked={isChecked}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isHovered={isHovered}
            isInvalid={isInvalid}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
        />
    );
};
