/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, useEffect, useContext, forwardRef } from "react";
import { Omit } from "utility-types";
import { warnOnce } from "../../util/devUtil";
import { LodestarCoreTheme, ThemeMode } from "../../util/theme";
import { useEnsuredForwardedRef } from "../../util/useEnsuredForwardedRef";
import {
    BORDER_WIDTH,
    BORDER_WIDTH_OLDESTAR,
    VERTICAL_PADDING,
    LINE_HEIGHT_EM,
    FONT_SIZE,
    SIZING_BUFFER,
    StyledTextarea,
} from "./style";

const DEFAULT_MIN_LINES = 4;
const DEFAULT_MIN_HEIGHT =
    BORDER_WIDTH * 2 +
    VERTICAL_PADDING * 2 +
    DEFAULT_MIN_LINES * Math.floor(FONT_SIZE * LINE_HEIGHT_EM);

export interface TextAreaProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        "onChange" | "value"
    > {
    /** Handler to be called when the input changes. */
    onChange: (
        value: string,
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    /** The value of the textarea. */
    value: string;
    /**
     * A `clientId` prop is provided for specified elements, which is a unique
     * string that appears as a data attribute `data-client-id` in the rendered code,
     * serving as a hook for automated tests */
    clientId?: string;
    /**
     * Don't allow users to manually resize the textarea. This can be advantageous if you want the
     * textarea to always grow along with its content.
     */
    disableManualResize?: boolean;
    /**
     * @deprecated `isDisabled` should be used instead of `disabled`.
     */
    disabled?: boolean;
    /** Flag to determine if the input is disabled */
    isDisabled?: boolean;
    /** The max height of the textarea, e.g. "400px" */
    maxHeight?: string;
    /**
     * The min height of the textarea, e.g. "48px". Text areas are always at least one line tall,
     * so you can use "0" to get that minimal one line height. */
    minHeight?: string;
    /** If "error" then it gets a red border. */
    validationState?: "error";
}

const warnOnDeprecatedDisabledProp = warnOnce(
    "Please use the `isDisabled` prop for <TextArea/> instead of `disabled`. The `disabled` prop has been deprecated due to its issues with a11y."
);

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (props, parentRef) => {
        const {
            // We need an e2e test for this prop.
            // See https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/326
            disableManualResize,
            value,
            onChange,
            minHeight,
            maxHeight,
            clientId,
            isDisabled,
            style: propStyle,
            validationState,
            ...htmlAttributes
        } = props;

        // TODO: remove this after deprecation, use just the `isDisabled` prop.
        const reallyDisabled = isDisabled
            ? isDisabled
            : htmlAttributes.disabled
            ? htmlAttributes.disabled
            : false;

        if (process.env.NODE_ENV !== "production") {
            if (htmlAttributes.disabled) {
                warnOnDeprecatedDisabledProp();
            }
        }

        const theme = useContext(LodestarCoreTheme);
        const oldestar = theme === "oldestar";

        const textAreaRef = useEnsuredForwardedRef(parentRef);

        // The inline value for CSS height. Values transitions might look like:
        // 0 => 60px => 0 => 60px => 0 => 80px ...
        // The momentary "0" value rubber bands the height to the content
        const [height, setHeight] = useState<string>("0");

        // The height as reported by ResizeObserver (available in all browsers
        // except IE). This value changes for 3 reasons:
        // 1. Initial state is reported as soon as you register an observer
        // 2. Auto-grow/shrink based on more or less content
        // 3. Manually dragging the resize handle
        const [observedHeight, setObservedHeight] = useState<number>(0);

        // The height that the user manually dragged the vertical resize handle
        // to.
        const [manualHeight, setManualHeight] = useState<number>(0);

        // The most recently calculated content height, as generated by momentarily
        // setting the height to auto.
        const [contentHeight, setContentHeight] = useState<number>(0);

        function handleOnChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
            if (onChange) {
                onChange(e.target.value, e);
            }
        }

        // Place this effect first so the initial run doesn't reset the height
        useEffect(() => {
            // This momentarily removes the explicit numeric height, so that
            // the native content height can be interrogated.
            setHeight("0");
        }, [value]);

        useEffect(() => {
            if (textAreaRef?.current !== null) {
                const newHeight =
                    textAreaRef.current.scrollHeight +
                    SIZING_BUFFER +
                    (oldestar ? BORDER_WIDTH_OLDESTAR : BORDER_WIDTH) * 2;

                const hasHeightChangedEnough =
                    Math.abs(newHeight - contentHeight) > SIZING_BUFFER;

                if (hasHeightChangedEnough || height === "0") {
                    setHeight(`${manualHeight ? manualHeight : newHeight}px`);
                }

                if (hasHeightChangedEnough) {
                    setContentHeight(newHeight);
                }
            }
        }, [
            contentHeight,
            height,
            manualHeight,
            oldestar,
            textAreaRef,
            textAreaRef.current?.scrollHeight,
        ]);

        useEffect(() => {
            if (disableManualResize || !window.ResizeObserver) {
                return;
            }

            const observer = new ResizeObserver(() => {
                if (textAreaRef.current) {
                    const height =
                        textAreaRef.current.getBoundingClientRect().height;
                    setObservedHeight(height);
                }
            });

            if (textAreaRef.current) {
                observer.observe(textAreaRef.current);
            }

            return () => observer.disconnect();
        }, [textAreaRef, height, disableManualResize]);

        // Need this to only run when observedHeight changes, so not
        // including contentHeight despite the lint warning. If we included
        // contentHeight as well, the box erroroneously updates
        // manualHeight when no drag handle operation occurred.
        useEffect(() => {
            if (disableManualResize) {
                return;
            }
            // ResizeObserver will notify regardless of if the change was programmatic
            // (smart resize) or manual (drag handle). To disambiguate we can use a bit
            // of lookbehind to compare the last calculated content height with the
            // currently reported ResizeObserver height. This avoids ugly hacks like
            // timers.
            if (Math.abs(observedHeight - contentHeight) > SIZING_BUFFER) {
                setManualHeight(observedHeight);
            }
        }, [observedHeight, disableManualResize]); // eslint-disable-line react-hooks/exhaustive-deps

        // Disable drag handle in IE
        const resizeStyleOverride: React.CSSProperties = {
            resize: window.ResizeObserver ? undefined : "none",
        };

        return (
            <StyledTextarea
                disableManualResize={disableManualResize}
                value={value}
                onChange={handleOnChange}
                ref={textAreaRef}
                validationState={validationState}
                style={{
                    ...propStyle,
                    height,
                    maxHeight: maxHeight,
                    minHeight: minHeight || DEFAULT_MIN_HEIGHT,
                    ...resizeStyleOverride,
                }}
                data-client-id={clientId}
                themeMode={useContext<ThemeMode>(LodestarCoreTheme)}
                aria-disabled={reallyDisabled}
                {...htmlAttributes}
                readOnly={
                    reallyDisabled
                        ? true
                        : htmlAttributes.readOnly
                        ? htmlAttributes.readOnly
                        : false
                }
            />
        );
    }
);
