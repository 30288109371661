import React, { ComponentType, HTMLAttributes, useEffect } from "react";
import ReactFocusLock from "react-focus-lock";
import { Omit } from "utility-types";
import { layers } from "../../../constants/layers";
import { useStackOrder } from "../../../util/StackOrder";
import { LayerContextProvider } from "../../../util/layerContext/LayerContext";
import { Blanket } from "../../blanket";
import { Portal, PortalProps } from "../../portal";
import { WIDTH_ENUM, WidthNames } from "../shared-variables";
import {
    StyledModalContainer,
    FillScreen as StyledFillScreen,
    PositionerAbsolute,
    modalWidth as getModalWidth,
} from "../styled/Modal";
import { ModalAnimation } from "./ModalAnimation";
import { ModalTransition, ModalTransitionConsumer } from "./ModalTransition";

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    /**
    Content of the modal
    */
    children: React.ReactNode;

    /**
     True to show the modal, false to close it.
     */
    isOpen: boolean;

    /**
     Function that will be called when the modal intends to close; i.e. The Esc key is pressed or the
     Overlay is clicked.

     Consumers should set their state that is driving the visibility of the modal here. The modal will
     not close unless you do so (see
     [controlled components](https://goshakkk.name/controlled-vs-uncontrolled-inputs-react/) for more info).
     */
    onCloseRequested: () => void;

    /**
    A `clientId` prop is provided for specified elements, which is a unique string that
    appears as a data attribute `data-client-id` in the rendered code, serving as a hook
    for automated tests
    */
    clientId?: string;

    /**
     * Overrides for sub-components in the Modal.
     */
    components?: {
        Portal?: ComponentType<PortalProps>;
    };

    /**
     Ignore any provided width/height and consume the entire screen. There will be no Overlay.
    */
    fullScreen?: boolean;
    /**
     *  Height of the vertical space between the edges of the window and the top and bottom of the modal.
     *  Takes either a px number value or a string percent in the format `<percent>%`
     *  Defaults to 60px. Does nothing if fullScreen is set to true.
     */
    gutterHeight?: number | string;

    /**
     Height of the modal. If not set, the modal grows to fit the content until it
     runs out of vertical space, at which point scrollbars appear. If a number is
     provided, the height is set to that number in pixels. A string including pixels,
     or a percentage, will be directly applied as a style. Note that separate from height there
     is also gutterHeight, which dictates the minimum space between the edges of the window and
     the bottom and top of the modal. If height is 100% there will still be gutters unless gutterHeight
     is set to 0.
     */
    height?: number | string;

    /**
     * Called when focus changes to a new element. If you return true here, the Modal's focus
     * management will act on the given focus change to maintain focus within this Modal. 
     * If you return false, the Modal will ignore the focus changes and potentially allow focus
     * to leave the Modal. You can use this as a last resport to manage complex modal layers or
     * in cases where other things might be managing focus as well.
     * 
     * By default the entire DOM is managed. Use this to relax that constraint.

     */
    inFocusLock?: (activeElement: HTMLElement) => boolean;

    /**
     Function that will be called when the exit transition is complete.
     */
    onCloseComplete?: (element: HTMLElement) => void;
    /**
     Function that will be called when the enter transition is complete.
     */
    onOpenComplete?: (node: HTMLElement, isAppearing: boolean) => void;

    /**
     * Props to pass to the underlying Portal if you need to customize its behavior.
     */
    portalProps?: Partial<Omit<PortalProps, "children">>;

    /**
     Boolean indicating if pressing the `esc` key should close the modal.
     */
    shouldCloseOnEscapePress?: boolean;

    /**
     Boolean indicating if clicking the overlay should close the modal.
     */
    shouldCloseOnOverlayClick?: boolean;

    /**
     Width of the modal. This can be provided in three different ways.
     If a number is provided, the width is set to that number in pixels.
     A string including pixels, or a percentage, will be directly applied as a style.
     Several size options are also recognised (see WidthNames).
     */
    width?: number | string | WidthNames;
}

/**
 * The Modal component renders a modal/dialog on top of the page, above its content.
 * By itself, the Modal component handles most of the behavior of a modal, and is unopinionated
 * about its content--it's more or less a blank canvas.
 * That said, we have created some basic components that you can compose into Modal that aim
 * to handle most use-cases (see index.ts for this list).
 *
 * Modal is responsible for the following behaviors:
 * - Escape to close (if within a ModalStackIndexContext, multiple modals will be handled gracefully)
 * - Locking the browser scrolling so that the page behind the modal won't scroll
 * - Enter/Exit animations
 * - Modal positioning
 * - Portaling to escape parent overflow and scroll contexts
 * - Keeping browser tab focus within the modal
 */
export const Modal: React.FC<ModalProps> = ({
    fullScreen = false,
    shouldCloseOnEscapePress,
    shouldCloseOnOverlayClick = true,
    width = "medium",
    onCloseRequested,
    children,
    height,
    onCloseComplete,
    onOpenComplete,
    portalProps,
    clientId,
    components = {},
    isOpen,
    inFocusLock,
    gutterHeight,
    ...htmlAttributes
}) => {
    const { isActiveItem, isWithinStack } = useStackOrder({
        isOpen,
        onCloseRequested,
        shouldCloseOnEscape:
            shouldCloseOnEscapePress !== undefined
                ? // If this is defined, use it.
                  shouldCloseOnEscapePress
                : // Otherwise, fullScreen modals don't close on esc by default, but normal ones do.
                  !fullScreen,
    });

    const PortalComponent = (components && components.Portal) || Portal;

    /**
     * Hook up the scroll handler
     */
    useEffect(() => {
        if (isOpen) {
            // Prevent window from being scrolled programmatically so that the modal is positioned correctly
            // and to prevent scrollIntoView from scrolling the window.
            const handleWindowScroll = (): void => {
                window.scrollTo(window.pageXOffset, 0);
            };

            window.addEventListener("scroll", handleWindowScroll);

            return () => {
                window.removeEventListener("scroll", handleWindowScroll);
            };
        }
    }, [isOpen]);

    const handleOverlayClick: React.MouseEventHandler<HTMLElement> = (
        e
    ): void => {
        e.stopPropagation();
        if (shouldCloseOnOverlayClick) {
            onCloseRequested();
        }
    };

    const handleOverlayDoubleClick: React.MouseEventHandler<HTMLElement> = (
        e
    ): void => {
        e.stopPropagation();
    };

    const heightValue = fullScreen ? "100vh" : height;
    // If width prop matches the width enum, allow styled component to consume as named prop.
    // Otherwise it's a custom width value (number, percentage, or string) and it will be consumed directly
    const widthName =
        width &&
        WIDTH_ENUM.values.indexOf(width.toString() as WidthNames) !== -1 &&
        !fullScreen
            ? (width as WidthNames)
            : undefined;
    // redefine widthValue if a widthName is discovered
    const widthValue = fullScreen
        ? "fullScreen"
        : widthName
        ? undefined
        : width;
    const modalWidth = getModalWidth({
        widthName,
        widthValue,
    });

    const sharedBody = (
        <StyledModalContainer
            heightValue={heightValue}
            role="dialog"
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
            {...htmlAttributes}
            data-client-id={clientId}
        >
            {children}
        </StyledModalContainer>
    );

    const modalBody = (slide: React.CSSProperties) =>
        fullScreen ? (
            sharedBody
        ) : (
            <>
                <Blanket
                    isTinted
                    onClick={handleOverlayClick}
                    onDoubleClick={handleOverlayDoubleClick}
                    clientId={
                        clientId ? `modal-blanket-${clientId}` : "modal-blanket"
                    }
                />
                <PositionerAbsolute
                    style={slide}
                    widthValue={modalWidth}
                    gutterHeight={gutterHeight}
                    data-client-id="positioner-absolute"
                >
                    {sharedBody}
                </PositionerAbsolute>
            </>
        );

    return (
        <LayerContextProvider defaultLayer="modal">
            <ModalTransition>
                {isOpen && (
                    <ModalTransitionConsumer>
                        {({ isOpen, onExited }) => (
                            <PortalComponent
                                zIndex={layers.modal}
                                {...portalProps}
                            >
                                <ModalAnimation
                                    in={isOpen}
                                    onExited={(e) => {
                                        onCloseComplete && onCloseComplete(e);
                                        onExited && onExited();
                                    }}
                                    onEntered={onOpenComplete}
                                >
                                    {({ fade, slide }) => (
                                        <StyledFillScreen style={fade}>
                                            <ReactFocusLock
                                                whiteList={inFocusLock}
                                                disabled={
                                                    (isWithinStack &&
                                                        !isActiveItem) ||
                                                    !isOpen
                                                }
                                                // This rule doesn't apply here because we really DO
                                                // want to turn this behavior off :)
                                                // eslint-disable-next-line jsx-a11y/no-autofocus
                                                autoFocus={false}
                                            >
                                                {modalBody(slide)}
                                            </ReactFocusLock>
                                        </StyledFillScreen>
                                    )}
                                </ModalAnimation>
                            </PortalComponent>
                        )}
                    </ModalTransitionConsumer>
                )}
            </ModalTransition>
        </LayerContextProvider>
    );
};
