import { css, Global } from "@emotion/core";
import React from "react";
import { neutralDark40, toRgba } from "../constants/colors";
import { scrollbarClassNames } from "../constants/scrollbars";
import { sizes } from "../constants/spacing";

export const ScrollbarStyles: React.FC = () => {
    const styles = css`
        * {
            scrollbar-color: ${toRgba(neutralDark40, 0.4)} transparent;

            /* set the exact width of the scrollbar */
            &::-webkit-scrollbar {
                width: ${sizes.medium}px;
            }

            &::-webkit-scrollbar-track {
                background-color: transparent;
            }

            &::-webkit-scrollbar-corner {
                background: transparent;
            }

            &::-webkit-scrollbar-thumb {
                /* make the thumb appear smaller, half the width of the track */
                border: ${sizes.medium / 4}px solid transparent;
                /* round the corners of the thumb */
                border-radius: ${sizes.medium / 2}px;
                /* constrain the background color to the padding box */
                background-clip: padding-box;
                /* set the thumb color */
                background-color: ${toRgba(neutralDark40, 0.4)};
                min-height: 40px;
                min-width: 40px;
            }
        }

        /* scrollbar width override for slim styles */
        .${scrollbarClassNames.slim} {
            scrollbar-width: thin;
            &::-webkit-scrollbar {
                height: ${sizes.xSmall}px;
                width: ${sizes.xSmall}px;
            }
            &::-webkit-scrollbar-thumb {
                border-width: ${sizes.xSmall / 4}px;
                border-radius: ${sizes.xSmall / 2}px;
            }
        }
    `;

    return <Global styles={styles} />;
};
