/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgPlus: React.FC<React.SVGAttributes<SVGElement>> = (props) => (
    <svg viewBox="0 0 14 14" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h14v14H0z" />
            <path
                fill="currentColor"
                d="M7 2a.75.75 0 01.75.75v3.5h3.5a.75.75 0 010 1.5h-3.5v3.5a.75.75 0 01-1.5 0V7.749l-3.5.001a.75.75 0 010-1.5l3.5-.001V2.75A.75.75 0 017 2z"
            />
        </g>
    </svg>
);
