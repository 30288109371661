import React from "react";
import { IconSizeTypes, iconSizes } from "./icon";

const sizes = Object.keys(iconSizes) as IconSizeTypes[];

export type SizeToResourceMap = Partial<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<IconSizeTypes, React.ComponentType<any>>
>;

// Array.findIndex() is not supported in IE11, so write our own
const arrayFindIndex = <T>(array: T[], value: T): number => {
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return i;
        }
    }
    return -1;
};

/**
 * Returns the SVG asset that is closest to the requested size
 */
export const getSvgResourceForSize = (
    sizeToResourceMap: SizeToResourceMap,
    size: IconSizeTypes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.ComponentType<any> => {
    // Have to save this value to a variable or else TS will complain
    const resource = sizeToResourceMap[size];
    if (resource) {
        // If we have a resource at the desired size, return that resource
        return resource;
    } else {
        const sizeIndex = arrayFindIndex(sizes, size);
        let smallerIndex = sizeIndex - 1;
        let largerIndex = sizeIndex + 1;
        while (smallerIndex >= 0 || largerIndex < sizes.length) {
            // Check if there is a smaller asset first that is optimized close to
            // the desired size
            if (smallerIndex >= 0) {
                const smallerSize = sizes[smallerIndex];
                const smallerResource = sizeToResourceMap[smallerSize];
                if (smallerResource) {
                    return smallerResource;
                }
            }
            if (largerIndex < sizes.length) {
                const largerSize = sizes[largerIndex];
                const largerResource = sizeToResourceMap[largerSize];
                if (largerResource) {
                    return largerResource;
                }
            }
            smallerIndex--;
            largerIndex++;
        }
    }
    throw Error(
        `Could not find any valid assets in the set ${Object.keys(
            sizeToResourceMap
        ).toString()}`
    );
};

/**
 * Compute the default icon size given the map of available assets. Will default
 * to "medium" if present; otherwise finds the closest available asset size.
 */
export const getDefaultSize = (
    sizeToResourceMap: SizeToResourceMap
): IconSizeTypes => {
    if (sizeToResourceMap["medium"]) {
        return "medium";
    }
    const sizeIndex = arrayFindIndex(sizes, "medium");
    let smallerIndex = sizeIndex - 1;
    let largerIndex = sizeIndex + 1;
    while (smallerIndex >= 0 || largerIndex < sizes.length) {
        // For default size, prefer larger over smaller assets
        if (largerIndex < sizes.length) {
            const largerSize = sizes[largerIndex];
            if (sizeToResourceMap[largerSize]) {
                return largerSize;
            }
        }
        if (smallerIndex >= 0) {
            const smallerSize = sizes[smallerIndex];
            if (sizeToResourceMap[smallerSize]) {
                return smallerSize;
            }
        }
        smallerIndex--;
        largerIndex++;
    }
    // If no sizes found, default to medium
    return "medium";
};
