import React, { ReactNode, useState, useEffect } from "react";

interface Props {
    /**
    Children that are conditionally rendered. The transition happens based
    on the existence or non-existence of children.
  */
    children?: React.ReactNode;
}

const { Consumer, Provider } = React.createContext({
    isOpen: true,
    onExited: (): void => undefined,
});

// checks if children exist and are truthy
const hasChildren = (children: React.ReactNode): boolean =>
    React.Children.count(children) > 0 &&
    React.Children.map(children || [], (child) => !!child).filter(Boolean)
        .length > 0;

export const ModalTransition: React.FC<Props> = ({ children }) => {
    const [previousChildrenForUnmount, setPreviousChildrenForUnmount] =
        useState<ReactNode>(undefined);

    /**
     * This effect's only job is to track children at each render.
     * When the component unmounts i.e. its children are unmounted, it will
     * set the most recently-tracked children as the current children.
     * This allows the Modal to retain its contents for the duration of the animation,
     * after which onExited will be called and we set that cache to null.
     */
    useEffect(() => {
        const exiting =
            hasChildren(previousChildrenForUnmount) && !hasChildren(children);
        setPreviousChildrenForUnmount(
            exiting ? previousChildrenForUnmount : children
        );
    }, [previousChildrenForUnmount, children]);

    const onExited = (): void => {
        setPreviousChildrenForUnmount(null);
    };

    return (
        <Provider
            value={{
                onExited: onExited,
                isOpen: hasChildren(children),
            }}
        >
            {children || previousChildrenForUnmount}
        </Provider>
    );
};

export const ModalTransitionConsumer = Consumer;
