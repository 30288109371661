import { StyledComponent } from "@emotion/styled";
import {
    AlertErrorAltIcon,
    AlertWarningIcon,
    CheckFilledIcon,
    IconProps,
    InfoFilledIcon,
} from "@smartsheet/lodestar-icons";
import { ColorProperty } from "csstype";
import React, {
    forwardRef,
    ForwardRefExoticComponent,
    HTMLAttributes,
    useMemo,
} from "react";
import { neutralLight40, neutralDark40 } from "../../constants/colors";
import { BannerType } from "./BannerTypes";
import { BannerContextProvider } from "./components/BannerContext";
import { BannerIconContainer } from "./styled/BannerIconContainer";
import { BannerStyled } from "./styled/BannerStyled";

export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * The type of the Banner will change its styling.
     */
    type: BannerType;

    /**
     * A `clientId` prop is a unique string that appears as a data attribute
     * `data-client-id` in the rendered code, serving as a hook for automated tests
     */
    clientId?: string;

    /**
     * Overrides for different internally-managed components.
     *
     * - `Icon`: If present, will be used instead of the default icon based on
     *           the type prop. If set to `null`, the icon will not be rendered.
     *           See `IconProps` from @smartsheet/lodestar-icons.
     */
    components?: {
        Icon?:
            | ForwardRefExoticComponent<IconProps>
            // eslint-disable-next-line @typescript-eslint/ban-types
            | StyledComponent<IconProps, object, object>
            | null;
    };
}

interface AlertBannerPreset {
    iconColor: ColorProperty;
    iconComponent: ForwardRefExoticComponent<IconProps>;
    labelKey: string;
}

const presets: Record<BannerType, AlertBannerPreset> = {
    error: {
        iconComponent: AlertErrorAltIcon,
        iconColor: neutralLight40,
        labelKey: "lbl_generic_error",
    },
    success: {
        iconComponent: CheckFilledIcon,
        iconColor: neutralDark40,
        labelKey: "lbl_success",
    },
    warning: {
        iconComponent: AlertWarningIcon,
        iconColor: neutralDark40,
        labelKey: "lbl_warning",
    },
    info: {
        iconComponent: InfoFilledIcon,
        iconColor: neutralDark40,
        labelKey: "lbl_info",
    },
} as const;

const getIcon = (
    type: BannerType,
    overrides: BannerProps["components"] = {}
) => {
    if (overrides.Icon === null) {
        return null;
    }

    return overrides.Icon || presets[type].iconComponent;
};

export const Banner = forwardRef<HTMLDivElement, BannerProps>(
    ({ children, type, components = {}, clientId, ...rest }, ref) => {
        const IconComponent = getIcon(type, components);
        const { iconColor } = presets[type];

        const memoizedBannerContextValue = useMemo(() => ({ type }), [type]);

        return (
            <BannerContextProvider value={memoizedBannerContextValue}>
                <BannerStyled
                    type={type}
                    role="alert"
                    data-client-id={clientId}
                    tabIndex={0}
                    ref={ref}
                    {...rest}
                >
                    {IconComponent && (
                        <BannerIconContainer aria-hidden={true}>
                            <IconComponent color={iconColor} />
                        </BannerIconContainer>
                    )}
                    {children}
                </BannerStyled>
            </BannerContextProvider>
        );
    }
);

Banner.displayName = "Banner";
