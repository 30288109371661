import { ClassNames } from "@emotion/core";
import React, { ReactNode, useContext } from "react";
import {
    neutralAccessible,
    neutralDark40,
    neutralLight10,
} from "../../constants/colors";
import { LodestarCoreTheme } from "../../util/theme";
import * as Theme from "../tag/TagTheme";
import { TagColor, CustomColor } from "../tag/TagTypes";
import { CloseButton, CloseRestSvg, CloseHoverSvg } from "./styled/CloseButton";
import { TokenContainer } from "./styled/TokenContainer";
import { TokenImageContainer } from "./styled/TokenImageContainer";
import { TokenLabel } from "./styled/TokenLabel";

export interface TokenProps {
    /**
     * This children to be rendered inside this Token.
     */
    children: ReactNode;
    /** Called when the close icon is clicked. */
    onCloseSelected: React.MouseEventHandler<HTMLButtonElement>;
    /**
     * Identifies the element (or elements) that describes the object.
     */
    "aria-describedby"?: string;
    /**
     * Defines a string value that labels the current element.
     */
    "aria-label"?: string;
    /**
     * Identifies the element (or elements) that labels the current element.
     */
    "aria-labelledby"?: string;
    /**  A classname to be applied to the root element of this component. */
    className?: string;
    /**
     A `clientId` prop is provided for specified elements, which is a unique string that
     appears as a data attribute `data-client-id` in the rendered code, serving as a hook
     for automated tests
     */
    clientId?: string;
    /** The color theme to apply, or custom color object comprised of css values for `backgroundColor` and `textColor`. */
    color?: TagColor | CustomColor;
    /**
     * An Avatar or Icon node to display in front of the text label.
     */
    image?: ReactNode;
    /** Sets the token to be disabled, with a changed hover state and changed styling. */
    isDisabled?: boolean;
    /** Handler for mouse down on the Token */
    onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
}

export const Token: React.FC<TokenProps> = ({
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledby,
    "aria-describedby": ariaDescribedBy,
    image,
    color = "blueLight",
    children,
    className,
    onCloseSelected,
    clientId,
    onMouseDown,
    isDisabled = false,
}) => {
    const theme = useContext(LodestarCoreTheme);
    let backgroundColor, textColor;
    if (isDisabled) {
        backgroundColor = neutralLight10;
        textColor = neutralAccessible;
    } else if (typeof color === "string") {
        backgroundColor = Theme.backgroundColor(color, theme);
        textColor = neutralDark40;
    } else {
        ({ backgroundColor, textColor } = color);
    }
    return (
        <TokenContainer
            aria-label={ariaLabel}
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedBy}
            backgroundColor={backgroundColor}
            textColor={textColor}
            className={className}
            data-client-id={clientId}
            onMouseDown={onMouseDown}
            aria-disabled={isDisabled || undefined}
        >
            {image && (
                <TokenImageContainer aria-hidden>{image}</TokenImageContainer>
            )}
            <TokenLabel>{children}</TokenLabel>
            <ClassNames>
                {({ css, cx }) => {
                    return (
                        <CloseButton
                            aria-label="Remove"
                            aria-disabled={isDisabled}
                            onClick={isDisabled ? undefined : onCloseSelected}
                            themeMode={theme}
                            isDisabled={isDisabled}
                            type="button"
                        >
                            {!isDisabled && (
                                <CloseHoverSvg
                                    className={cx(
                                        "token--icon--hover",
                                        css`
                                            display: none;
                                        `
                                    )}
                                />
                            )}
                            <CloseRestSvg className={"token--icon-rest"} />
                        </CloseButton>
                    );
                }}
            </ClassNames>
        </TokenContainer>
    );
};
