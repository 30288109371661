export interface Cuff {
  render(target: HTMLElement): void;
  destroy(): void;
}

export interface CuffConstructor {
  new(): Cuff;
}

export interface ClientKey<K extends string, V> {
  readonly name: K;
  readonly val?: V | undefined;
}

type Constructor<T> = { new(...args: any[]): T };

export function getKey<K extends string, V>(keyName: K, _keyTypetype: Constructor<V>): ClientKey<K, V> {
  return { name: keyName };
}

export type ValType<T extends ClientKey<any, any>> = T extends ClientKey<any, infer V> ? V : any;


const CUFF_LINK_WINDOW_PROP = "$$$cuffLink";
declare var window: {
  [CUFF_LINK_WINDOW_PROP]: Map<string, any> | undefined;
};

export class CuffLink {

  private static getRegistry(): Map<string, any> {
    if (!window[CUFF_LINK_WINDOW_PROP]) {
      window[CUFF_LINK_WINDOW_PROP] = new Map<string, any>();
    }
    return window[CUFF_LINK_WINDOW_PROP]!;
  }

  public static register<T extends ClientKey<any, any>>(key: T, thing: ValType<T>) {
    this.getRegistry().set(key.name, thing);
  }

  public static fetch<T extends ClientKey<any, any>>(key: T): ValType<T> {
    const obj = this.getRegistry().get(key.name);
    if (!obj) {
      throw new Error("Unable to fetch " + key.name);
    }
    return obj;
  }
}
