/**
 * This file is generated from 'npm run build-svg'. Any changes should be made to the
 * template in .svgrrc.js or to the source SVG in src/icons/svg.
 **/
import React from "react";
export const SvgArrowDown: React.FC<React.SVGAttributes<SVGElement>> = (
    props
) => (
    <svg viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                fill="currentColor"
                d="M11.584 14.376L8.518 9.777A.5.5 0 018.934 9h6.132a.5.5 0 01.416.777l-3.066 4.599a.5.5 0 01-.832 0z"
            />
            <path d="M0 0h24v24H0z" />
        </g>
    </svg>
);
