import React, {
    ButtonHTMLAttributes,
    forwardRef,
    MouseEventHandler,
    ReactNode,
    useCallback,
    useMemo,
} from "react";
import { CreateFocusRingProps, getFocusRingStyles } from "../../util/focusRing";
import { ClickableStyled } from "./styled/ClickableStyled";

export interface ClickableProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
    children: ReactNode;

    clientId?: string;
    focusRingOptions?: Partial<CreateFocusRingProps>;
    isDisabled?: boolean;
    omitFocusRing?: boolean;
}

/**
 * Provides the basic stuff for any clickables, include a style reset!
 */
export const Clickable = forwardRef<HTMLButtonElement, ClickableProps>(
    (
        {
            children,
            clientId,
            omitFocusRing = false,
            focusRingOptions = {},
            isDisabled = false,
            onClick: providedOnClick,
            onDoubleClick: providedOnDoubleClick,
            tabIndex = 0,
            type = "button",
            ...rest
        },
        ref
    ) => {
        const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (isDisabled) {
                    e.preventDefault();
                    return;
                }
                if (providedOnClick) {
                    providedOnClick(e);
                }
            },
            [isDisabled, providedOnClick]
        );

        const onDoubleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (isDisabled) {
                    e.preventDefault();
                    return;
                }
                if (providedOnDoubleClick) {
                    providedOnDoubleClick(e);
                }
            },
            [isDisabled, providedOnDoubleClick]
        );

        const focusRingStyles = useMemo(
            () =>
                omitFocusRing
                    ? ""
                    : getFocusRingStyles({
                          theme: "light",
                          offset: 4,
                          radius: 4,
                          includeFocusPseudoClass: true,
                          ...focusRingOptions,
                      }),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [omitFocusRing, ...Object.values(focusRingOptions)]
        );

        return (
            <ClickableStyled
                {...rest}
                isDisabled={isDisabled}
                aria-disabled={isDisabled}
                data-client-id={clientId}
                focusRingStyles={focusRingStyles}
                ref={ref}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                tabIndex={tabIndex}
                type={type}
            >
                {children}
            </ClickableStyled>
        );
    }
);

Clickable.displayName = "Clickable";
