import React, {
    ButtonHTMLAttributes,
    forwardRef,
    MouseEventHandler,
    useCallback,
} from "react";
import { useFocusModeListener } from "../../../util/useFocusModeListener";
import { BannerActionStyled } from "../styled/BannerActionStyled";

export interface BannerActionProps
    extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled"> {
    /**
     * A `clientId` prop is provided for specified elements, which is a
     * unique string that appears as a data attribute `data-client-id` in
     * the rendered code, serving as a hook for automated tests
     */
    clientId?: string;

    /**
     * If true, will disable interactions and apply aria-disabled.
     * Will still be focusable.
     */
    isDisabled?: boolean;
}

export const BannerAction = forwardRef<HTMLButtonElement, BannerActionProps>(
    (
        {
            children,
            clientId,
            isDisabled,
            onClick: providedOnClick,
            onDoubleClick: providedOnDoubleClick,
            ...rest
        },
        ref
    ) => {
        const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (isDisabled) {
                    e.preventDefault();
                    return;
                }
                if (providedOnClick) {
                    providedOnClick(e);
                }
            },
            [isDisabled, providedOnClick]
        );

        const onDoubleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (isDisabled) {
                    e.preventDefault();
                    return;
                }
                if (providedOnDoubleClick) {
                    providedOnDoubleClick(e);
                }
            },
            [isDisabled, providedOnDoubleClick]
        );

        const focusMode = useFocusModeListener();

        return (
            <BannerActionStyled
                focusMode={focusMode}
                tabIndex={0}
                aria-disabled={isDisabled}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                type="button"
                data-client-id={clientId}
                {...rest}
                ref={ref}
            >
                {children}
            </BannerActionStyled>
        );
    }
);

BannerAction.displayName = "BannerAction";
