const LARGE_BUTTON = {
    height: 40,
    fontSize: 14,
};
const MEDIUM_BUTTON = {
    height: 32,
    fontSize: 13,
};

export const BUTTON_STYLE_PROPERTIES_BY_SIZE_MAP = {
    large: LARGE_BUTTON,
    medium: MEDIUM_BUTTON,
};
