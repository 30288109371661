/**
 * Component used for consistent, deterministic horizontal spacing.
 * Adapted from https://github.com/seek-oss/braid-design-system
 */
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import React, { Children, forwardRef, HTMLAttributes, ReactNode } from "react";
import flattenChildren from "react-keyed-flatten-children";
import { sizeNames, sizes } from "../../constants/spacing";
import { cloneElement } from "./layoutUtil";

const Container = styled.div<{ space: number }>`
    // Apply padding top to this, and add an extra -1 to the
    // padding-top of the first child to prevent margin collapsing.
    padding-top: 1px;

    &::before {
        // Negative margin-top applied to the parent to shift everything up,
        // then apply the positive padding-top to each child.
        margin-top: ${({ space }) => -space - 1}px;
        content: "";
        display: block;
    }
`;

const Inner = styled.div<{
    space: number;
}>`
    margin-left: ${({ space }) => -space}px;
    display: flex;
    flex-wrap: wrap;
`;

export interface InlineProps extends HTMLAttributes<HTMLDivElement> {
    /**
     * The list of children to render inline.
     *
     * ⚠️ All child components MUST accept a className prop and
     * apply it to their root element to work properly in `Inline`.
     */
    children: ReactNode;

    /**
     * How much space should be included vertically and horizontally between each child.
     */
    space: sizeNames;

    /**
     * Attributes to forward to inner wrapper element.
     */
    innerWrapperProps?: HTMLAttributes<HTMLDivElement>;
}

export const Inline = forwardRef<HTMLDivElement, InlineProps>(
    ({ children, space, innerWrapperProps, ...rest }, ref) => {
        const spaceInPx = typeof space === "number" ? space : sizes[space];
        const childStyles = css`
            min-width: 0;
            margin-top: ${spaceInPx}px;
            margin-left: ${spaceInPx}px;
        `;
        return (
            <Container space={spaceInPx} {...rest} ref={ref}>
                <Inner space={spaceInPx} {...innerWrapperProps}>
                    {Children.map(flattenChildren(children), (child) =>
                        child !== null && child !== undefined
                            ? cloneElement(child, {
                                  css: childStyles,
                              })
                            : null
                    )}
                </Inner>
            </Container>
        );
    }
);

Inline.displayName = "Inline";
