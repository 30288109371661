import React, { forwardRef, ReactNode, useContext, useState } from "react";
import { Omit } from "utility-types";
import * as colors from "../../constants/colors";
import { warnOnce } from "../../util/devUtil";
import { LodestarCoreTheme } from "../../util/theme";
import { useEnsuredForwardedRef } from "../../util/useEnsuredForwardedRef";
import {
    BoxStyledOuterContainer,
    UnderlineStyledOuterContainer,
    StyledContentBeforeContainer,
    StyledInputContainer,
    StyledContentAfterContainer,
    StyledInput,
} from "./style";

/**
 * Used to provide the left and right customizations access to the current state of the TextInput.
 */
interface InnerState {
    isFocused: boolean;
    isHovered: boolean;
}

// Omitting "style" because, it's ambiguous as to which sub element
// the style should go on (the input itself or the wrapper).
// Omitting "defaultValue" because we're only support controlled use
// cases right now.
export interface TextInputProps
    extends Omit<
        React.InputHTMLAttributes<HTMLInputElement>,
        "onChange" | "value" | "style" | "defaultValue"
    > {
    /** Handler to be called when the input changes. */
    onChange: (
        value: string,
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.MouseEvent<HTMLButtonElement>
    ) => void;
    /** The value of the text-area. */
    value: string;
    /**
     * A classname to be applied to the root element of the component.
     */
    className?: string;
    /**
     * A `clientId` prop is provided for specified elements, which is a unique
     * string that appears as a data attribute `data-client-id` in the rendered code,
     * serving as a hook for automated tests */
    clientId?: string;
    /**
     * Piece of UI to show after the typeable area, normally a 20x20 icon.
     */
    contentAfter?: ReactNode | ((state: InnerState) => ReactNode);
    /**
     * Piece of UI to show before the typeable area, normally a 20x20 icon.
     */
    contentBefore?: ReactNode | ((state: InnerState) => ReactNode);
    /**
     * @deprecated `isDisabled` should be used instead of `disabled`.
     */
    disabled?: boolean;
    /** Flag to determine if the input is disabled */
    isDisabled?: boolean;
    /**
     * @deprecated `contentBefore` should be used instead of `leftIcon`.
     */
    leftIcon?: ReactNode | ((state: InnerState) => ReactNode);
    /**
     * @deprecated `contentAfter` should be used instead of `rightIcon`.
     */
    rightIcon?: ReactNode | ((state: InnerState) => ReactNode);
    /** Type of text box. We're not simply passing it through raw since we want to disallow radio, checkbox, etc. */
    type?: "text" | "email" | "number" | "tel" | "url" | "password";
    /** If "error" then it gets a red border. */
    validationState?: "error";
    /** "box" gives a border all around, "underline" just a bottom border */
    variant?: "box" | "underline";
}

const warnOnDeprecatedDisabledProp = warnOnce(
    "Please use the `isDisabled` prop for <TextInput/> instead of `disabled`. The `disabled` prop has been deprecated due to its issues with a11y."
);

const warnOnDeprecatedLeftIconProp = warnOnce(
    "Please use the `contentBefore` prop for <TextInput/> instead of `leftIcon`. The `leftIcon` prop has been deprecated for better RTL better support."
);

const warnOnDeprecatedRightIconProp = warnOnce(
    "Please use the `contentAfter` prop for <TextInput/> instead of `rightIcon`. The `rightIcon` prop has been deprecated for better RTL better support."
);

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (props, parentRef) => {
        const {
            contentAfter,
            contentBefore,
            className,
            value,
            onChange,
            clientId,
            variant = "box",
            type = "text",
            leftIcon,
            rightIcon,
            onFocus,
            onBlur,
            validationState,
            isDisabled, // TODO - assign default `false` value again after `disabled` is fully removed
            ...htmlAttributes
        } = props;

        const [isFocused, setIsFocused] = useState<boolean>(false);
        const [isHovered, setIsHovered] = useState<boolean>(false);

        // TODO: remove this after deprecation, use just the `isDisabled` prop.
        const reallyDisabled =
            isDisabled !== undefined
                ? isDisabled
                : htmlAttributes.disabled
                ? htmlAttributes.disabled
                : false;

        // TODO: Remove this in https://git.lab.smartsheet.com/team-ui-engineering/lodestar-core/-/issues/516
        const realContentBefore =
            contentBefore !== undefined ? contentBefore : leftIcon;
        const realContentAfter =
            contentAfter !== undefined ? contentAfter : rightIcon;

        if (process.env.NODE_ENV !== "production") {
            if (htmlAttributes.disabled !== undefined) {
                warnOnDeprecatedDisabledProp();
            }

            if (leftIcon !== undefined) {
                warnOnDeprecatedLeftIconProp();
            }

            if (rightIcon !== undefined) {
                warnOnDeprecatedRightIconProp();
            }
        }

        const theme = useContext(LodestarCoreTheme);

        const textInputRef = useEnsuredForwardedRef(parentRef);

        function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
            onChange(e.target.value, e);
        }

        function handleOnFocus(e: React.FocusEvent<HTMLInputElement>) {
            setIsFocused(true);
            if (onFocus) {
                onFocus(e);
            }
        }

        function handleOnBlur(e: React.FocusEvent<HTMLInputElement>) {
            setIsFocused(false);
            if (onBlur) {
                onBlur(e);
            }
        }

        // This is too complex to do in emotion, passing it in.
        const borderColor =
            validationState === "error"
                ? colors.buttonDestructive
                : isFocused
                ? theme === "oldestar"
                    ? "#005ee0"
                    : colors.focus
                : reallyDisabled
                ? colors.neutralLight30
                : isHovered
                ? theme === "oldestar"
                    ? "#a0a0a0"
                    : colors.neutral
                : colors.neutralLight10;

        const OuterComponent =
            variant === "box"
                ? BoxStyledOuterContainer
                : UnderlineStyledOuterContainer;

        return (
            <OuterComponent
                isDisabled={reallyDisabled}
                className={className}
                borderColor={borderColor}
                themeMode={theme}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                    textInputRef.current?.focus();
                }}
            >
                {realContentBefore && (
                    <StyledContentBeforeContainer>
                        {typeof realContentBefore === "function"
                            ? realContentBefore({
                                  isFocused: isFocused,
                                  isHovered: isHovered,
                              })
                            : realContentBefore}
                    </StyledContentBeforeContainer>
                )}
                <StyledInputContainer>
                    <StyledInput
                        variant={variant}
                        themeMode={theme}
                        type={type}
                        value={value}
                        onChange={handleOnChange}
                        ref={textInputRef}
                        data-client-id={clientId}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        isHovered={isHovered}
                        aria-disabled={reallyDisabled}
                        {...htmlAttributes}
                        readOnly={
                            reallyDisabled
                                ? true
                                : htmlAttributes.readOnly
                                ? htmlAttributes.readOnly
                                : false
                        }
                    />
                </StyledInputContainer>
                {realContentAfter && (
                    <StyledContentAfterContainer>
                        {typeof realContentAfter === "function"
                            ? realContentAfter({
                                  isFocused: isFocused,
                                  isHovered: isHovered,
                              })
                            : realContentAfter}
                    </StyledContentAfterContainer>
                )}
            </OuterComponent>
        );
    }
);

TextInput.displayName = "TextInput";
