/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from "@emotion/styled";
import { blanketBackground } from "../../constants/colors";
import { layers } from "../../constants/layers";
declare type PointerEventsProperty = import("csstype").PointerEventsProperty;

interface BlanketProps {
    canClickThrough?: boolean;
    isTinted?: boolean;
}

export const opacity = (p: BlanketProps): number => (p.isTinted ? 1 : 0);
export const pointerEvents: (p: BlanketProps) => PointerEventsProperty = (p) =>
    p.canClickThrough ? "none" : "initial";

export const Div = styled.div<BlanketProps>`
    background: ${blanketBackground};
    bottom: 0;
    left: 0;
    opacity: ${opacity};
    pointer-events: ${pointerEvents};
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 220ms;
    z-index: ${layers.blanket};
`;
