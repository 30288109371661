import styled from "@emotion/styled";
import {
    buttonDestructive,
    infoLight10,
    neutralDark40,
    neutralLight40,
    success,
    warning,
} from "../../../constants/colors";
import { getFocusRingStyles } from "../../../util/focusRing";
import { withFocusToggleClassName } from "../../../util/withFocusClassName";
import { BannerType } from "../BannerTypes";

const styles = {
    error: {
        backgroundColor: buttonDestructive,
        fontColor: neutralLight40,
    },
    info: {
        backgroundColor: infoLight10,
        fontColor: neutralDark40,
    },
    success: {
        backgroundColor: success,
        fontColor: neutralDark40,
    },
    warning: {
        backgroundColor: warning,
        fontColor: neutralDark40,
    },
} as const;

const getColor = ({ type }: BannerContainerProps) => styles[type].fontColor;
const getBackgroundColor = ({ type }: BannerContainerProps) =>
    styles[type].backgroundColor;

const focusRing = getFocusRingStyles({
    includeFocusPseudoClass: true,
    colorOverride: neutralDark40,
    offset: 4,
    radius: 4,
    theme: "light",
});

export interface BannerContainerProps {
    type: BannerType;
}

export const BannerStyled = withFocusToggleClassName(styled(
    "div"
)<BannerContainerProps>`
    min-height: 48px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;

    color: ${getColor};
    background-color: ${getBackgroundColor};

    font-weight: 500;
    font-size: 14px;

    ${focusRing}
`);

BannerStyled.displayName = "BannerStyled";
