import { createContext } from "react";

export interface StaticPropContextShape {
    clientId?: string;
}

/**
 * This context should be used for things in Select and MultiSelect that don't expect to be changed during a
 * component's lifetime. This isn't a hard-n-fast rule, but this context can be a grab-bag of things we
 * want to propagate around these components. If some part of this context ends up changing frequently it
 * could hurt perf so we should break those things out if that becomes the case.
 */
export const StaticPropContext = createContext<StaticPropContextShape>({});
