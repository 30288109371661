/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { ReactNode } from "react";
import { sizes } from "../../../constants/spacing";
import { getLoadingStyle } from "../getStyles";

interface Props {
    hasLeftIcon: boolean;
    hasRightIcon: boolean;
    children?: ReactNode;
    clientId?: string;
    isLoading?: boolean;
}

const getMargin = (hasLeftIcon: boolean, hasRightIcon: boolean) => {
    // Icons already provide a margin, so the content is only responsible for edges without an icon
    if (hasLeftIcon && hasRightIcon) {
        return { margin: `0` };
    }
    if (hasLeftIcon) {
        return { marginRight: `${sizes.small - 1}px` }; // -1px for border
    }
    if (hasRightIcon) {
        return { marginLeft: `${sizes.small - 1}px` };
    }
    // Proper spacing will be provided by the button padding if there are no icons present
    return { margin: `0` };
};

export const Content = ({
    children,
    hasLeftIcon,
    hasRightIcon,
    isLoading,
    ...rest
}: Props) => (
    <span
        css={{
            flex: "1 1 auto",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            ...getMargin(hasLeftIcon, hasRightIcon),
            ...getLoadingStyle(isLoading),
        }}
        {...rest}
    >
        {children}
    </span>
);
