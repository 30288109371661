import styled from "@emotion/styled";
import { neutralDark40 } from "../../../constants/colors";
import { sizes } from "../../../constants/spacing";
import { Button } from "../../button";

export const StyledXButton = styled(Button)`
    position: absolute;
    top: ${sizes.small}px;
    right: ${sizes.small}px;
    height: 34px;
    color: ${neutralDark40};
`;

StyledXButton.displayName = "StyledXButton";
