/**
 * @param fns functions of the same signature to call. Doesn't allow a return type currently since
 * composing return types would be weird and isn't really what we need this for here.
 */
export function callAllHandlers<HandlerFn extends (...args: never[]) => void>(
    ...fns: Array<HandlerFn | undefined>
): HandlerFn {
    return function handle(...args) {
        fns.forEach((fn) => {
            fn && fn(...args);
        });
    } as HandlerFn;
}
